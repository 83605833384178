import { axiosClient } from "../apiClient";

export async function SaveGeneralInfo(data) {
  let res = await axiosClient.post("/saverecruitergeneralinfo", data);
  let response = await res.data;
  return response;
}

export async function getDomain() {
  return axiosClient
    .get("/getDomain")
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function saveCompanyPage(data) {
  return axiosClient
    .post("/savecompanydetails", data)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function getCompanyNames() {
  return axiosClient
    .get("/getCompanyName")
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function addCompanyApi(company) {
  return axiosClient
    .post("/saveCompanyName", company)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function fetchCompanyType() {
  return axiosClient
    .get("/getCompanyType")
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function saveDomains(data) {
  return axiosClient
    .post("/saveDomainsForCompany", data)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function forgetPasswordRecover(data) {
  return axiosClient
    .post("/forgetpasswordemailverification", data)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function saveResettedPassword(data) {
  return axiosClient
    .post("/saveResettedPassword", data)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function checkIfResetLinkExpired(data) {
  return axiosClient
    .post("/checkIfResetLinkExpired", data)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function resendOtpApi(data) {
  return axiosClient
    .post("/resendrecruiterotp", data)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function emailExists(data) {
  return axiosClient
    .post("/verifyemailexists", data)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function fetchCompanySize() {
  return axiosClient
    .get("/getCompanySize")
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function verifyEmailApi(otp) {
  return axiosClient
    .post("/verifyrecruiterotp", otp)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function getIndustryApi() {
  return axiosClient
    .get("/getIndustry")
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function otpTimerExpired(email) {
  return axiosClient
    .post("/recruiterotptimer", email)
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}
