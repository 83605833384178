export const pxToRem = (pixels) => {
  return pixels / 18.018 + "rem";
};

export const pxToVh = (px, portHeight = 1080) => `${(px * 100) / portHeight}vh`;

export const pxToVw = (px, portWidth = 1920) => `${(px * 100) / portWidth}vw`;

export const pxToMvh = (px, portHeight = 853) => `${(px * 100) / portHeight}vh`;

export const pxToMvw = (px, portWidth = 480) => `${(px * 100) / portWidth}vw`;
