/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import { useState } from "react";
import { useStyles } from "./styles";
// import Input from "../../../Shared/input";
import Loading from "../../Shared/Loader/index";
import TextAsync from "../../Shared/input/textAsync";
import { Link } from "react-router-dom";
import { ReactComponent as Envelope } from "../../../Assets/images/RecruiterOnboard/Envelope.svg";
import Header from "../Header/index";
import { debounce } from "lodash";
import { emailExists , forgetPasswordRecover } from "../../../Services/lib/RecruiterOnboarding";

const RecoverPass = (props) => {
    
    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);


    const [generalForm, setGeneralForm] = useState({
        email: {
            label: "email",
            elementType: "text",
            placeholder: "Enter Your registered Email Id",
            icon: <Envelope />,
            value: "",
            validation: {
                required: true,
                isEmail: true
            },
            errors: ["Enter a valid email id", <>This email isn't registered. <Link className={styles.signUp} to="/signup">Signup to continue</Link></>],
            errorNote:null,
            valid: false,
            touched: false
        },
    },

    );

    const checkValidity = (value, rules) => {
        let isValid = true;
        if (rules.isEmail) {
            let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            isValid = value.trim() != "" && pattern.test(value) && isValid;
        }

        return isValid;
    }

    const inputChangedHandler = (e) => {
        const updatedElement = {
            ...generalForm["email"]
        }
        updatedElement.value = e.target.value;
        updatedElement.touched = true;

         let checkEmail = {
            companyEmailId:e.target.value
        }

        emailExists(checkEmail).then(res => {
            console.log(res.res.data.message, res.res.data.status);

            if (checkValidity(updatedElement.value,updatedElement.validation)) {
                
            
            if (res.res.data.message == "Email do not exists.") {
                
                updatedElement.valid = false;
                updatedElement.errorNote = generalForm.email.errors[1];
                
            } else if (res.res.data.message == "Email exists.") {
                updatedElement.errorNote = null;
                updatedElement.valid = true;    
            }

            } else {
                updatedElement.errorNote = generalForm.email.errors[0];
                updatedElement.valid = false;
            }
            
             setGeneralForm(prev => {
                    prev["email"] = updatedElement;

                    return ({
                        ...prev
                    })
                });
            
        }).catch(err => {
            updatedElement.errorNote = generalForm.email.errors[0];

        })
    }

    let formElement = [];

    for (let key in generalForm) {
        formElement.push({
            id: key,
            config: generalForm[key]
        });
    }

    
    const textHandler = (e) => {
        // console.log("Executing Late");
        return inputChangedHandler(e);
    }

    const debounceTextHandler = useMemo(() => {
        return debounce(textHandler, 500);
    },[])

    const Next = () => {

        setLoading(true);
        const emailObj = {
            companyEmailId: generalForm.email.value,
            resetBaseUrl:"https://qaapi.glosity.club/"
        }
        forgetPasswordRecover(emailObj).then(res => {
            console.log(res);
            if (res.res.data.success) {
                setLoading(false);
            setSent(true);
            } else {
                setSent(false);
                setLoading(false);

            }
        }).catch(err => {
            console.log(err);
                setLoading(false);

        })
    }

    return (
        <Header>
            <div className={styles.container}>
            
            <div className={styles.formWrapper}>
                <h1>Recover password</h1>
                    <div className={styles.note}>Do not worry, happpens to the best of us.</div>
                    
                    {!sent ?
                        <div className={styles.inputContainer}>
                        <TextAsync
                        elementType="text"
                        placeholder="Enter your registered Email Id"
                        onchange={debounceTextHandler}
                        icon={<Envelope />}
                        value={generalForm.email.value}
                        valid={generalForm.email.valid}
                            // editable={true}
                        touched={generalForm.email.touched}
                        errorNote={generalForm.email.errorNote}
                        validation={{
                            required: true,
                            isName: true
                        }}
                />
                <button 
                    onClick={Next} 
                    disabled={!generalForm.email.valid} 
                    // className={`${styles.submit} ${!generalForm.email.valid ? styles.disabledSubmit : styles.enabledSubmit}`}> Email me a Recovery Link {loading && <Loading />}</button>
                    className={`${styles.submit} ${!generalForm.email.valid ? styles.disabledSubmit : styles.enabledSubmit}`}> Email me a Recovery Link</button>
                        </div> :
                  <p className={styles.emailSent}>An email with password reset link has been sent. Please click the link when you get it.</p>
                  }
            </div>
            </div>
        </Header>
    )
}

export default RecoverPass;