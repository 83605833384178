import {
  LOGIN_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGNUP_FAILURE,
  SIGNUP_START,
  SIGNUP_SUCCESS,
} from "../../Actions/StudentOnboarding/auth";

const initialstate = {
  token: null,
  user: null,
};

export const authReducer = (state = initialstate, action) => {
  switch (action.type) {
    case LOGIN_START: {
      return {
        error: false,
        user: null,
      };
    }
    case LOGIN_SUCCESS: {
      // console.log(action.payload);
      return {
        token: action.payload.token,
        user: action.payload.user,
      };
    }
    case LOGIN_FAILURE: {
      return {
        token: null,
        user: null,
      };
    }
    case LOGOUT: {
      return {
        user: null,
        token: null,
      };
    }
    case SIGNUP_START: {
      return {
        error: false,
        user: null,
      };
    }
    case SIGNUP_SUCCESS: {
      console.log(action.payload);
      return {
        token: action.payload.token,
        user: action.payload.user,
      };
    }
    case SIGNUP_FAILURE: {
      return {
        token: null,
        user: null,
      };
    }
    default:
      return state;
  }
};
