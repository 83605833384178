import { makeStyles } from "@mui/styles";
import { pxToVw, pxToVh, pxToRem, pxToMvw, pxToMvh } from "../../Utils/pxToRem";
import { theme } from "../../Utils/breakpoints";

export const useStyles = makeStyles({
  explorePage: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#fff",
    msOverFlowStyle: "none" /* IE and Edge */,
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
      width: "0px",
    },
    // background:
    //   "radial-gradient(30% 30% at 5% 5%, rgba(14, 201, 158, 0.15) 0%, rgba(196, 196, 196, 0) 100%)",
  },
  explorePageWrapper: {
    paddingLeft: pxToVw(200),
    paddingRight: pxToVw(200),
    height: "100%",
    paddingTop: pxToVh(65),
    [theme.breakpoints.down("mb")]: {
      paddingTop: pxToMvh(35),
      paddingLeft: pxToMvw(25),
      paddingRight: pxToMvh(25),
    },
  },
  exploreHeading: {
    display: "flex",
    alignItems: "center",
    color: "#0D1C2E",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "700",
    fontSize: pxToVw(40),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(20),
    },
  },
  exploreContainer: {
    display: "flex",
    flexDirection: "column",
    // gap: pxToVh(30),
    // paddingBottom: pxToVh(60),
    gap: pxToVh(32),
    paddingBottom: pxToVh(100),
    // border: "1px solid",
    height: "87.5%",
    paddingTop: pxToVh(65),
    [theme.breakpoints.down("mb")]: {
      paddingTop: pxToMvh(30),
      paddingBottom: pxToMvh(0),
    },
  },
  explorePageShowMoreDiv: {
    display: "flex",
    justifyContent: "center",
    padding: pxToVh(20),
    paddingBottom: pxToVh(120),
    [theme.breakpoints.down("mb")]: {
      // width: "100%",
      // paddingBottom: pxToMvh(100),
      // paddingTop: pxToMvh(40),
    },
  },
  explorePageShowMore: {
    borderRadius: pxToRem(12),
    border: "none",
    outline: "none",
    background: "#DBF7F0",
    padding: pxToVh(10) + " " + pxToVw(24),
    color: "#0EC99E",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
    fontSize: pxToVw(16),
    cursor: "pointer",
    marginRight: pxToVw(25),
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(265),
      fontSize: pxToMvw(12),
    },
  },
  exploreCard: {
    display: "flex",
    // border: "1px solid",
    borderRadius: pxToRem(24),
    // height: "8.5rem",
    // height: "25%",
    boxShadow: "0px 0px 50px 0px blue",
    background: "#FFFFFF",
  },
});
