/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useStyles } from "./styles";
// import Input from "../../Shared/input";
import Password from "../../Shared/input/password";
import Header from "../Header/index";
import { useLocation } from "react-router-dom";
import Loader from "../../Shared/Loader/index";
import { ReactComponent as Lock } from "../../../Assets/images/RecruiterOnboard/Lock.svg";
import { saveResettedPassword , checkIfResetLinkExpired } from "../../../Services/lib/RecruiterOnboarding";
// import Load

const ResetPass = () => {
  const styles = useStyles();
  const Navigate = useNavigate();

  // const [recruiterId, setRecruiterId] = useState();
  // const [token, setToken] = useState();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formValid, setFormValid] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const tokenQuery = new URLSearchParams(search).get("token");
  const [error, setError] = useState(false);
  // const { params } = useParams();

  const [generalForm, setGeneralForm] = useState({
    password: {
      label: "password",
      elementType: "password",
      editable: true,
      placeholder: "Create Your Password",
      icon: <Lock />,
      value: "",
      validation: {
        required: true,
        isPassword: true,
      },
      note: "Must be 8 characters or longer. Use at least one number or symbol(eg, !@#$%^)",
      errorNote: null,
      valid: false,
      touched: false,
    },
    cnfPass: {
      label: "cnfPass",
      elementType: "password",
      placeholder: "Confirm your new Password",
      icon: <Lock />,
      value: "",
      note: null,
      editable: true,
      validation: {
        required: true,
      },
      errorNote: "Passwords do not match",
      valid: false,
      touched: false,
    },
  });

  const [tokenVerified, setTokenVerified] = useState(false);

  useEffect(() => {

    const Obj = {
      recruiterId:id
    }

    checkIfResetLinkExpired(Obj).then(res => {
      console.log(res);
      if (res.res.data.success) {
        setTokenVerified(true);
        setLoading(false)
      } else {
        setTokenVerified(false);
        setLoading(false);

      }
    }).catch(err => {
      setLoading(false);
      console.log(err);
    })
  },[id])

  const checkValidity = (value, rules) => {
    let isValid = true;

    if (rules.isPassword) {
      let pattern = /(?=.{8,})((?=.*\d)|(?=.*[!@#$%^"]))/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  };
  const inputChangedHandler = (e, id) => {
    const updatedElement = {
      ...generalForm[id],
    };

    updatedElement.value = e.target.value;
    updatedElement.touched = true;
    if (updatedElement.errors != undefined) {
      updatedElement.errorNote =
        updatedElement.errors[0] || updatedElement.errorNote;
    }

    updatedElement.valid = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    if (updatedElement.value == "") {
      updatedElement.touched = false;
      updatedElement.valid = false;
    }

    setGeneralForm((prev) => {
      prev[id] = updatedElement;
      // password match
      if (generalForm.cnfPass.value === generalForm.password.value) {
        generalForm.cnfPass.valid = true;
      } else {
        generalForm.cnfPass.valid = false;
      }

      let FormValid = true;

      for (let inputIdentifier in generalForm) {
        FormValid = generalForm[inputIdentifier].valid && FormValid;
      }
      setFormValid(FormValid);
      return {
        ...prev,
      };
    });
    // generalForm[id] = updatedElement;
  };
  let formElement = [];

  for (let key in generalForm) {
    formElement.push({
      id: key,
      config: generalForm[key],
    });
  }

  const Reset = () => {
    return Navigate("/forgot");
  };

  const Next = () => {
    const resetPassObj = {
      token: tokenQuery,
      recruiterId: id,
      password: generalForm.password.value,
    };

    console.log(resetPassObj);

    saveResettedPassword(resetPassObj)
      .then((res) => {
        console.log(res);
        if (res.res.data.success) {
          console.log(res.res.data.message);
          setSuccess(true);
        } else {
          console.log(res.res.data.message);
          setError(true);
          setSuccess(false);
        }
      })
      .catch((err) => {
        setError(false);
        setSuccess(false);
        console.log(err);
      });
  };
  

  const renderPage = () => {
  
    if (tokenVerified) {
      return (
        <div className={styles.formWrapper}>
          <h1>Reset password</h1>
          <div className={styles.note}>
            Must be 8 characters or longer, Use at least one number and one
            special character
          </div>
          <div className={styles.inputs}>
            <Password
              elementType="password"
              placeholder="Create a new Password"
              onchange={(e) => inputChangedHandler(e, "password")}
              icon={<Lock />}
              editable={generalForm.password.editable}
              value={generalForm.password.value}
              note={generalForm.password.note}
              valid={generalForm.password.valid}
              touched={generalForm.password.touched}
              errorNote={generalForm.password.errorNote}
              validation={{
                required: true,
                isPassword: true,
              }}
            />
            <Password
              elementType="password"
              placeholder="Confirm your new Password"
              onchange={(e) => inputChangedHandler(e, "cnfPass")}
              icon={<Lock />}
              editable={generalForm.cnfPass.editable}
              value={generalForm.cnfPass.value}
              valid={generalForm.cnfPass.valid}
              note={generalForm.cnfPass.note}
              touched={generalForm.cnfPass.touched}
              errorNote={generalForm.cnfPass.errorNote}
              notice={generalForm.cnfPass.notice}
              validation={{
                required: true,
                isPassword: true,
              }}
            />
          </div>
          {success && (
            <span className={styles.successNote}>
              Your Password has been Changed
            </span>
          )}
          {error && (
            <span className={styles.errorNote}>Something Went Wrong</span>
          )}
          {/* <div className={styles.loginContainer} onClick={Next} > */}
          {success ? (
           <button
            disabled={!formValid}
            onClick={() => Navigate("/login")}
            className={`${styles.btnLogin} ${styles.enabledSubmit}`}
          >
            Click here to Login
          </button>
          ) :
            <button
            disabled={!formValid}
            onClick={Next}
            className={`${styles.btnLogin} ${
              formValid ? styles.enabledSubmit : styles.disabledSubmit
            }`}
          >
            Change password
          </button>
          }
          
          {/* </div> */}
        </div>
      );
    } else  {
      return (
        <div className={styles.formWrapper}>
          <h1>Something Went Wrong</h1>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <span>
                This password reset link seems to be expired or you have already
                resetted your password.
              </span>
              <span>Please try resetting your password again.</span>
            </div>
            {/* <div className={styles.loginContainer} onClick={Reset}>
              <button className={styles.btnLogin}>Reset password</button>
            </div> */}
            <button
            onClick={Reset}
            className={styles.resetBtn}>
            Reset password
          </button>
            <span className={styles.problemNote}>
              If problem persists, please contact support@glosity.club
            </span>
          </div>
        </div>
      );
    } 

  };


  return (
    <Header>
      <div className={styles.container}>
        {loading ? <Loader /> : renderPage()}
        {/* <Loader /> */}
      </div>
    </Header>
  );
};

export default ResetPass;
