import { makeStyles } from "@mui/styles";
import { pxToRem, pxToVw, pxToMvw, pxToMvh } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";
export const useStyles = makeStyles({
  section: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#131313",
    background:
      "radial-gradient(25% 50% at 100% 50%, rgba(14, 201, 158, 0.15) 0%, rgba(196, 196, 196, 0) 100%)",
    [theme.breakpoints.down("mb")]: {
      paddingLeft: pxToMvw(0),
      flexDirection: "column-reverse",
      paddingTop: pxToMvh(40),
      backgroundColor: "#131313",
      background:
        "radial-gradient(30% 20% at 90% 23%, rgba(14, 201, 158, 0.15) 0%, rgba(196, 196, 196, 0) 100%)",
    },
  },

  detailsWrapper: {
    width: "50%",
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      paddingLeft: pxToMvw(27),
      paddingRight: pxToMvw(27),
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    gap: pxToVw(20),
    paddingRight: pxToVw(200),
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      gap: pxToMvw(30),
      paddingRight: pxToVw(0),
    },
  },

  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(64),
    lineHeight: pxToVw(80),
    background: "-webkit-linear-gradient(0deg,#AFE1D6 ,#009270)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      paddingRight: pxToMvw(30),
      fontSize: pxToMvw(32),
      lineHeight: pxToMvh(35),
    },
  },
  subtitle: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    lineHeight: pxToVw(32),
    paddingRight: pxToVw(30),
    color: "#9D9D9D",
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      fontSize: pxToMvw(18),
      lineHeight: pxToMvh(25),
    },
  },

  button: {
    width: pxToVw(327),
    height: pxToVw(80),
    borderRadius: pxToRem(40),
    border: " 2px solid #0EC99E",
    backgroundColor: "#222222",
    color: "white",
    fontFamily: "'Poppins', sans-serif;",
    cursor: "pointer",
    fontSize: pxToVw(18),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(18),
      borderRadius: pxToMvw(40),
      width: pxToMvw(250),
      height: pxToMvh(45),
    },
  },
  animationWrapper: {
    width: "50%",
    paddingLeft: pxToVw(90),
    [theme.breakpoints.down("mb")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      paddingTop: pxToMvh(20),
      paddingLeft: pxToMvw(0),
    },
  },
  animation: {
    width: pxToVw(900),
    height: pxToVw(900),
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(450),
      height: pxToMvw(450),
    },
  },
});
