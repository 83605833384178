import React from "react";
import Input from "./Input";
import Numbers from "./Numbers";
import { useStyles } from "./Styles";
import Title from "./Title";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LeftVector from "../../../Assets/images/LandingPage/Subscribe/left.png";
import RightVector from "../../../Assets/images/LandingPage/Subscribe/right.png";
import { pxToVh } from "../../../Utils/pxToRem";

const Subscribe = () => {
  const classes = useStyles();

  var settings = {
    dots: true,
    autoplay:true,
    infinite:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed:1000,
    autoplaySpeed: 3500,
    cssEase: "linear",
    pauseOnHover: true,
    appendDots: dots => (
      <div
        style={{
          borderRadius: "10px",
          paddingBottom: pxToVh(50),
        }}
      >
        <ul style={{color:"#0EC99E",size:"50px" ,margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <div className={classes.subscribe}>
      <img src={LeftVector} className={classes.leftvector} />
      <img src={RightVector} className={classes.rightvector} />
      <div className={classes.numberWrapper}>
        <Numbers text="100+" subtext="Companies associated" />
        <Numbers text="1 Million+" subtext="User Base" />
        <Numbers text="500+" subtext="Recuruiter looking to hire" />
        <Numbers text="16+" subtext="16+" />
      </div>
      <div className={classes.numberCarousal}>
      <Slider {...settings} className={classes.carousal}>
      <Numbers text="100+" subtext="Companies associated" />
          <Numbers text="1 Million+" subtext="User Base" />
          <Numbers text="500+" subtext="Recuruiter looking to hire" />
          <Numbers text="16+" subtext="16+" />
      </Slider>
        {/* <Carousel
          className={classes.carousal}
          showArrows={false}
          showIndicators={true}
          showStatus={false}
          autoPlay
          infiniteLoop={true}
          interval="15000"
          transitionTime="5000"
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className={classes.dots}
                  style={{
                    background: "#0EC99E",
                  }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                className={classes.dots}
                style={{
                  background: "#0EC99E30",
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          <Numbers text="100+" subtext="Companies associated" />
          <Numbers text="1 Million+" subtext="User Base" />
          <Numbers text="500+" subtext="Recuruiter looking to hire" />
          <Numbers text="16+" subtext="16+" />
        </Carousel> */}
      </div>
      <div className={classes.bottomWrapper}>
        <Title />
        <Input />
      </div>
    </div>
  );
};

export default Subscribe;
