import { makeStyles } from "@mui/styles";
import {
  pxToMvw,
  pxToRem,
  pxToVh,
  pxToVw,
  pxToMvh,
} from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const useStyles = makeStyles({
  container: {
    // height: "100vh",
    alignSelf: "center",
    display: "flex",
    justifyContent: "center",
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    "& h1": {
      fontSize: pxToRem(48),
      background: "-webkit-linear-gradient(#00634C, #009270)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontFamily: "Poppins,sans-serif",
      fontWeight: 600,
      letterSpacing: "-1.5px",
      margin: 0,
      lineHeight: pxToVh(86),
      textAlign: "center",
      marginTop: pxToVh(52),
      marginBottom: pxToVh(36),
      [theme.breakpoints.down("sm")]: {
        fontSize: pxToMvw(24),
      },
    },
  },
  inputs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: pxToVh(24),
  },
  loginContainer: {
    width: pxToVw(140),
    height: pxToVw(80),
    background:
      "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
    borderRadius: pxToRem(40),
    border: "2px solid #0EC99E",
    margin: `${pxToVh(24)} auto ${pxToVh(32)} auto`,
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(124),
      height: pxToMvw(48),
      borderRadius: pxToMvw(40),
    },
  },
  btnLogin: {
    color: "white",
    textDecoration: "none",
    fontSize: pxToRem(18),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 400,
    height: "100%",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
    },
  },
  forgot: {
    fontWeight: 500,
    background:
      "linear-gradient(to right,rgba(1, 110, 85, 1) 30%, rgba(1, 143, 110, 1))",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
    marginBottom: pxToVh(31),
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    },
  },
  Or: {
    color: "rgba(208, 208, 208, 1)",
    fontSize: pxToRem(18),
    marginBottom: pxToVh(15),
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    },
  },
  googleLogin: {
    width: pxToVw(327),
    height: pxToVw(80),
    border: "2px solid rgba(14, 201, 158, 1)",
    borderRadius: pxToRem(40),
    marginBottom: pxToVh(48),
    backgroundColor: "white",
    "& a": {
      textDecoration: "none",
      color: "rgba(71, 71, 71, 1)",

      gap: pxToVw(7),
      fontWeight: 400,
      fontSize: pxToRem(18),
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: pxToMvw(12),
        gap: pxToMvw(10),
      },
    },
    "& svg": {
      width: pxToVw(30),
      height: pxToVw(30),
      [theme.breakpoints.down("sm")]: {
        width: pxToMvw(19),
        height: pxToMvw(18),
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(261),
      height: pxToMvw(48),
      fontSize: pxToRem(12),
      borderRadius: pxToMvh(40),
    },
  },
  BottomOptions: {
    display: "flex",
    alignItems: "center",
    gap: pxToVw(25),
    "& span": {
      fontWeight: 400,
      background:
        "linear-gradient(to right,rgba(1, 110, 85, 1) 50%, rgba(1, 143, 110, 1))",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textAlign: "center",
      fontSize: pxToRem(18),
      fontFamily: "Poppins,sans-serif",
      [theme.breakpoints.down("sm")]: {
        fontSize: pxToMvw(12),
      },
    },
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvw(20),
    },
  },
  signUpContainer: {
    width: pxToVw(160),
    height: pxToVw(80),
    borderRadius: pxToRem(40),
    backgroundColor: "rgba(227, 255, 249, 1)",
    border: "2px solid rgba(14, 201, 158, 1)",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(137),
      height: pxToMvw(48),
      borderRadius: pxToMvw(40),
      fontSize: pxToMvw(12),
    },
  },
  signUp: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    color: "rgba(0, 64, 49, 1)",
    fontSize: pxToRem(18),
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    },
  },
});
