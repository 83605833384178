/*eslint no-unused-vars:*/
import React, { useEffect, useState } from "react";
import Select, { createFilter } from "react-select";
import { customStyles, useStyles } from "./Styles";
import { pxToVh, pxToVw } from "../../../../Utils/pxToRem";
import Error from "../../Error/Error";

const CustomSelect = ({
  disabled,
  icon,
  placeholder,
  options,
  value,
  setValue,
  isMulti,
  errorMessage,
  helperText,
  error,
}) => {
  const [borderColor, setBorderColor] = useState("white");
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (value.length > 0 && error) {
      setBorderColor("red");
    } else if (value?.length > 0 && !error) {
      setBorderColor("#0EC99E");
    } else if (value.value?.length > 0 && !error) {
      setBorderColor("#0EC99E");
    } else {
      setBorderColor("white");
    }
    if (value?.length === 0) {
      setFocused(false);
    }
  }, [value, error]);

  const handleFocus = (e) => {
    if (!error) setBorderColor("#0EC99E");
    setFocused(true);
  };
  const handleBlur = (e) => {
    if (value?.length === 0) {
      setBorderColor("#FFFFFF");
    }
  };

  const classes = useStyles({ borderColor });
  return (
    <div className={classes.inputWrapper}>
      <div
        style={{ border: `2px solid ${borderColor}` }}
        className={classes.inputContainer}
      >
        <div className={classes.inputIcon}>{icon}</div>
        {/* <p className={classes.placeholder}>{placeholder}</p> */}
        <Select
          styles={customStyles}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          maxMenuHeight={150}
          defaultValue={value}
          isSearchable={true}
          isClearable={false}
          isDisabled={disabled}
          onChange={setValue}
          options={options}
          isMulti={isMulti}
          error={error}
          filterOption={createFilter({ ignoreAccents: false })}
        />
      </div>
      {error && <Error text={errorMessage} color="red" />}
      {focused && !error ? <Error text={helperText} color="#9D9D9D" /> : null}
    </div>
  );
};

export default CustomSelect;
