import React from "react";
import { pxToVw } from "../../../Utils/pxToRem";

const Tags = ({ bg, text, textbg, border }) => {
  return (
    <div
      style={{
        backgroundColor: bg,
        display: "flex",
        alignItems: "center",
        padding: ".10em 0.8rem",
        borderRadius: pxToVw(24),
        cursor: "pointer",
        border: border,
      }}
    >
      <p style={{ color: textbg, fontSize: pxToVw(14) }}>{text}</p>
    </div>
  );
};

export default Tags;
