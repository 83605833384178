import { makeStyles } from "@mui/styles";
import {
  pxToVw,
  pxToVh,
  pxToRem,
  pxToMvh,
  pxToMvw,
} from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";

export const useStyles = makeStyles({
  reelsWrapper: {
    borderRadius: pxToRem(17.6122),
    flexGrow: "1",
    width: pxToVw(300),
    height: "100%",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(350),
      display: "flex",
    },
  },
  reels: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  reelVideo: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {},
  },
  video: {},
  control: {
    background:
      "linear-gradient(360deg, #000000 0%, rgba(50, 0, 0, 0.1) 24.85%)",
    display: "flex",
    opacity: "1",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    padding: pxToRem(30),
    paddingBottom: pxToRem(15),
    transition: "opacity 200ms ease-in-out",
    "&:hover": {
      opacity: "1",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  viewCount: {
    flexGrow: "1",
    display: "flex",
    alignItems: "center",
    gap: pxToRem(11),
  },

  videoControls: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: pxToRem(98),
    height: pxToRem(98),
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, 0.56)",
    boxShadow: "0px 1.64959px 21.4446px -4.94876px #AFAFAF",
    borderRadius: "100%",
    padding: "auto",
    cursor: "pointer",
  },
  videoActivity: {
    marginTop: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: pxToRem(20),
    color: "#FFFFFF",
    fontWeight: "800",
    fontSize: pxToRem(16),
    marginLeft: "auto",
    marginBottom: pxToRem(60),
  },
  likeVideo: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: pxToRem(10),
    borderRadius: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 1.14025px 14.8233px -3.42076px #AFAFAF",
    cursor: "pointer",
  },
  shareVideo: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: pxToRem(10),
    borderRadius: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 1.14025px 14.8233px -3.42076px #AFAFAF",
    cursor: "pointer",
  },
  likeIcon: {
    color: "#FF3366",
  },
  shareIcon: {
    transform: "scaleX(-1)",
    color: "#005B7A",
  },
  likeCount: {
    height: pxToRem(33),
    width: pxToRem(33),
    background: "rgba(255, 51, 102, 0.4)",
    top: -15,
    right: -10,
    position: "absolute",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  shareCount: {
    height: pxToRem(33),
    width: pxToRem(33),
    background: "rgba(0, 91, 122, 0.69)",
    top: -15,
    right: -10,
    position: "absolute",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  videoProgress: {
    display: "flex",
    gap: pxToRem(20),
    alignItems: "center",
  },
  progressBar: {
    display: "flex",
    alignItems: "center",
    width: "55%",
  },
  progress: {
    // flexGrow: "1",
    marginLeft: "auto",
    color: "#fff",
    fontWeight: "500",
    fontSize: pxToRem(16),
  },
});
