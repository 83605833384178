import { makeStyles } from "@mui/styles";
import { pxToVw, pxToVh } from "../../../../Utils/pxToRem";

export const useStyles = makeStyles({
  //card styles
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: pxToVw(24),
    width: pxToVw(494),
    position: "relative",
    backgroundColor: "white",
    gap: pxToVh(12),
    boxShadow: "0px 0px 30px 8px #E3E6EC80",
    cursor: "pointer",
    padding: pxToVw(20),
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: pxToVw(30),
  },
  bottom: {
    display: "flex",
    flexDirection: "column",
    gap: pxToVw(2),
  },
  topWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingRight: pxToVw(20),
    gap: pxToVw(5),
  },
  titleWrapper: {
    display: "flex",
    gap: pxToVw(15),
  },
  title: {
    fontSize: pxToVw(22),
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "500",
  },
  subtitle: {
    fontSize: pxToVw(18),
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    color: "#2C2C2C",
  },
  image: {
    width: pxToVw(61),
    height: pxToVw(61),
    objectFit: "cover",
  },
  dateWrapper: {
    display: "flex",
  },
  date: {
    fontSize: pxToVw(18),
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "500",
    color: "#808191",
  },
  time: {
    fontSize: pxToVw(14),
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    color: "#808191",
  },
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: pxToVw(10),
    marginTop: pxToVw(5),
  },
  link: { width: pxToVw(31), height: pxToVh(31), objectFit: "cover" },
  edit: {
    position: "absolute",
    right: pxToVw(13),
    top: pxToVw(25),
    cursor: "pointer",
  },
  smallcross: {
    position: "absolute",
    right: pxToVw(-12),
    top: pxToVw(-2),
    cursor: "pointer",
  },
  videoWrapper: {
    width: pxToVw(450),
    height: pxToVw(226),
    borderRadius: pxToVw(22),
  },
  video: {
    width: pxToVw(450),
    height: "100%",
    borderRadius: pxToVw(22),
  },
  acheivements: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  acheivementText: {
    fontSize: pxToVw(18),
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "500",
    color: "#0EC99E",
  },
});
