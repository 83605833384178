/*eslint no-unused-vars:*/
import React, { useState } from "react";
import { useStyles } from "./Styles";
import Final from "../../Final";
import Topbar from "../../../Shared/Topbar/Topbar";
import { useNavigate } from "react-router";

const FinalPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/");
  };
  return (
    <div className={classes.final}>
      <Final
        title="You are all done!"
        buttonText="Explore Glosity"
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default FinalPage;
