import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw } from "../../../../Utils/pxToRem";

export const useStyles = makeStyles({
  MetricsCard: {
    border: `${pxToVh(1)} solid #a3e3d4`,
    padding: `${pxToVh(5)} ${pxToVw(3)} ${pxToVh(5)} ${pxToVw(15)}`,
    borderRadius: pxToVw(10),
    marginLeft: pxToVw(50),
    marginBottom: pxToVh(17),
    maxWidth: pxToVw(250),
  },
  MetricsCardWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  MetricsCardFollower: {
    fontSize: pxToVw(14),
    fontWeight: "600",
    marginBottom: pxToVh(12),
  },
  MetricsCardFollowerNumber: {
    marginRight: pxToVw(10),
    fontWeight: "600",
    fontSize: pxToVw(40),
    background:
      "linear-gradient(88.03deg, #012E3E 3.33%, #1E977C 32.4%, #157665 45.08%, #20A284 60.67%, #075353 80.1%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
});
