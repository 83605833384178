import React, { useState } from "react";
import { ReactComponent as Menu } from "../../../Assets/images/Topbar/Menu.svg";
import { ReactComponent as Cross } from "../../../Assets/images/Topbar/cross.svg";
import { ReactComponent as Logo } from "../../../Assets/images/Topbar/logo.svg";
import Drawer from "./Drawer";
import { useStyles } from "./Styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open });
  const handleDrawerOpen = () => {
    setOpen(true);
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };
  const navigate = useNavigate();
  const routeChange = () => {
    let path = "/login";
    navigate(path);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    document.body.style.overflow = "unset";
  };

  return (
    <AppBar position="sticky">
      <Toolbar className={classes.topbar}>
        <div className={classes.left}>
          <Logo className={classes.logo} />
          <div className={classes.buttons}>
            <button className={classes.text}>How it works</button>
            <button className={classes.text}>Showcase</button>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.options}>
            <button className={classes.text} onClick={routeChange}>
              For Company
            </button>
            <button
              className={classes.button}
              onClick={() => {
                navigate("/studentregister");
              }}
            >
              Get Started
            </button>
            <button
              className={classes.text}
              onClick={() => {
                navigate("/studentlogin");
              }}
            >
              Login
            </button>
          </div>
          <div className={classes.topbarIcons}>
            {!open ? (
              <Menu onClick={handleDrawerOpen} />
            ) : (
              <Cross onClick={handleDrawerClose} />
            )}
          </div>
          <div className={classes.drawer}>
            <Drawer open={open} setOpen={setOpen} />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
