// import { Menu } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as Logo } from "../../../Assets/images/Shared/logo.svg";
import { useStyles } from "./Styles";
import { ReactComponent as Logo } from "../../../Assets/images/ExploreCareerPage/Header/logo.svg";
import { ReactComponent as Search } from "../../../Assets/images/ExploreCareerPage/Header/search.svg";
import { ReactComponent as Notification } from "../../../Assets/images/ExploreCareerPage/Header/notification.svg";
import { ReactComponent as Menu } from "../../../Assets/images/ExploreCareerPage/Header/Menu.svg";
import Profile from "../../../Assets/images/ExploreCareerPage/Header/profile.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/Actions/StudentOnboarding/auth";

const Topbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const showBar = user?.StudentGeneralInfo?.isDetailedFilled;
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <div className={classes.Exploreheader}>
      <Logo
        className={classes.ExploreheaderLogoIcon}
        onClick={() => {
          if (showBar) navigate("/explorecareer");
          else navigate("/");
        }}
      />
      {showBar && (
        <>
          <div className={classes.ExploreheaderSearchBarDiv}>
            <input
              placeholder="Search"
              className={classes.ExploreheaderSearchBar}
            />
            <Search className={classes.ExploreheaderSearchIcon} />
          </div>
          <div className={classes.ExploreheaderNavLinks}>
            <div className={classes.ExploreheaderNavLink}>Home</div>
            <div
              className={classes.ExploreheaderNavLink}
              onClick={() => {
                navigate("/explorecareer");
              }}
            >
              Explore
            </div>
            <div className={classes.ExploreheaderNavLink}>
              Opportunity Board
            </div>
          </div>
          <div className={classes.ExploreheaderNotificationAndProfile}>
            <Notification className={classes.ExploreheaderNotificationIcon} />
            <img
              src={Profile}
              className={classes.ExploreheaderProfileIcon}
              onClick={handleLogout}
            />
          </div>
          <Menu className={classes.ExploreheaderMenuIcon} />
        </>
      )}
    </div>

    // <div className={classes.topbar}>
    //   <div className={classes.left}>
    //     <Logo
    //       className={classes.logo}
    //       onClick={() => {
    //         navigate("/");
    //       }}
    //     />
    //   </div>
    // </div>
  );
};

export default Topbar;
