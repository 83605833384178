import React, { useEffect, useState } from "react";
import { useStyles } from "./Styles";
// import { pxToRem } from "../../../Utils/pxToRem";
import { ReactComponent as PlayIcon } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/playIcon.svg";
import { ReactComponent as PlayIconActive } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/playIconActive.svg";
const CareersQuestions = ({ faqData, selectFaq, selectedFaq }) => {
  const classes = useStyles();

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(faqData);
  }, [faqData]);

  return (
    <div className={classes.CareersQuestionsWrapper}>
      {data.map((item, index) => (
        <div
          key={index}
          className={`${index === selectedFaq && classes.active} ${
            classes.CareersQuestions
          }`}
          onClick={() => selectFaq(index)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {index === selectedFaq ? (
              <PlayIconActive className={classes.playButton} />
            ) : (
              <PlayIcon className={classes.playButton} />
            )}
          </div>
          <p className={classes.Questions}>{item.expert_ques}</p>
        </div>
      ))}
    </div>
  );
};

export default CareersQuestions;
