import React from "react";
import { useStyles } from "./Styles";

const CoverPhotoSection = () => {
  const classes = useStyles();
  return (
    <div className={classes.Cover__area}>
      <img
        src="https://random.imagecdn.app/1535/250"
        alt=""
        className={classes.Cover__area_image}
      />
    </div>
  );
};

export default CoverPhotoSection;
