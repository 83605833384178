import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
// import Input from "../../Shared/input";
import { ReactComponent as Envelope } from "../../../Assets/images/RecruiterOnboard/Envelope.svg";
import { ReactComponent as Lock } from "../../../Assets/images/RecruiterOnboard/General_Info/Lock.svg";
import Header from "../Header/index";
import Text from "../../Shared/input/text";
import Password from "../../Shared/input/password";

const RecruiterLogin = () => {
  const styles = useStyles();

  const [generalForm, setGeneralForm] = useState({
    email: {
      label: "email",
      elementType: "text",
      placeholder: "Enter your Company Email Address",
      icon: <Envelope />,
      value: "",
      validation: {
        required: true,
        isEmail: true,
      },
      errorNote: "Enter a valid email id",
      valid: false,
      touched: false,
    },
    password: {
      label: "password",
      elementType: "password",
      placeholder: "Enter Password",
      icon: <Lock />,
      value: "",
      validation: {
        required: true,
        isPassword: true,
      },
      errorNote:
        "Must be 8 characters or longer. Use at least one number or symbol(eg, !@#$%^)",
      valid: false,
      touched: false,
    },
  });

  const [formValid, setFormValid] = useState(false);

  const checkValidity = (value, rules) => {
    let isValid = true;
    if (rules.isEmail) {
      let pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = value.trim() != "" && pattern.test(value) && isValid;
    }
    // password validation
    if (rules.isPassword) {
      let pattern = /(?=.{8,})((?=.*\d)|(?=.*[!@#$%^"]))/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  };

  const inputChangedHandler = (e, id) => {
    const updatedElement = {
      ...generalForm[id],
    };
    updatedElement.value = e.target.value;
    updatedElement.touched = true;

    updatedElement.valid = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    if (updatedElement.value == "") {
      updatedElement.touched = false;
      updatedElement.valid = false;
    }

    setGeneralForm((prev) => {
      prev[id] = updatedElement;

      let FormValid = true;

      for (let inputIdentifier in generalForm) {
        FormValid = generalForm[inputIdentifier].valid && FormValid;
      }
      setFormValid(FormValid);
      return {
        ...prev,
      };
    });
    // generalForm[id] = updatedElement;
  };

  let formElement = [];

  for (let key in generalForm) {
    formElement.push({
      id: key,
      config: generalForm[key],
    });
  }

  return (
    <Header>
      <div className={styles.container}>
        <div className={styles.formWrapper}>
          <h1>Welcome back To Glosity{formValid}</h1>
          <div className={styles.inputs}>
            {/* {formElement.map((input, i) => (
                        <Input key={i} {...input.config} onchange={(e) => inputChangedHandler(e, input.id)} />
                    ))} */}
            <Text
              placeholder="Enter your Company Email Address"
              onchange={(e) => inputChangedHandler(e, "type")}
              icon={<Envelope />}
              value={generalForm.email.value}
              valid={generalForm.email.valid}
              touched={generalForm.email.touched}
              errorNote={generalForm.email.errorNote}
              validation={{
                required: true,
                isEmail: true,
              }}
            />

            <Password
              placeholder="Enter Password"
              onchange={(e) => inputChangedHandler(e, "type")}
              icon={<Lock />}
              value={generalForm.password.value}
              valid={generalForm.password.valid}
              touched={generalForm.password.touched}
              errorNote={generalForm.password.errorNote}
              validation={{
                required: true,
              }}
            />
          </div>
          <div className={styles.loginContainer}>
            <Link className={styles.btnLogin} to="/dashboard">
              Login
            </Link>
          </div>
          <Link className={styles.forgot} to="/forgot">
            Forgot Password?
          </Link>
          <span className={styles.Or}>Or</span>
          <div className={styles.BottomOptions}>
            <span>New to Glosity ?</span>
            <div className={styles.signUpContainer}>
              <Link to="/signup" className={styles.signUp}>
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default RecruiterLogin;
