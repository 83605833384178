import React from "react";
import { useStyles } from "./styles";

const Checkbox = (props) => {

    const styles = useStyles();



    return (
        // <div>
          
            <div className={styles.checkBoxContainer}>
                <input id={props.id} type="checkbox" checked={props.agree} className={styles.checkInput} onChange={props.setAgree} />
                <span className={styles.Checkmark}></span>
                {props.agree && <span className={styles.tick}></span>}
            </div>
          
        // </div>
    )
}

export default Checkbox;