/*eslint no-unused-vars:*/
import React from "react";
import { useStyles } from "./Styles";
import { ReactComponent as Tick } from "../../../Assets/images/StudentOnboard/Final/tick.svg";

const Final = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.final}>
      <h1 className={classes.title}>{props.title}</h1>
      <Tick className={classes.tick} />
      <button className={classes.next} onClick={props.handleSubmit}>
        {props.buttonText}
      </button>
    </div>
  );
};

export default Final;
