export const OPEN_MODAL = "openModal";
export const CLOSE_MODAL = "closeModal";
export const OPEN_NESTED_MODAL = "openNestedModal";
export const CLOSE_NESTED_MODAL = "closeNestedModal";

export const openModal = (data) => {
  return {
    type: OPEN_MODAL,
    payload: data,
  };
};

export const closeModal = (data) => {
  return {
    type: CLOSE_MODAL,
    payload: data,
  };
};

export const openNestedModal = (data) => {
  return {
    type: OPEN_NESTED_MODAL,
    payload: data,
  };
};

export const closeNestedModal = (data) => {
  return {
    type: CLOSE_NESTED_MODAL,
    payload: data,
  };
};
