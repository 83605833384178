import { makeStyles } from "@mui/styles";
import { pxToMvh, pxToMvw, pxToVh, pxToVw } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";
export const useStyles = makeStyles({
  section: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    // width: pxToVw(1920),
    paddingTop: pxToVh(100),
    backgroundColor: "#131313",
    background:
      "radial-gradient(50% 50% at 50% 50%, rgba(14, 201, 158, 0.15) 0%, rgba(196, 196, 196, 0) 100%)",
    [theme.breakpoints.down("mb")]: {
      paddingLeft: pxToMvw(0),
      paddingTop: pxToMvh(20),
      flexDirection: "column-reverse",
    },
  },
  imageWrapper: {
    paddingTop: pxToVh(150),
    position: "sticky",
    top: 0,
    transition: "all .2s ease",
    width: "50%",
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  image: {
    position: "sticky",
    transform: "translate(10%)",
    top: pxToVh(250),
    width: pxToVw(800),
    height: pxToVh(593),
    [theme.breakpoints.down("mb")]: {
      position: "unset",
      transform: "translate(5%)",
      width: pxToMvw(311),
      height: pxToMvh(167),
    },
  },
  scrollFullScreen: {
    display: "flex",
    height: "90vh",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("mb")]: {
      display: "flex",
      height: pxToMvh(300),
      justifyContent: "center",
    },
  },
  mobileWrapper: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: "flex",
      flexDirection: "column",
      gap: pxToMvh(80),
      width: "100%",
      paddingLeft: pxToMvw(27),
    },
  },
  detailsWrapper: {
    width: "50%",
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    paddingRight: pxToVw(200),
    gap: pxToVh(20),
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      gap: pxToMvw(30),
      paddingRight: pxToVw(0),
    },
  },
  notVisible: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: "ease-in 0.65s",
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(64),
    lineHeight: pxToVw(80),
    background: "-webkit-linear-gradient(0deg,#AFE1D6 ,#009270)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      paddingRight: pxToMvw(30),
      fontSize: pxToMvw(30),
      lineHeight: pxToMvh(35),
    },
  },
  subtitle: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    lineHeight: pxToVw(32),
    paddingRight: pxToVw(30),
    color: "#9D9D9D",
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      fontSize: pxToMvw(18),
      lineHeight: pxToMvh(25),
    },
  },
  listWrapper: {
    width: "100%",
  },
  list: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    marginBottom: pxToVh(15),
    color: "#FFFFFF",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(16),
      lineHeight: pxToMvw(25),
    },
  },
  button: {
    width: pxToVw(327),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    border: " 2px solid #0EC99E",
    backgroundColor: "#222222",
    color: "white",
    fontFamily: "'Poppins', sans-serif;",
    fontSize: pxToVw(18),
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvh(250),
      height: pxToMvw(65),
      fontSize: pxToMvw(18),
      borderRadius: pxToMvw(40),
    },
  },
  handWrapper: {
    display: "flex",
    gap: pxToVw(5),
    [theme.breakpoints.down("mb")]: {
      gap: pxToMvw(10),
      width: "100%",
    },
  },
  hand: {
    width: pxToVw(20),
    height: pxToVh(20),
    marginTop: pxToVh(4),
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(25),
      height: pxToMvh(25),
      marginTop: pxToMvh(2),
    },
  },
  mobileImageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingRight: pxToMvw(27),
  },
  mobileImage: {
    width: pxToMvw(400),
    height: pxToMvh(250),
  },
});
