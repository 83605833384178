/*eslint no-unused-vars:*/
import React, { useEffect, useState } from "react";
import { useStyles } from "./Styles";
import { ReactComponent as Pen } from "../../../../Assets/images/StudentOnboard/Form/pen.svg";
import { ReactComponent as Email } from "../../../../Assets/images/StudentOnboard/Form/mail.svg";
import { ReactComponent as Face } from "../../../../Assets/images/StudentOnboard/Form/face.svg";
import { ReactComponent as Star } from "../../../../Assets/images/StudentOnboard/Form/star.svg";
import Text from "../../../Shared/inputV2/Text/input";
import Select from "../../../Shared/inputV2/DropDown";
import {
  getStudentAspirations,
  getStudentSkills,
} from "../../../../Services/lib/StudentOnboarding";
import { ReactComponent as Cross } from "../../../../Assets/images/StudentOnboard/Form/cross.svg";

const BasicDetails = ({
  multiForm,
  setMultiForm,
  file,
  setFile,
  formPage,
  setFormPage,
  aspirations,
  setAspirations,
  skills,
  setSkills,
  nameError,
  setNameError,
  emailError,
  setEmailError,
  setAspirationsError,
  aspirationsError,
}) => {
  const [disabled, setDisabled] = useState(true);
  const classes = useStyles({ disabled });

  useEffect(() => {
    if (aspirations.length >= 4) {
      setAspirationsError(true);
      aspirations.pop();
    } else {
      setAspirationsError(false);
    }
  }, [aspirations]);

  useEffect(() => {
    if (
      multiForm.fullName.length !== 0 &&
      multiForm.email.length !== 0 &&
      skills.length >= 3 &&
      aspirations.length > 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [multiForm.fullName, multiForm.email, skills, aspirations]);

  useEffect(() => {
    if (multiForm.fullName?.length === 0) {
      setNameError(false);
    }
    if (multiForm.email?.length === 0) {
      setEmailError(false);
    }
  }, [multiForm.fullName, multiForm.email]);

  const onChange = (e) => {
    setMultiForm({ ...multiForm, [e.target.name]: e.target.value });
  };

  const [aspirationsArray, setAspiratonsArray] = useState([]);
  const [skillsArray, setSkillsArray] = useState([]);
  const aspirationsOptions = [];
  const skillsOptions = [];

  useEffect(() => {
    const getData = async () => {
      const res = await getStudentAspirations();
      setAspiratonsArray(res.res.data.data);
      const skill = await getStudentSkills();
      setSkillsArray(skill.res.data.data);
    };
    getData();
  }, []);

  aspirationsArray?.map((data) =>
    aspirationsOptions.push({ value: data.Aspiration, label: data.Aspiration })
  );

  skillsArray?.map((data) => {
    skillsOptions.push({ value: data.Skill, label: data.Skill });
  });

  const options = [
    { value: "WordPress", label: "WordPress" },
    { value: "SEO", label: "SEO" },
    { value: "Yoast", label: "Yoast" },
    { value: "journalism", label: "journalism" },
    { value: "technical writing", label: "technical writing" },
    { value: "ghostwriting", label: "ghostwriting" },
    { value: "Word", label: "Word" },
    { value: "Excel", label: "Excel" },
    { value: "Powerpoint", label: "Powerpoint" },
    { value: "Outlook", label: "Outlook" },
    { value: "Access", label: "Access" },
    { value: "OneNote", label: "OneNote" },
    { value: "HTML", label: "HTML" },
    { value: "CSS", label: "CSS" },
    { value: "Javascript", label: "Javascript" },
    { value: "React", label: "React" },
    { value: "WordPress", label: "WordPress" },
    {
      value: "Content Management Systems",
      label: "Content Management Systems",
    },
    { value: "Trello", label: "Trello" },
    { value: "Slack", label: "Slack" },
    { value: "Asana", label: "Asana" },
    { value: "C#", label: "C#" },
    { value: "Java", label: "Java" },
    { value: "SQL", label: "SQL" },
    { value: "C++", label: "C++" },
    { value: "C", label: "C" },
    { value: "Python", label: "Python" },
    { value: "Machine Learning", label: "Machine Learning" },
    { value: "Perl", label: "Perl" },
    { value: "XML", label: "XML" },
  ];

  return (
    <div className={classes.basicDetails}>
      <h1 className={classes.title}>Enter Basic Details</h1>
      <div className={classes.inputWrappers}>
        <div className={classes.profilePhotoWrapper}>
          <label htmlFor="fileInput" className={classes.photoLabel}>
            {!file && (
              <div className={classes.profilePhoto}>
                <p className={classes.plus}>+</p>
                <p className={classes.text}>Profile Pic</p>
              </div>
            )}
            {file && (
              <img
                className={classes.profileImage}
                src={URL.createObjectURL(file)}
                alt="write image"
              />
            )}
          </label>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        </div>
        <Text
          icon={<Pen />}
          onChange={onChange}
          value={multiForm.fullName}
          name="fullName"
          type="text"
          placeholder="Enter Full Name"
          errorMessage="Name cannot contain numbers"
          error={nameError}
          setError={setNameError}
        />
        <Text
          icon={<Email />}
          onChange={onChange}
          value={multiForm.email}
          name="email"
          type="text"
          placeholder="Email Address"
          errorMessage="Not a valid email format"
          error={emailError}
          setError={setEmailError}
        />
        <Select
          icon={<Face />}
          title="Field of study"
          placeholder="Your Aspirations (Maximum 3)"
          name="filedOfStudy"
          options={aspirationsOptions}
          value={aspirations}
          setValue={setAspirations}
          isMulti={true}
          errorMessage="Maximum 3 aspirations can be added"
          helperText="Maximum 3 aspirations can be added"
          error={aspirationsError}
        />
        {/* {aspirations.length > 0 && (
          <div className={classes.list}>
            {aspirations?.map((ele, id) => (
              <div className={classes.selections}>
                <button key={id} className={classes.selection}>
                  {ele.value}
                </button>
                <Cross
                  onClick={() => {
                    deleteItems(id);
                  }}
                />
              </div>
            ))}
          </div>
        )} */}
        <Select
          icon={<Star />}
          title="Field of study"
          placeholder="Skills (Minimum 3)"
          name="filedOfStudy"
          options={options}
          value={skills}
          setValue={setSkills}
          isMulti={true}
          errorMessage="Please add atleast 3 skills"
          helperText="Please add atleast 3 skills"
        />
        {/* {skills.length > 0 && (
          <div className={classes.list}>
            {skills?.map((ele, id) => (
              <button>{ele.value}</button>
            ))}
          </div>
        )}
        */}
      </div>

      <div className={classes.button}>
        <button
          disabled={disabled}
          className={classes.next}
          onClick={() => {
            setFormPage(formPage + 1);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BasicDetails;
