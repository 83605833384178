import { SET_DATA, SET_SELECTED_REEL } from "../../Actions/ExploreCareer";

const initialState = {
  cardData: null,
  selectedReel: null,
};

const ExploreCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return { ...state, cardData: action.payload };
    case SET_SELECTED_REEL:
      return { ...state, selectedReel: action.payload };

    default:
      return state;
  }
};

export default ExploreCardReducer;
