/*eslint no-unused-vars:*/
import React, { useState } from "react";
import Topbar from "../../Shared/Topbar/Topbar";
import Left from "../../../Assets/images/RecruiterOnboard/leftillustration.png";
import Right from "../../../Assets/images/RecruiterOnboard/rightIllustration.png";
import { useStyles } from "./Styles";
import VerifyOtp from "./VerifyOtp/index";
import SignUp from "./SignUp/index";

const index = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [values, setValues] = useState({
    phonenumber: "",
    password: "",
    confirmpassword: "",
  });
  const [formTitles, setFormTitles] = useState([
    "Hello, Welcome to Glosity",
    "Verify your Phone Number",
  ]);
  const PageRender = () => {
    if (page === 0) {
      return (
        <SignUp
          page={page}
          setPage={setPage}
          values={values}
          setValues={setValues}
        />
      );
    } else if (page === 1) {
      return (
        <VerifyOtp
          page={page}
          setPage={setPage}
          phonenumber={values.phonenumber}
          password={values.password}
        />
      );
    }
  };
  return (
    <div className={classes.studentRegister}>
      <Topbar />
      <div className={classes.studentRegisterContainer}>
        <div className={classes.body}>
          <h1 className={classes.title}>{formTitles[page]}</h1>
          {PageRender()}
        </div>
      </div>
    </div>
  );
};

export default index;
