import { makeStyles } from "@mui/styles";
import { theme } from "../../../Utils/breakpoints";
import { pxToMvh, pxToMvw, pxToVh, pxToVw } from "../../../Utils/pxToRem";
export const useStyles = makeStyles({
  studentRegisterContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    height: "92vh",
    gap: pxToVh(36),
    paddingTop: pxToVh(52),
    position: "relative",
    backgroundColor: "#F6F6F6",
  },
  left: {
    position: "absolute",
    width: "30%",
    bottom: 0,
    left: 0,
    zIndex: -1,
  },
  right: {
    position: "absolute",
    width: "25%",
    top: -50,
    right: 0,
    zIndex: -1,
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(48),
    lineHeight: pxToVw(86),
    background: "linear-gradient(89.61deg, #00634C -19%, #009270 92.23%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(32),
      lineHeight: pxToMvw(32),
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});
