import React, { useState } from "react";
import Topbar from "../Shared/Topbar/Topbar";
import { useStyles } from "./Styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as ArrowSvg } from "../../Assets/images/Roadmap/arrow.svg";
import Aurthorimg from "../../Assets/images/Roadmap/aurthor.svg";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { ReactComponent as Arrow3 } from "../../Assets/images/Roadmap/arrow3.svg";
import { ReactComponent as Arrow4 } from "../../Assets/images/Roadmap/arrow4.svg";
import { ReactComponent as Trophy } from "../../Assets/images/Roadmap/Trophy.svg";

import { ReactComponent as LinkedIn } from "../../Assets/images/Roadmap/Social/LinkedIn.svg";
import { ReactComponent as Dribble } from "../../Assets/images/Roadmap/Social/Dribble.svg";
import { ReactComponent as Behance } from "../../Assets/images/Roadmap/Social/Behance.svg";
import { ReactComponent as Instagram } from "../../Assets/images/Roadmap/Social/Instagram.svg";
import { ReactComponent as Facebook } from "../../Assets/images/Roadmap/Social/Facebook.svg";
import { ReactComponent as Medium } from "../../Assets/images/Roadmap/Social/Medium.svg";
import { ReactComponent as Website } from "../../Assets/images/Roadmap/Social/Website.svg";
import { ReactComponent as GitHub } from "../../Assets/images/Roadmap/Social/GitHub.svg";
import { ReactComponent as Twitter } from "../../Assets/images/Roadmap/Social/Twitter.svg";
import { ReactComponent as Tick } from "../../Assets/images/Roadmap/tick.svg";

import ModalManager from "../Shared/ModalManager/ModalManager";
import { RoadmapModal } from "./RoadmapModal/RoadmapModal";

import { useDispatch } from "react-redux";
import { openModal, OPEN_MODAL } from "../../redux/Actions/Modal/modal";

import { pxToRem, pxToVw } from "../../Utils/pxToRem";
import { Modal } from "./RoadmapModal/RoadmapModal";
import { Navigate } from "react-router-dom";

const Roadmap = ({ auth }) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(true);

  if (!auth) {
    return <Navigate to="/" />;
  }
  const classes = useStyles();
  const socialMediaIcons = {
    LinkedIn,
    Dribble,
    Behance,
    Instagram,
    Facebook,
    Medium,
    Website,
    GitHub,
    Twitter,
    // Pinterest,
  };
  const socialMedia = [
    { platform: "Facebook", link: "http://fb.com" },
    { platform: "Dribble", link: "http://dribble.com" },
    { platform: "Instagram", link: "http://instagram.com" },
    { platform: "Website", link: "http://google.com" },
  ];
  const cardsData = [
    {
      title:
        "Understanding the basics of Product Management And Web Development",
      completed: "80",
    },
    {
      title: "Identifying user personas and their underserved needs",
      completed: "40",
    },
    {
      title: "Product conceptualisation and defining success metrics",
      completed: "40",
    },
    { title: "Tech for PM and working with Engineering", completed: "40" },
    { title: "Product Launch", completed: "40" },
    { title: "Cracking into PM role", completed: "40" },
    { title: "Final Case study ", completed: "40" },
  ];
  const curatedData = [
    { name: "Nicole Kidman", post: "Sr. Data Engineer, Lenskart" },
    { name: "Tim Cook", post: "Sr. Data Engineer, Lenskart" },
    { name: "Faf Du Plesis", post: "Sr. Data Engineer, Lenskart" },
    // {name:"",post:""},
  ];
  const modalData = {
    heading: "What is a Product",
    description:
      "Data powers today's world. From unleashing innovations to improving decision-making processes, data holds the potential to unlock the success of every industry. ",
    resources: [
      {
        title: "How to build a career in Data Science?",
        type: "File",
        link: "http://www",
        completed: true,
      },
      {
        title: "Job role, Skills , Salaries",
        type: "File",
        link: "http://www",
        completed: true,
      },
      {
        title: "Getting Started with R",
        type: "Video",
        link: "http://www",
        completed: true,
      },
      {
        title: "Linear Regression",
        type: "Video",
        link: "http://www",
        completed: true,
      },
      {
        title: "Random Forests",
        type: "Video",
        link: "http://www",
        completed: false,
      },
      {
        title: "Who are data scientists?",
        type: "Video",
        link: "http://www",
        completed: false,
      },
    ],
  };

  const handleModalOpen = () => {
    dispatch(
      openModal({ type: "ROADMAPMODAL", open: openModal, data: modalData })
    );
  };

  const insideAccordionHeading = () => {
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot></TimelineDot>
          <TimelineConnector style={{ background: "#0EC99E" }} />
        </TimelineSeparator>
        <TimelineContent>
          <div className={classes.insideAccordionHeading}>
            INTRODUCTION TO PRODUCT MANAGEMENT
          </div>
        </TimelineContent>
      </TimelineItem>
    );
  };

  const insideAccordionMiddle = (iscompleted = false) => {
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector style={{ background: "#0EC99E" }} />
          <TimelineDot>
            <Arrow4 />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div
            className={classes.insideAccordionTopicDiv}
            style={{ background: `${iscompleted ? "#E3FFF9" : null}` }}
            onClick={handleModalOpen}
          >
            {/* <div className={classes.insideAccordionTopicWrapper}> */}
            {/* <Tick className={classes.insideAccordionTopicTick} /> */}
            <div className={classes.insideAccordionTopic}>
              {modalData.heading}
            </div>
            {/* </div> */}
          </div>
        </TimelineContent>
      </TimelineItem>
    );
  };

  const insideAccordionLast = (iscompleted = false) => {
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <Arrow3 />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div
            className={classes.insideAccordionTopicDiv}
            style={{ background: `${iscompleted ? "#E3FFF9" : null}` }}
            onClick={handleModalOpen}
          >
            <div className={classes.insideAccordionTopic}>
              {modalData.heading}
            </div>
          </div>
        </TimelineContent>
      </TimelineItem>
    );
  };

  const insideAccordion = () => {
    return (
      <div className={classes.insideAccordion}>
        <Timeline position="right">
          {insideAccordionHeading()}
          {insideAccordionMiddle(false)}
          {insideAccordionLast(true)}
        </Timeline>
      </div>
    );
  };

  const accodianRoadmap = (index, title) => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowSvg />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.CardHeadingContainer}>
            {index + 1 != cardsData.length ? (
              <>
                <div className={classes.numberFont}>{index + 1}</div>
                <div className={classes.CardHeading}>{title}</div>
              </>
            ) : (
              <>
                <div className={classes.numberFontLast}>
                  <Trophy />
                </div>
                <div className={classes.CardHeading}>
                  <div className={classes.CardHeadingBold}>
                    Capstone Project : &nbsp;
                  </div>
                  {title}
                </div>
              </>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {insideAccordion()}
          {insideAccordion()}
        </AccordionDetails>
      </Accordion>
    );
  };

  const accodianRoadmapContainer = (data, index) => {
    return (
      <TimelineItem key={index}>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>{accodianRoadmap(index, data.title)}</TimelineContent>
      </TimelineItem>
    );
  };

  const AuthurCard = (data, index) => {
    return (
      <div className={classes.roadmapAuthurCard} key={index}>
        <div className={classes.roadmapAuthurCardPicContainer}>
          <img src={Aurthorimg} className={classes.roadmapAurthurImg} />
        </div>
        <div className={classes.roadmapAuthurCardDetailsContainer}>
          <div className={classes.roadmapAuthurCardName}>{data.name}</div>
          <div className={classes.roadmapAuthurCardPosition}>{data.post}</div>
          <div className={classes.roadmapAuthurCardSocialIcons}>
            {socialMedia.map((social, index) => {
              const Icon = socialMediaIcons[social.platform];
              return (
                <a href={social.link} target="blank" key={index}>
                  <Icon className={classes.roadmapAuthurCardSocialIcon} />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.roadmapPage}>
        <Topbar />
        <ModalManager />
        {/* <RoadmapModal /> */}
        <div className={classes.roadmapPageWrapper}>
          <div className={classes.roadmapHeading}>
            <p>Data Science Learning Path</p>
            {/* <div className={classes.roadmapHeadingCompanyDetailsWrapper}>
              <div className={classes.cardCompanyDiv}>
                <img src={Company4png} className={classes.cardCompanyIcon} />
                <img src={Company4png} className={classes.cardCompanyIcon} />
              </div>
              <div className={classes.roadmapHeadingCompanyTitle}>
                3.2k following this path
              </div>
            </div> */}
          </div>
          <div className={classes.roadmapContentWrapper}>
            <div className={classes.Cards}>
              <Timeline position="right">
                {cardsData.map((data, index) => {
                  return accodianRoadmapContainer(data, index);
                })}
              </Timeline>
            </div>
            <div className={classes.roadmapSideContent}>
              <div className={classes.roadmapSideContentHeading}>
                CURATED BY
              </div>

              <div className={classes.roadmapAuthurCardContainer}>
                {curatedData.map((data, index) => {
                  return AuthurCard(data, index);
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
