import { makeStyles } from "@mui/styles";
import { pxToVw, pxToMvw, pxToVh, pxToMvh } from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: pxToVh(5),
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvh(5),
    },
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    gap: pxToVw(25),
    paddingLeft: pxToVw(47),
    paddingRight: pxToVw(10),
    width: pxToVw(620),
    height: pxToVw(80),
    backgroundColor: "white",
    borderRadius: pxToVw(40),
    border: (props) => (props.touched ? `2px solid ${props.color}` : "none"),
    boxShadow: "0 10px 20px 0 #0EC99E12",
    "&:focus-within": {
      transition: "all .3s ease",
      border: (props) =>
        props.touched ? `2px solid ${props.color}` : "2px solid #0EC99E",
    },
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvw(10),
      width: pxToMvw(400),
      height: pxToMvw(52),
      paddingLeft: pxToMvw(20),
      borderRadius: pxToMvw(40),
    },
  },

  inputIcon: {
    width: pxToVw(24),
    height: pxToVw(24),
    "& svg": {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(16),
      height: pxToMvw(16),
      "& svg": {
        width: "100%",
        height: "100%",
      },
    },
  },
  eyeWrapper: {
    width: pxToVw(32),
    height: pxToVw(32),
    marginRight: pxToVw(24),
    "& svg": {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(24),
      height: pxToMvw(24),
      marginRight: pxToMvw(10),

      "& svg": {
        width: "100%",
        height: "100%",
      },
    },
  },
  input: {
    display: "flex",
    gap: pxToVw(9),
    width: "80%",
    height: "100%",
    border: "none",
    outline: "none",
    borderRadius: pxToVw(40),
    justifyContent: "center",
    alignItems: "center",
    transition: "all .3s ease",
    color: "#545454",
    backgroundColor: "#ffffff",
    fontFamily: "Poppins,sans-serif",
    fontSize: pxToVw(18),
    fontWeight: 400,
    "&::placeholder": {
      color: "#9D9D9D",
      fontFamily: "Poppins,sans-serif",
      fontSize: pxToVw(18),
      fontWeight: 400,
      letterSpacing: 0,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
      "&::placeholder": {
        fontSize: pxToMvw(12),
      },
    },
  },
  passwordText: {
    width: pxToVw(450),
    fontFamily: "Poppins,sans-serif",
    height: "100%",
    border: "none",
    outline: "none",
    fontSize: pxToVw(18),
    color: "rgba(84, 84, 84, 1)",
    fontWeight: 400,
    letterSpacing: pxToVw(5),
    "&::placeholder": {
      color: "#9D9D9D",
      fontFamily: "Poppins,sans-serif",
      fontSize: pxToVw(18),
      fontWeight: 400,
      letterSpacing: 0,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        fontSize: pxToMvw(12),
        lineHeight: pxToMvw(30),
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: pxToMvw(14),
    },
  },
  formHeading: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "500",
    fontSize: pxToVw(16),
    lineHeight: pxToVw(26),
    color: (props) => (props.error ? "red" : "#9D9D9D"),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(16),
      lineHeight: pxToMvw(26),
    },
  },
  errorWrapper: {
    width: pxToVw(535),
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(320),
    },
  },
});
