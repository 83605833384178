import React from "react";
import { useStyles } from "./Styles";
import AddButton from "./AddButton/AddButton";
import Carousel from "./Carousal/Carousal";
import { ReactComponent as Edit } from "../../../Assets/images/ProfilePage/Section/Carousal/edit.svg";
import { ReactComponent as Add } from "../../../Assets/images/ProfilePage/Section/Carousal/add.svg";

const Section = ({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.header}>
        <p className={classes.heading}>{title}</p>
        <div className={classes.icons}>
          <Edit className={classes.icon} />
          <Add className={classes.icon} />
        </div>
      </div>
      <Carousel />
    </div>
  );
};

export default Section;
