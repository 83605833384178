/*eslint no-unused-vars:*/
import { makeStyles } from "@mui/styles";
import { pxToMvh, pxToMvw, pxToVh, pxToVw } from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";
export const useStyles = makeStyles({
  basicDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      height: "100vh",
    },
  },
  inputWrappers: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: pxToVw(24),
    paddingBottom: pxToVh(50),
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvh(18),
      marginTop: pxToMvh(20),
    },
  },
  profilePhotoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: pxToVw(128),
    height: pxToVw(128),
    backgroundColor: "white",
    borderRadius: pxToVw(40),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(80),
      height: pxToMvw(80),
      borderRadius: pxToMvw(25),
    },
  },
  profileImage: {
    width: "100%",
    height: "100%",
    borderRadius: pxToVw(40),
    objectFit: "cover",
    objectPosition: "top",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(80),
      height: pxToMvw(80),
      borderRadius: pxToMvw(25),
    },
  },
  photoLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    borderRadius: pxToVw(40),
    objectFit: "cover",
    objectPosition: "top",
    cursor: "pointer",
  },
  profilePhoto: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: pxToVw(111),
    height: pxToVw(107),
    border: "2px dashed #9D9D9D",
    borderRadius: pxToVw(40),
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(69),
      height: pxToMvw(69),
      borderRadius: pxToMvw(25),
    },
  },
  plus: {
    fontSize: pxToVw(20),
    color: "#9D9D9D",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(16),
    },
  },
  text: {
    color: "#9D9D9D",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(14),
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(10),
    },
  },
  button: {
    display: "flex",
    gap: pxToVw(30),
  },
  back: {
    width: pxToVw(140),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    backgroundColor: "rgba(227, 255, 249, 1)",
    border: "2px solid rgba(14, 201, 158, 1)",
    fontSize: pxToVw(18),
    fontFamily: "Poppins,sans-serif",
    cursor: "pointer",
  },
  next: {
    width: pxToVw(140),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    border: (props) => (props.disabled ? "none" : "2px solid #0EC99E"),
    background: (props) =>
      props.disabled
        ? "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), linear-gradient(0deg, #0EC99E, #0EC99E)"
        : "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%),linear-gradient(0deg, #0EC99E, #0EC99E)",
    color: "white",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    cursor: (props) => (props.disabled ? "not-allowed" : "pointer"),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(106),
      height: pxToMvw(48),
      borderRadius: pxToMvw(40),
      fontSize: pxToMvw(12),
    },
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(48),
    lineHeight: pxToVw(86),
    background: "linear-gradient(89.61deg, #00634C -19%, #009270 92.23%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    paddingBottom: pxToVh(28),
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(28),
      lineHeight: pxToMvw(32),
    },
  },
});
