import React, { useEffect, useState } from "react";
import { useStyles } from "./Styles";
import { Carousel } from "react-responsive-carousel";
import posterMb from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/posterMb.png";
import { ReactComponent as LeftArrow } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/rightArrow.svg";
import CardCarousalItem from "./CardCarousalItem";
import ExploreCarousal from "../../../Shared/ExploreCarousal";
import { Carousel as Carousel3D } from "3d-react-carousal";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CardCarousal = ({ careerExpertsArr }) => {
  const classes = useStyles();
  const [mobileArr, setMobileArr] = useState([]);
  const [desktopArr, setDesktopArr] = useState([]);

  useEffect(() => {
    if (careerExpertsArr) {
      let arr = careerExpertsArr.map((carousalCardData) => {
        return (
          <CardCarousalItem
            carousalCardData={carousalCardData}
            mobileView={true}
          />
        );
      });
      setMobileArr(arr);
      let dArr = [];
      setDesktopArr(careerExpertsArr);
    }
  }, [careerExpertsArr]);

  function SampleNextArrow(props) {
    const { currentSlide, onClick } = props;
    if (currentSlide > 4) {
      return false;
    } else {
      return <RightArrow className={classes.rightArrow} onClick={onClick} />;
    }
  }

  function SamplePrevArrow(props) {
    const { currentSlide, onClick } = props;
    if (currentSlide === 0) {
      return false;
    } else {
      return <LeftArrow className={classes.leftArrow} onClick={onClick} />;
    }
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          infinite: true,
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log("asdf ", desktopArr);
  return (
    <>
      <div className={classes.cardCarousalDiv}>
        <Slider {...settings} className={classes.carousal}>
          {desktopArr?.map((data, index) => {
            return <CardCarousalItem data={data} key={index} />;
          })}
          {/* <CardCarousalItem twoCardArr={mobileArr[0]} /> */}
        </Slider>
      </div>

      {/* Below Carousel Will Map for Mobile View */}
      {/* <div className={classes.cardCarousalDivMobile}>
        {mobileArr?.length > 0 && (
          <Carousel3D
            slides={mobileArr}
            autoplay={false}
            interval={1000}
            className={classes.cardCarousal3DContainer}
          />
        )}
      </div> */}
    </>
  );
};

export default CardCarousal;
