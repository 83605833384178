import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw } from "../../Utils/pxToRem";

export const useStyles = makeStyles({
  profile: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    backgroundColor: "#F6F6F6",
  },
  body: {
    height: "100%",
    paddingBottom: pxToVh(159),
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: pxToVh(100),
    backgroundColor: "#F6F6F6",
    background:
      "linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 96.13%)",
  },
  AboutSection: {
    height: "100%",
    paddingBottom: pxToVh(110),
    width: "100%",
  },
});
