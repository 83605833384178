import React, { useState, useEffect } from "react";
import ReelsModal from "./Modal";
import Card from "./Card/Card";
import { useStyles } from "./Styles";
import { axiosClient } from "../../Services/apiClient";
import { useDispatch } from "react-redux";
import { setData } from "../../redux/Actions/ExploreCareer";
import Topbar from "../Shared/Topbar/Topbar";
import { Navigate } from "react-router";

const ExploreCareers = ({ auth }) => {
  if (!auth) {
    return <Navigate to="/" />;
  }
  const dispatch = useDispatch();

  const ApiData = {
    status: true,
    message: "Career data",
    data: [
      {
        salary: {
          low: "60",
          mid: "90",
          high: "120",
        },
        _id: "6245a145b4d7c3b0c3304e2a",
        name: "Data Scientist",
        logo: "#",
        description:
          "Data science is an interdisciplinary field that uses scientific methods, processes, algorithms and systems to extract knowledge and insights. Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary re",
        req_skills: [
          "C ",
          "C++",
          "React",
          "JavaScript",
          "Java",
          "React Native",
          "Back End Development",
          "Front End Development",
          "Etherium and Blockchain Heleo",
          "Python",
          "Python",
          "Python",
          "Python",
          "NumPy",
          "Pandas",
          "Pandas",
          "JavaScript",
          "Front End Development",
          "Java",
          "Flask",
        ],
        career_experts: [
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "John",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: ["Facebook", "Twitter"],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "Lalu",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: ["Facebook", "Twitter"],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "Heena",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: ["Facebook", "Twitter"],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "John",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: [
              { platform: "Facebook", link: "http://fb.com" },
              { platform: "Instagram", link: "http://fb.com" },
            ],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
        ],
        companies_hiring: [
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Twitter",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2c",
          },
        ],
        roadmap_to_career: [],
        is_career_followed: false,
        __v: 0,
      },
      {
        salary: {
          low: "60",
          mid: "90",
          high: "120",
        },
        _id: "6245a145b4d7c3b0c3304e2a",
        name: "Data Scientist",
        logo: "#",
        description:
          "Data science is an interdisciplinary field that uses scientific methods, processes, algorithms and systems to extract knowledge and insights.",
        req_skills: [
          "Pandas",
          "NumPy",
          "NumPy",
          "Flask",
          "Flask",
          "Flask",
          "Flask",
          "Front-end Development",
          "Ethereum Development",
          "Back-end Development",
          "Ethereum Development",
          "Web Development",
        ],
        career_experts: [
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "Hmm..",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: ["Facebook", "Twitter"],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "Hmm..",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: ["Facebook", "Twitter"],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "Hmm..",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: ["Facebook", "Twitter"],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "Hmm..",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: ["Facebook", "Twitter"],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "Hmm..",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: ["Facebook", "Twitter"],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "Hmm..",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: ["Facebook", "Twitter"],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
        ],
        companies_hiring: [
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Twitter",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2c",
          },
        ],
        roadmap_to_career: [],
        is_career_followed: false,
        __v: 0,
      },
      {
        salary: {
          low: "60",
          mid: "90",
          high: "120",
        },
        _id: "6245a145b4d7c3b0c3304e2a",
        name: "Data Scientist",
        logo: "#",
        description:
          "Data science is an interdisciplinary field that uses scientific methods, processes, algorithms and systems to extract knowledge and insights.",
        req_skills: [
          "Ethereum Development",
          "Ethereum Development",
          "Ethereum Development",
          "Ethereum Development",
          "Flask",
          "Flask",
          "Pandas",
          "Flask",
          "Flask",
          "NumPy",
          "Flask",
          "Ethereum Development",
        ],
        career_experts: [
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "John",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: ["Facebook", "Twitter"],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
        ],
        companies_hiring: [
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Twitter",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2c",
          },
        ],
        roadmap_to_career: [],
        is_career_followed: false,
        __v: 0,
      },
      {
        salary: {
          low: "60",
          mid: "90",
          high: "120",
        },
        _id: "6245a145b4d7c3b0c3304e2a",
        name: "Data Scientist",
        logo: "#",
        description:
          "Data science is an interdisciplinary field that uses scientific methods, processes, algorithms and systems to extract knowledge and insights.",
        req_skills: ["Pandas", "NumPy", "Flask", "Ethereum Development"],
        career_experts: [
          {
            _id: "6245a09ab4d7c3b0c3304e25",
            name: "John",
            company_name: "Facebook",
            company_logo: "#",
            career_name: "Data Scientist",
            designation: "Senior Analyst",
            experts_image: "#",
            view_count: 9,
            social_media_handeler: ["Facebook", "Twitter"],
            experts_faq: ["62456b7e0c40a197365d73eb"],
            __v: 0,
          },
        ],
        companies_hiring: [
          {
            name: "Facebook",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2b",
          },
          {
            name: "Twitter",
            logo: "#",
            _id: "6245a145b4d7c3b0c3304e2c",
          },
        ],
        roadmap_to_career: [],
        is_career_followed: false,
        __v: 0,
      },
    ],
  };

  const classes = useStyles();
  const [cardDataArr, setCardDataArr] = useState([]);

  useEffect(() => {
    const getCardData = async () => {
      // const response = await axiosClient.get("/getCareer");
      // setCardDataArr(response.data.data);
      // dispatch(setData(response.data.data));

      // console.log("The Response is --> ", response.data.data, ApiData);

      //console.log("The Response Offline is --> ", ApiData.data[0]);
      dispatch(setData(ApiData.data));
      setCardDataArr(ApiData.data);
    };
    getCardData();
  }, []);

  return (
    <div className={classes.explorePage}>
      <Topbar />

      <div className={classes.explorePageWrapper}>
        <div className={classes.exploreHeading}>
          Explore Recommended Careers
        </div>
        <div className={classes.exploreContainer}>
          {cardDataArr.map((cardData, index) => {
            return <Card data={cardData} key={index} />;
          })}
        </div>
        <div className={classes.explorePageShowMoreDiv}>
          <button className={classes.explorePageShowMore}>
            Show more Recommended Careers
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExploreCareers;
