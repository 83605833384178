/* eslint-disable no-unused-vars */
import React, { useRef,useState } from "react";
import { useStyles } from "./styles";
import { ReactComponent as TextAreaIcon } from "../../../Assets/images/RecruiterOnboard/Company_Page/TextArea.svg";
import { makeStyles } from "@mui/styles";



const TextArea = (props) => {

    const styles = useStyles();


    const focusRef = useRef();
    const [text, setText] = useState(0);
    const [foc, setFoc] = useState(false);

    const setFocus = () => {
        setFoc(true);
        focusRef.current.focus()

    }

     const status = () => {
    if (!props.valid && props.touched) {
            return "2px solid rgba(244, 67, 54, 1)"
    } if (props.valid && props.touched) {
            return "2px solid #0EC99E"
        }
        else {
            return ""
        }
    }

     const iconStatus = () => {
    if (!props.valid && props.touched) {
            return "rgba(169, 169, 169, 1)"
    } if (props.valid && props.touched) {
            return "rgba(84, 84, 84, 1)"
        }
        else {
            return ""
        }
    }

    const textHandler = (e) => {
        props.onchange(e);
        setText(e.target.value.length);


    }

    const dynamicStyle = makeStyles({
    iconContainer: {
      "& svg": {
        fill: iconStatus()
        
      }
    }
  })();

    return (
        
            <div className={styles.textWrapper } >
                <div className={`${styles.textAreaContainer} ${dynamicStyle.iconContainer}`} style={{ border: status() }} onClick={setFocus}>
                    {/* <div className={dynamicStyle.iconContainer}> */}
                    {props.icon}
                    {/* </div> */}
                    <textarea maxLength={props?.limit || 3000}  autoComplete="off" type={"text"} onBlur={() => setFoc(false)} ref={focusRef} className={styles.inputTextArea} placeholder={props.placeholder} value={props.value} onChange={textHandler}>
                    </textarea>
                </div>
             
            { props.note && foc && <span className={styles.noteContainer}>
            <p>{text}/{props.limit}</p>
        </span>}
        </div>
        
    )
}

export default TextArea;