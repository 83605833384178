import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw } from "../../../../Utils/pxToRem";

export const useStyles = makeStyles({
  carousal: {
    display: "flex",
    gap: pxToVw(20),
    width: "100%",
    borderRadius: pxToVw(24),
    paddingTop: pxToVw(10),
    paddingBottom: pxToVw(10),
    position: "relative",
  },
  carousalInnerWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  leftArrow: {
    width: pxToVw(36),
    height: pxToVw(36),
    position: "absolute",
    left: -50,
    top: "45%",
    zIndex: 2,
    cursor: "pointer",
  },
  rightArrow: {
    width: pxToVw(36),
    height: pxToVw(36),
    position: "absolute",
    right: 30,
    top: "45%",
    zIndex: 2,
    cursor: "pointer",
  },
});
