/*eslint no-unused-vars:*/
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { pxToMvh, pxToMvw, pxToVh, pxToVw } from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";

const Header = ({ page, setPage }) => {
  const [step, setStep] = useState({
    step1: {
      bgColor: "#0EC99E",
      color: "white",
    },
    step2: {
      bgColor: "#E3FFF9",
      color: "#0EC99E",
    },
    step3: {
      bgColor: "#E3FFF9",
      color: "#0EC99E",
    },
  });

  useEffect(() => {
    if (page === 0) {
      setStep({
        ...step,
        step2: {
          bgColor: "#E3FFF9",
          color: "#0EC99E",
        },
      });
    }
    if (page === 1) {
      setStep({
        ...step,
        step2: {
          bgColor: "#0EC99E",
          color: "white",
        },
        step3: {
          bgColor: "#E3FFF9",
          color: "#0EC99E",
        },
      });
    }
    if (page === 2) {
      setStep({
        ...step,
        step2: {
          bgColor: "#0EC99E",
          color: "white",
        },
        step3: {
          bgColor: "#0EC99E",
          color: "white",
        },
      });
    }
  }, [page]);

  const useStyles = makeStyles({
    header: {
      display: "flex",
    },
    bar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: pxToVh(20),
    },
    barContainer: {
      display: "flex",
      width: "fit-content",
      height: pxToVh(60),
      [theme.breakpoints.down("sm")]: {
        height: pxToMvh(30),
      },
    },
    barCircleWrapper: {
      display: "flex",
      alignItems: "center",
    },
    barCircle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: pxToVw(35),
      height: pxToVw(35),
      borderRadius: "50%",
      border: "1px solid #0EC99E80",
      fontFamily: "'Poppins', sans-serif;",
      fontWeight: "400",
      fontSize: pxToVw(16),
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: pxToMvw(25),
        height: pxToMvw(25),
        fontSize: pxToMvw(12),
      },
    },
    barLine: {
      width: pxToVw(223),
      height: pxToVh(4),
      backgroundColor: "#0EC99E80",
      [theme.breakpoints.down("sm")]: {
        width: pxToMvw(120),
        height: pxToMvh(2),
      },
    },
    one: {
      backgroundColor: step?.step1?.bgColor,
      color: step.step1?.color,
    },
    two: {
      backgroundColor: step?.step2?.bgColor,
      color: step.step2?.color,
    },
    three: {
      backgroundColor: step?.step3?.bgColor,
      color: step.step3?.color,
    },
    title: {
      fontFamily: "'Poppins', sans-serif;",
      fontWeight: "400",
      fontSize: pxToVw(16),
      color: "#009270",
      [theme.breakpoints.down("sm")]: {
        fontSize: pxToMvw(12),
      },
    },
    barText: {
      display: "flex",
      gap: pxToVw(130),
      [theme.breakpoints.down("sm")]: {
        gap: pxToMvw(50),
      },
    },
  });

  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div className={classes.bar}>
        <div className={classes.barContainer}>
          <div className={classes.barCircleWrapper}>
            <div className={`${classes.barCircle} ${classes.one}`}>1</div>
            <div className={`${classes.barLine} }`}></div>
          </div>

          <div className={classes.barCircleWrapper}>
            <div className={`${classes.barCircle} ${classes.two}`}>2</div>
            <div className={`${classes.barLine} }`}></div>
          </div>

          <div className={classes.barCircleWrapper}>
            <div className={`${classes.barCircle} ${classes.three}`}>3</div>
          </div>
        </div>
        <div className={classes.barText}>
          <p className={classes.title}>Enter Basic Details</p>
          <p className={classes.title}>Select Interests</p>
          <p className={classes.title}>Recent Education</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
