/*eslint no-unused-vars:*/
import { makeStyles } from "@mui/styles";
import { theme } from "../../../Utils/breakpoints";
import { pxToMvw, pxToVh, pxToVw } from "../../../Utils/pxToRem";
export const useStyles = makeStyles({
  final: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    gap: pxToVh(50),
  },
  next: {
    paddingLeft: pxToVw(30),
    paddingRight: pxToVw(30),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    border: (props) => (props.disabled ? "none" : "2px solid #0EC99E"),
    background: (props) =>
      props.disabled
        ? "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), linear-gradient(0deg, #0EC99E, #0EC99E)"
        : "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%),linear-gradient(0deg, #0EC99E, #0EC99E)",
    color: "white",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    cursor: (props) => (props.disabled ? "not-allowed" : "pointer"),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: pxToMvw(20),
      paddingRight: pxToMvw(20),
      height: pxToMvw(50),
      borderRadius: pxToMvw(40),
      fontSize: pxToMvw(12),
    },
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(48),
    lineHeight: pxToVw(86),
    background: "linear-gradient(89.61deg, #00634C -19%, #009270 92.23%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(28),
      lineHeight: pxToMvw(32),
    },
  },
  tick: {
    width: pxToVw(240),
    height: pxToVw(240),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(170),
      height: pxToMvw(170),
    },
  },
});
