import { takeLatest, all } from "redux-saga/effects";
import { LOGIN_START } from "../Actions/StudentOnboarding/auth";
import { handleLogin } from "./handlers/auth";
import {
  watcherGetCompanyName,
  watcherGetindustry,
  watcherDomainSelect,
  watcherGetCompanyType,
  watchersetCompanySize,
} from "./RecruiterOnboarding/recruiterOnboarding";

export default function* rootSaga() {
  yield all([
    // watcherGeneralForm(),
    watcherDomainSelect(),
    // watcherCompanyPage(),
    watcherGetCompanyType(),
    watchersetCompanySize(),
    watcherGetCompanyName(),
    watcherGetindustry(),
    // watcherGetAddCompany(),
    // takeLatest(LOGIN_START, handleLogin),
  ]);
}
