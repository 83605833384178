/*eslint no-unused-vars:*/
import { makeStyles } from "@mui/styles";
import { pxToMvh, pxToMvw, pxToVh, pxToVw } from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";

export const useStyles = makeStyles({
  interests: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: pxToVh(24),
    paddingBottom: pxToVh(34),
    "& p": {
      fontFamily: "'Poppins', sans-serif;",
      fontWeight: "500",
      fontSize: pxToVw(20),
      color: "#9D9D9D",
    },
    [theme.breakpoints.down("sm")]: {
      "& p": {
        fontSize: pxToMvw(14),
      },
    },
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(48),
    lineHeight: pxToVw(86),
    background: "linear-gradient(89.61deg, #00634C -19%, #009270 92.23%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    paddingBottom: pxToVh(28),
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(28),
      lineHeight: pxToMvw(32),
    },
  },
  domainWrapper: {
    display: "flex",
    justifyContent: "center",
    width: pxToVw(800),
    flexWrap: "wrap",
    gap: pxToVw(10),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: pxToMvw(20),
      paddingRight: pxToMvw(20),
      paddingBottom: pxToMvh(30),
      gap: pxToMvw(10),
    },
  },
  domains: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: pxToVw(20),
    paddingRight: pxToVw(20),
    height: pxToVw(48),
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(14),
    borderRadius: pxToVw(40),
    color: "#000000",
    backgroundColor: "#E3FFF9",
    border: "2px solid #0EC99E",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: pxToMvw(13),
      paddingRight: pxToMvw(13),
      height: pxToMvw(32),
      fontSize: pxToMvw(12),
      borderRadius: pxToMvw(25),
    },
  },
  selected: {
    backgroundColor: "#0EC99E",
  },
  subDomains: {
    backgroundColor: "transparent",
  },
  selections: {
    display: "flex",
    alignItems: "center",
    height: pxToVw(48),
    borderRadius: pxToVw(40),
    color: "#000000",
    backgroundColor: "#E3FFF9",
    border: "2px solid #0EC99E",
    cursor: "pointer",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(14),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: pxToMvw(13),
      paddingRight: pxToMvw(0),
      height: pxToMvw(32),
      fontSize: pxToMvw(12),
      borderRadius: pxToMvw(25),
    },
  },
  selection: {
    paddingLeft: pxToVw(20),
    paddingRight: pxToVw(15),
    border: "none",
    outline: "none",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(14),
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    },
  },
  button: {
    display: "flex",
    gap: pxToVw(30),
  },
  back: {
    width: pxToVw(140),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    backgroundColor: "rgba(227, 255, 249, 1)",
    border: "2px solid rgba(14, 201, 158, 1)",
    fontSize: pxToVw(18),
    fontFamily: "Poppins,sans-serif",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(106),
      height: pxToMvw(48),
      fontSize: pxToMvw(12),
      borderRadius: pxToMvw(40),
    },
  },
  next: {
    width: pxToVw(140),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    border: (props) => (props.disabled ? "none" : "2px solid #0EC99E"),
    background: (props) =>
      props.disabled
        ? "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), linear-gradient(0deg, #0EC99E, #0EC99E)"
        : "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%),linear-gradient(0deg, #0EC99E, #0EC99E)",
    color: "white",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    cursor: (props) => (props.disabled ? "not-allowed" : "pointer"),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(106),
      height: pxToMvw(48),
      fontSize: pxToMvw(12),
      borderRadius: pxToMvw(40),
    },
  },
  cross: {
    width: pxToVw(40),
    height: pxToVw(40),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(25),
      height: pxToMvw(25),
    },
  },
});
