import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw } from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";

export const useStyles = makeStyles({
  About__Wrapper: {
    margin: "auto",
    width: "80%",
    padding: pxToVw(2),
    marginTop: pxToVw(50),
  },
  About__Header: {
    fontSize: pxToVw(38),
    fontWeight: "600",

    background:
      "linear-gradient(88.03deg, #012E3E 1.33%, #1E977C 3.4%, #157665 4.08%, #20A284 4.67%, #075353 8.1%),#0EC99E",
    // "linear-gradient(#012E3E 15.68%, #1C6353 34.42%, #157665 55.97%, #20A284 75.65%), #0EC99E",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  About__Description: {
    marginTop: pxToVw(18),
    display: "block",
  },
  About__SeeMore: {
    color: "#0EC99E",
    marginLeft: pxToVw(2),
  },
});
