import React from "react";
import { useStyles } from "./Styles";

const Title = () => {
  const classes = useStyles();
  return (
    <div className={classes.title}>
      <h1 className={classes.text}>Get Rewarded for Skills, Not Pedigree.</h1>
      <p className={classes.subtext}>
        A career focused growth community for early career professionals to
        explore career opportunities, get guidance from top professionals and
        get hired with proof of work.
      </p>
    </div>
  );
};

export default Title;
