/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import { useStyles } from "./styles";
import { makeStyles } from "@mui/styles";

const InputPassword = (props) => {
  const styles = useStyles();
  const focusRef = useRef();
  const [text, setText] = useState();

  const [foc, setFoc] = useState(false);

  const setFocus = () => {
    setFoc(true);
    focusRef.current.focus();
  };

  // useEffect(() => {
  //     console.log(props.value);
  //     if (props.value.length > 0) {
  //         props.valid = true;
  //     }
  // },[props.value])

  const status = () => {
    if (!props.valid && props.touched) {
      return "2px solid rgba(244, 67, 54, 1)";
    }
    if (props.valid && props.touched) {
      return "2px solid #0EC99E";
    } else {
      return "";
    }
  };

  const iconStatus = () => {
    if (!props.valid && props.touched) {
      return "rgba(169, 169, 169, 1)";
    }
    if (props.valid && props.touched) {
      return "rgba(84, 84, 84, 1)";
    } else {
      return "";
    }
  };

  const textHandler = (e) => {
    if (props.note) {
      setText(e.target.value.length);
      props.onchange(e);
    } else {
      props.onchange(e);
    }
  };

  const dynamicStyle = makeStyles({
    iconContainer: {
      "& svg": {
        fill: iconStatus(),
      },
    },
  })();

  return (
    <form className={styles.errorWrapper} autoComplete="new-password">
      <div
        className={`${styles.inputContainer} ${dynamicStyle.iconContainer}`}
        style={{ border: status() }}
        onClick={setFocus}
      >
        <div className={styles.innerContainer}>
          {props.icon}
          <input
            readOnly={props.editable == undefined ? false : !props.editable}
            autoComplete="off"
            type={"text"}
            maxLength={props?.limit || 3000}
            ref={focusRef}
            onBlur={() => setFoc(false)}
            value={props.value}
            onChange={textHandler}
            className={styles.inputText}
            placeholder={props.placeholder}
          />
        </div>
      </div>
      {/* <div className={styles.animateDiv}> */}
      {!props.valid && props.touched && (
        <span className={styles.errorNote}>{props.errorNote}</span>
      )}
      {props.note && foc && (
        <span className={styles.noteContainer}>
          <p>
            {text || 0}/{props.limit}
          </p>
        </span>
      )}
      {/* </div> */}
    </form>
  );
};

export default InputPassword;
