import { makeStyles } from "@mui/styles";
import bimage from "../../../Assets/images/LandingPage/Subscribe/bimage.png";
import { pxToMvh, pxToMvw, pxToVh, pxToVw } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const useStyles = makeStyles({
  subscribe: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // width: pxToVw(1920),
    gap: pxToVh(175),
    paddingTop: pxToVh(205),
    paddingBottom: pxToVh(200),
    backgroundImage: `url(${bimage})`,
    backgroundRepeat: "round",
    position: "relative",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(480),
      height: "fit-content",
      gap: pxToMvh(0),
      paddingTop: pxToMvh(30),
      paddingBottom: pxToMvh(30),
    },
  },
  bottomWrapper: {
    display: "flex",
    width: "100%",
    paddingLeft: pxToVw(238),
    paddingRight: pxToVw(238),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: pxToVw(58),
    [theme.breakpoints.down("mb")]: {
      paddingLeft: pxToMvw(0),
      paddingRight: pxToMvw(0),
    },
  },
  //input styles
  inputWrapper: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: pxToVw(41),
    width: pxToVw(620),
    height: pxToVw(80),
    border: "1px solid #222222",
    transition: "all .2s ease",
    borderRadius: pxToVw(40),
    backgroundColor: "#222222",
    "&:focus-within": {
      border: "1px solid #0EC99E",
    },
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  input: {
    width: pxToVw(420),
    height: "100%",
    backgroundColor: "#222222",
    borderRadius: pxToVw(40),
    border: "none",
    outline: "none",
    color: "#474747",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  mobileinputWrapper: {
    [theme.breakpoints.down("mb")]: {},
  },
  button: {
    width: pxToVw(200),
    borderRadius: pxToVw(40),
    outline: "none",
    border: "none",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%)",
    webkitBackgroundClip: "text",
    webkitTextFillColor: "transparent",
    cursor: "pointer",
  },
  mobilebutton: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: "block",
      borderRadius: pxToMvw(45),
      outline: "none",
      border: "none",
      fontFamily: "'Poppins', sans-serif;",
      fontWeight: "400",
      fontSize: pxToMvw(15),
      background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%)",
      webkitBackgroundClip: "text",
      webkitTextFillColor: "transparent",
      cursor: "pointer",
      width: pxToMvw(123),
      height: pxToMvh(38),
    },
  },
  //Title Styles
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: pxToVw(1143),
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      paddingLeft: pxToMvw(30),
      paddingRight: pxToMvw(30),
    },
  },
  textWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "-webkit-linear-gradient(0deg,#AFE1D6 ,#009270)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("mb")]: {},
  },
  text: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(64),
    lineHeight: pxToVw(78),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(24),
      lineHeight: pxToMvh(26),
    },
  },
  subtext: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    color: "#9D9D9D",
    lineHeight: pxToVh(64),
    paddingTop: pxToVh(24),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(14),
      lineHeight: pxToMvh(20),
    },
  },
  //Numbers Styles
  numberWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: pxToVw(90),
    width: "100%",
    paddingLeft: pxToVw(100),
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  numberCarousal: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: "flex",

      width: "100%",
    },
  },
  carousal: {
    width: "100%",
  },
  numbers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: pxToVw(10),
    [theme.breakpoints.down("mb")]: {
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      gap: pxToMvw(5),
      paddingBottom: pxToVh(30),
    },
  },
  numberText: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "700",
    fontSize: pxToVw(44),
    background: "-webkit-linear-gradient(0deg,#AFE1D6 ,#009270)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(30),
    },
  },
  numberSubtext: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(16),
    color: "#9D9D9D",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(14),
    },
  },
  lineWrapper: {
    width: pxToVw(255),
  },
  line: {
    width: "100%",
  },
  leftvector: {
    width: pxToVw(400),
    height: pxToVh(1200),
    position: "absolute",
    left: 0,
    top: 10,
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  rightvector: {
    width: pxToVw(400),
    height: pxToVh(1200),
    position: "absolute",
    right: 0,
    top: 10,
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  dots: {
    width: pxToMvw(10),
    height: pxToMvw(10),
    borderRadius: "50%",
    display: "inline-block",
    margin: "0 8px",
  },
});
