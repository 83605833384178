import React from "react";
import { useStyles } from "./Styles";
import Review from "./Review";
import Image from "../../../Assets/images/LandingPage/Review/image.png";
import { ReactComponent as Quote } from "../../../Assets/images/LandingPage/Review/quote.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { pxToVh } from "../../../Utils/pxToRem";

const ReviewMain = () => {
  const classes = useStyles();

  var settings = {
    dots: true,
    // autoplay:true,
    infinite:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed:1000,
    // autoplaySpeed: 3500,
    // cssEase: "linear",
    pauseOnHover: true,
    appendDots: dots => (
      <div
        style={{
          borderRadius: "10px",
          paddingBottom: pxToVh(50),
        }}
      >
        <ul style={{color:"#0EC99E",size:"50px" ,margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <div className={classes.reviewMain}>
      <Quote className={classes.quote} />
      <div className={classes.headerWrapper}>
        <h1 className={classes.header}>
          This is what leaders have to say about us
        </h1>
      </div>
       <div className={classes.reviewCarousalDesktop}>
       <Slider {...settings} className={classes.carousal}>
       <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
      </Slider>
        {/* <Carousel
          className={classes.carousalDesktop}
          showArrows={false}
          showIndicators={true}
          showStatus={false}
          interval="1500"
          transitionTime="2000"
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className={classes.dots}
                  style={{
                    background: "#0EC99E",
                  }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                className={classes.dots}
                style={{
                  background: "#0EC99E30",
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
        </Carousel> */}
      </div>
      {/* <div className={classes.reviewCarousal}>
        <Carousel
          className={classes.carousal}
          showArrows={false}
          showIndicators={true}
          showStatus={false}
          autoPlay
          infiniteLoop={true}
          interval="15000"
          transitionTime="5000"
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className={classes.dots}
                  style={{
                    background: "#0EC99E",
                  }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                className={classes.dots}
                style={{
                  background: "#0EC99E30",
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
          <Review
            title="Incredible Experience"
            review="We had an incredible experience working with Glosity and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the concept so quickly. "
            image={Image}
          />
        </Carousel>
      </div>  */}
      
    </div>
  );
};

export default ReviewMain;
