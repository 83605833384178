/*eslint no-unused-vars:*/
import React, { useEffect, useState } from "react";
import { useStyles } from "./Styles";
import { ReactComponent as Degree } from "../../../../Assets/images/StudentOnboard/Form/degree.svg";
import { ReactComponent as Institute } from "../../../../Assets/images/StudentOnboard/Form/institute.svg";
import { ReactComponent as FieldOfStudy } from "../../../../Assets/images/StudentOnboard/Form/fos.svg";
import { ReactComponent as Tick } from "../../../../Assets/images/StudentOnboard/Form/tick.svg";
import Select from "../../../Shared/inputV2/DropDown";
import CustomDatePicker from "../../../Shared/inputV2/DatePicker/CustomDatePicker";
import {
  getStudentDegree,
  getStudentInstitute,
  getStudentFieldOfStudy,
} from "../../../../Services/lib/StudentOnboarding";

const Education = ({
  formPage,
  setFormPage,
  degree,
  setDegree,
  fieldOfStudy,
  setFieldOfStudy,
  institute,
  setInstitute,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handlesubmit,
  checked,
  setChecked,
}) => {
  const [disabled, setDisabled] = useState(true);

  const handlecheck = () => {
    setChecked(!checked);
    setEndDate(null);
  };
  const classes = useStyles({ disabled, checked });

  useEffect(() => {
    if (!checked) {
      if (
        degree.value &&
        fieldOfStudy.value &&
        institute.value &&
        startDate &&
        endDate
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      if (degree.value && fieldOfStudy.value && institute.value && startDate) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [degree, fieldOfStudy, institute, startDate, endDate, checked]);

  const [degreeArray, setDegreeArray] = useState([]);
  const [instituteArray, setInstituteArray] = useState([]);
  const [fieldOfStudyArray, setFieldOfStudyArray] = useState([]);
  const degreeOptions = [];
  const institituteOptions = [];
  const filedOfStudyOptins = [];

  useEffect(() => {
    const getData = async () => {
      const deg = await getStudentDegree();
      setDegreeArray(deg.res.data.data);
      const ins = await getStudentInstitute();
      setInstituteArray(ins.res.data.data);
      const fos = await getStudentFieldOfStudy();
      setFieldOfStudyArray(fos.res.data.data);
    };
    getData();
  }, []);

  degreeArray?.map((data) =>
    degreeOptions.push({ value: data.Degree, label: data.Degree })
  );

  instituteArray?.map((data) =>
    institituteOptions.push({ value: data.Institute, label: data.Institute })
  );

  fieldOfStudyArray?.map((data) =>
    filedOfStudyOptins.push({
      value: data.Specialization,
      label: data.Specialization,
    })
  );

  return (
    <div className={classes.education}>
      <h1 className={classes.title}>Recent Education</h1>
      <div className={classes.inputWrappers}>
        <Select
          icon={<Degree />}
          title="Field of study"
          placeholder="Select your Degree"
          name="filedOfStudy"
          options={degreeOptions}
          value={degree}
          setValue={setDegree}
        />
        <Select
          icon={<Institute />}
          title="Field of study"
          placeholder="Institute Name"
          name="filedOfStudy"
          options={institituteOptions}
          value={institute}
          setValue={setInstitute}
        />
        <Select
          icon={<FieldOfStudy />}
          title="Field of study"
          placeholder="Field of Study"
          name="filedOfStudy"
          options={filedOfStudyOptins}
          value={fieldOfStudy}
          setValue={setFieldOfStudy}
        />
        <div className={classes.datePickers}>
          <CustomDatePicker
            text="Start Date"
            value={startDate}
            setValue={setStartDate}
            placeholder="Start Date"
          />
          <CustomDatePicker
            text="End Date"
            value={endDate}
            setValue={setEndDate}
            placeholder="End Date"
            disabled={checked}
          />
        </div>
        <div className={classes.checkBox}>
          <div className={classes.checkInput} onClick={handlecheck}>
            {checked && <Tick />}
          </div>
          <p className={classes.text}>I am currently studying here</p>
        </div>
      </div>
      <div className={classes.button}>
        <button
          className={classes.back}
          onClick={() => {
            setFormPage(formPage - 1);
          }}
        >
          back
        </button>
        <button
          disabled={disabled}
          className={classes.next}
          onClick={handlesubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Education;
