import React, { useEffect } from "react";
import { useStyles } from "./styles";
import * as types from "../../../../redux/types";
import { useDispatch } from "react-redux";
import { ReactComponent as Complete} from "../../../../Assets/images/RecruiterOnboard/progress_completed.svg";

const Completed = () => {

    const styles = useStyles();
    const dispatch = useDispatch();


    // useEffect(() => {

    //     return () => {
    //         dispatch({ type:types.REMOVE_PERSISTENCE })
    //     }

    // },[])

    return (
        <div className={styles.CompletedContainer}>
            <div className={styles.progressWrapper}>
                <div className={styles.progressBar}>
                <Complete />
                </div>
            </div>
            <div className={styles.moreButtons}>
                <button className={styles.explore}>Explore Talent</button>
                <button className={styles.post}>Post a Job</button>
            </div>
        </div>
    )
}

export default Completed;