/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useStyles } from "./Styles";
import Text from "../../../Shared/inputV2/Text/input";
import { ReactComponent as DialPad } from "../../../../Assets/images/StudentOnboard/LoginPage/Dialpad.svg";
import { ReactComponent as Lock } from "../../../../Assets/images/StudentOnboard/LoginPage/Lock.svg";
import { ReactComponent as Eye } from "../../../../Assets/images/StudentOnboard/LoginPage/Eye.svg";
import { ReactComponent as EyeClosed } from "../../../../Assets/images/StudentOnboard/LoginPage/eyeClosed.svg";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../../../../Services/apiClient";

const SignUp = ({ values, setValues, page, setPage }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassord, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (password.length === 0) {
      setPasswordError(false);
    }
    if (confirmPassword.length === 0) {
      setConfirmPasswordError(false);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    if (
      password.length !== 0 &&
      confirmPassword.length !== 0 &&
      !passwordError &&
      !confirmPasswordError
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password, confirmPassword, confirmPasswordError, passwordError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passwordError && !confirmPasswordError) {
      try {
        const response = await axiosClient.put("/saveResettedStudentPassword", {
          PhoneNumber: values.phonenumber,
          Password: password,
        });
        if (response.data.success) {
          setPage(page + 1);
          console.log;
        }
        console.log(response.data);
      } catch (err) {
        console.log(err);
      }
    }
  };
  console.log("reset");
  const classes = useStyles({ disabled });
  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit} autoComplete="off" className={classes.form}>
        <div className={classes.inputWrappers}>
          <Text
            icon={<Lock />}
            eye={
              !showPassord ? (
                <Eye
                  onClick={() => {
                    setShowPassword(true);
                  }}
                />
              ) : (
                <EyeClosed
                  onClick={() => {
                    setShowPassword(false);
                  }}
                />
              )
            }
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            name="password"
            type={showPassord ? "text" : "password"}
            placeholder="Create Password"
            errorMessage="Must be 8 characters or longer. Use at least one number or symbol(eg, !@#$%^)"
            helperText="Must be 8 characters or longer. Use at least one number or symbol(eg, !@#$%^)"
            error={passwordError}
            setError={setPasswordError}
          />
          <Text
            icon={<Lock />}
            eye={
              !showConfirmPassword ? (
                <Eye
                  onClick={() => {
                    setShowConfirmPassword(true);
                  }}
                />
              ) : (
                <EyeClosed
                  onClick={() => {
                    setShowConfirmPassword(false);
                  }}
                />
              )
            }
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            password={password}
            value={confirmPassword}
            name="confirmpassword"
            type={showConfirmPassword ? "text" : "password"}
            errorMessage="Passwords do not match"
            placeholder="Confirm Password"
            error={confirmPasswordError}
            setError={setConfirmPasswordError}
          />
        </div>
        <button disabled={disabled} type="submit" className={classes.button}>
          Change password
        </button>
      </form>
    </div>
  );
};

export default SignUp;
