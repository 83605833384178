import React from "react";
import { useStyles } from "./Styles";
import Topbar from "../Shared/Topbar/Topbar";
import ProfileTop from "./ProfileTop";
import Section from "./Section";
import { Navigate } from "react-router-dom";

const Profile = ({ auth }) => {
  if (!auth) {
    return <Navigate to="/" />;
  }
  const classes = useStyles();
  return (
    <div className={classes.profile}>
      <Topbar />
      <div className={classes.AboutSection}>
        <ProfileTop />
      </div>
      <div className={classes.body}>
        <Section title="Work Experience" />
        <Section title="Achievements" />
        <Section title="Certifications" />
        <Section title="Educational Background" />
      </div>
    </div>
  );
};

export default Profile;
