/*eslint no-unused-vars:*/
import React from "react";
import { useStyles } from "./Styles";
import Final from "../../Final";
import { useNavigate } from "react-router";

const FinalPage = ({ page, setPage }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/login");
  };

  console.log("final page");
  return (
    <div className={classes.final}>
      <Final
        title="Password reset succesfull"
        buttonText="Back to login"
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default FinalPage;
