/*eslint no-unused-vars:*/
import React, { useState } from "react";
import Topbar from "../../Shared/Topbar/Topbar";
import Left from "../../../Assets/images/RecruiterOnboard/leftillustration.png";
import Right from "../../../Assets/images/RecruiterOnboard/rightIllustration.png";
import { useStyles } from "./Styles";
import VerifyOtp from "./VerifyOtp/index";
import Recover from "./Recover/index";
import Reset from "./Reset";
import { useNavigate } from "react-router-dom";
import FinalPage from "./FinalPage";

const index = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [values, setValues] = useState({
    phonenumber: "",
    password: "",
    confirmpassword: "",
  });
  const [formTitles, setFormTitles] = useState([
    "Recover password",
    "Recover password",
    "Reset password",
  ]);

  const navigate = useNavigate();
  const PageRender = () => {
    if (page === 0) {
      return (
        <Recover
          page={page}
          setPage={setPage}
          values={values}
          setValues={setValues}
        />
      );
    } else if (page === 1) {
      return (
        <VerifyOtp
          page={page}
          setPage={setPage}
          phonenumber={values.phonenumber}
        />
      );
    } else if (page === 2) {
      return (
        <Reset
          page={page}
          setPage={setPage}
          values={values}
          setValues={setValues}
        />
      );
    } else {
      return (
        <FinalPage
          setPage={setPage}
          page={page}
          values={values}
          setValues={setValues}
        />
      );
    }
  };
  return (
    <div className={classes.studentRegister}>
      <Topbar />
      <div className={classes.studentRegisterContainer}>
        <div className={classes.body}>
          <h1 className={classes.title}>{formTitles[page]}</h1>
          {PageRender()}
        </div>
      </div>
      <img src={Left} className={classes.left} />
      <img src={Right} className={classes.right} />
    </div>
  );
};

export default index;
