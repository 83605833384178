import { makeStyles } from "@mui/styles";
import { pxToVw,pxToVh, pxToMvw, pxToMvh} from "../../Utils/pxToRem";
import { theme} from "../../Utils/breakpoints";

export const useStyles = makeStyles({
    backButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: pxToVw(211),
        top:pxToVh(99),
        backgroundColor: "white",
        borderRadius: "50%",
        width: pxToVw(80),
        height: pxToVw(80),
        cursor: "pointer",
        pointerEvents: "all",
        zIndex: 11,
        WebkitTapHighlightColor:"transparent",
        "& svg": {
            width: pxToVw(21.67),
            height: pxToVw(15.83),
            [theme.breakpoints.down("sm")]: {
            width:pxToMvw(21.67),
            height: pxToMvw(15.83),
        }
        },
        [theme.breakpoints.down("sm")]: {
            width:pxToMvw(51),
            height: pxToMvw(51),
            position: "fixed",
            borderRadius: "50%",
            border: "1px solid rgba(14, 201, 158, 0.2)",
            top: pxToMvh(14),
            left:pxToMvw(24)
        }
    }
});
