import { makeStyles } from "@mui/styles";
import { pxToRem , pxToVh, pxToVw , pxToMvw , pxToMvh} from "../../../../Utils/pxToRem";
import { theme} from "../../../../Utils/breakpoints";

export const useStyles = makeStyles(() => ({
    heading: {
            fontSize: pxToRem(48),
            background: "-webkit-linear-gradient(#00634C, #009270)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily:"Poppins,sans-serif",
            fontWeight: 600,
            letterSpacing: "-1.5px",
            margin: 0,
            lineHeight:pxToVh(86),
            textAlign: "center",
            marginTop: pxToVh(32),
        marginBottom: pxToVh(36),
        [theme.breakpoints.down("sm")]: {
               margin:"unset",
                fontSize: pxToMvw(24),
                marginTop: pxToMvh(38),
        }
    },
    inputs: {
        display: "flex",
        flexDirection: "column",
        alignItems:"center",
        gap: pxToVh(24),
        marginBottom: pxToVh(32),
        
    },
    submit: {
        width: pxToVw(131),
        height: pxToVw(80),
        borderRadius: pxToRem(40),
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: pxToRem(18),
        marginBottom: pxToVh(102),

        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(131),
            height: pxToMvw(60),
            borderRadius: pxToMvw(40),
            fontSize:pxToMvw(14)
        }
        // cursor:"pointer"W
    },
    errorText: {
        color: "#F44336",
        fontSize: pxToRem(16),
        textAlign: "center",
        marginBottom: pxToVw(32),
        fontWeight:500,
        
        [theme.breakpoints.down("sm")]: {
            fontSize:pxToMvw(14)
        }
    },
    disabledSubmit: {
        background: "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), #0EC99E",
        border: "none",
        cursor: "no-drop",
       
    },
    enabledSubmit: {
        background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
        border:"2px solid #0EC99E",
        cursor: "pointer",
        
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        gap: pxToVh(24),
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            gap:0
        }
    },
    skip: {
        fontWeight: 500,
        background: "linear-gradient(rgba(1, 110, 85, 1),rgba(1, 143, 110, 1))",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor:"transparent",
        marginBottom: pxToVh(102),
        border: "none",
        cursor: "pointer",
        fontFamily:"Poppins,sans-serif",
        fontSize: pxToRem(18),
        [theme.breakpoints.down("sm")]: {
            height: pxToMvw(48),
            fontSize:pxToMvw(14)
        }
    },
    container: {
        width: pxToVw(620),
        marginTop:pxToVh(198 + 71),
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginTop: pxToMvh(132),
            position: "relative",
            zIndex:5
        }
    },
    checkLabel: {
        display: "flex",
        gap: pxToVw(17),
        [theme.breakpoints.down("sm")]: {
            gap:pxToMvw(15)
        }
    },
    checkboxContainer: {
        display: "flex",
        gap: pxToVw(17),
        marginBottom: pxToVh(32),
        "& label": {
            color: "#9D9D9D",
            fontSize: pxToRem(16),
            fontWeight: 400,
            lineHeight: pxToRem(24),
            [theme.breakpoints.down("sm")]: {
                fontSize: pxToMvw(12),
                lineHeight:pxToMvw(16)
        }
        },
        [theme.breakpoints.down("sm")]: {
            padding: `0 ${pxToMvw(27)}`,
            gap:pxToMvw(30)
        }
       
    }
}))