import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "./Styles";
import image1 from "../../../Assets/images/LandingPage/Section/hand.png";
import image2 from "../../../Assets/images/LandingPage/Section/finger.png";
import image3 from "../../../Assets/images/LandingPage/Section/card.png";
import SectionText from "./SectionText";
import MobileSectionText from "./MobileSectionText";
import gsap from "gsap";

const Section = () => {
  const classes = useStyles();
  const imageRef = useRef(null);
  const scrolldata = [
    {
      img: image1,
      title:
        "We take care of your CAREER MATTERS seriously so that you never have to",
      subtitle:
        "Never take a career decision based on what someone else said. Be clear about what you want to be in life and how to be there with the help of a learning path, mentors who have been there and done that. ",
      list: [
        "Explore different career opportunities with help of career reels",
        "Get guidance with help of mentors and learning pathss",
        "Build real world industry projects with guidance ",
      ],
      buttontext: "Explore Opportunities",
    },
    {
      img: image2,
      title: "Resumes are old school",
      subtitle:
        "Build Glofolios with proof of work with the help of the community and get noticed by top recruiters and hiring managers to get your dream job.",
      list: [
        "Showcase your proof of work to community of mentors, peers, recruiters and hiring managers and get their suggestions ",
        "Get noticed by companies and get scouted by companies before placement seasons",
      ],
      buttontext: "Explore Opportunities",
    },
    {
      img: image3,
      title:
        "Experience the joy of learning and creating with community and mentors",
      subtitle:
        "Knowing a concept is important but CREATING things that leave an impact is GOAT",
      list: [
        "Build industry level projects by collaborating with peers",
        "Get feedback and suggestions from top professionals",
      ],
      buttontext: "Explore Opportunities",
    },
  ];
  const [currentImg, setCurrentImg] = useState(0);
  const tl = gsap.timeline();
  useEffect(() => {
    tl.from(imageRef.current, { opacity: 0, duration: 1 }).to(
      imageRef.current,
      {
        opacity: 1,
        duration: 1,
      }
    );
  }, [currentImg]);
  return (
    <div className={classes.section}>
      <div className={classes.imageWrapper} ref={imageRef}>
        <img
          src={scrolldata[currentImg].img}
          className={classes.image}
          key={scrolldata[currentImg].img}
        />
      </div>
      <div className={classes.detailsWrapper}>
        {scrolldata.map((item, id) => (
          <div key={id} className={classes.scrollFullScreen}>
            <SectionText
              key={id}
              item={item}
              id={id}
              setCurrentImg={setCurrentImg}
            />
          </div>
        ))}
      </div>
      <div className={classes.mobileWrapper}>
        {scrolldata.map((item, id) => (
          <MobileSectionText key={id} item={item} id={id} />
        ))}
      </div>
    </div>
  );
};

export default Section;
