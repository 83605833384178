/* eslint-disable no-unused-vars */
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Utils/breakpoints";
import { pxToMvw, pxToRem, pxToVh, pxToVw } from "../../../../Utils/pxToRem";
export const useStyles = makeStyles({
  verifyOtp: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: pxToVh(32),
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "500",
    fontSize: pxToVw(18),
    lineHeight: pxToVw(32),
    color: "#9D9D9D",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(10),
      marginTop: pxToMvw(20),
    },
  },
  otpContainer: {
    display: "flex",
    gap: pxToVw(20),
  },
  otpInput: {
    width: pxToVw(80),
    height: pxToVw(80),
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "500",
    fontSize: pxToVw(30),
    textAlign: "center",
    color: "#9BA09F",
    border: (props) => (props.error ? "2px solid red" : "2px solid #E9E9E9"),
    borderRadius: pxToVw(29),
    outline: "none",
    "&::placeholder": {
      color: "#E9E9E9",
      fontFamily: "Poppins,sans-serif",
      fontSize: pxToVw(30),
      fontWeight: 400,
      letterSpacing: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(40),
      height: pxToMvw(40),
      fontSize: pxToMvw(18),
      borderRadius: pxToMvw(16),
      "&::placeholder": {
        fontSize: pxToMvw(18),
      },
    },
  },
  otpButton: {
    width: pxToVw(282),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    border: (props) => (props.disable ? "none" : "2px solid #0EC99E"),
    background: (props) =>
      props.disable
        ? "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), linear-gradient(0deg, #0EC99E, #0EC99E)"
        : "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%),linear-gradient(0deg, #0EC99E, #0EC99E)",
    color: "white",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    cursor: (props) => (props.disable ? "not-allowed" : "pointer"),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(176),
      height: pxToMvw(48),
      fontSize: pxToMvw(12),
      borderRadius: pxToMvw(40),
    },
  },
  timer: {
    color: "#018F6E",
    fontWeight: 500,
    fontSize: pxToVw(18),
    marginLeft: pxToVw(5),
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(10),
    },
  },
  backButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#0EC99E",
    width: pxToVw(160),
    height: pxToVw(80),
    borderRadius: pxToRem(40),
    backgroundColor: "rgba(227, 255, 249, 1)",
    border: "2px solid rgba(14, 201, 158, 1)",
    fontSize: pxToRem(18),
    fontFamily: "Poppins,sans-serif",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(106),
      height: pxToMvw(48),
      fontSize: pxToMvw(12),
      borderRadius: pxToMvw(40),
    },
  },
  verifyEmail: {
    width: pxToVw(336),
    height: pxToVw(80),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(229),
      height: pxToMvw(48),
    },
  },
  error: {
    color: "red",
  },
});
