/*eslint no-unused-vars:*/
import React, { useState } from "react";
import { useEffect } from "react";
import { useStyles } from "./Styles";
import { getStudentDomains } from "../../../../Services/lib/StudentOnboarding";
import { ReactComponent as Cross } from "../../../../Assets/images/StudentOnboard/Form/cross.svg";

const Interests = ({
  formPage,
  setFormPage,
  multiForm,
  setMultiForm,
  domains,
  setDomains,
  selected,
  setSelected,
  subDomain,
  setSubDomain,
}) => {
  const [disabled, setDisabled] = useState(false);
  const classes = useStyles({ disabled });

  useEffect(() => {
    const getData = async () => {
      const res = await getStudentDomains();
      setDomains(res.res.data.data);
      setSelected(res.res.data.data[0].domain);
      let sd = domains.filter((d) =>
        d?.domain === res.res.data.data[0].domain ? true : false
      );
      setSubDomain(sd[0].subDomain);
    };
    getData();
  }, []);

  useEffect(() => {
    if (multiForm.interests.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [multiForm.interests]);

  const handleSelect = (e) => {
    // let newName = e.target.name.split(" ").join("");
    setSelected(e.target.name);
    let sd = domains.filter((d) =>
      d?.domain === e.target.name ? true : false
    );
    setSubDomain(sd[0].subDomain);
  };

  const handleSubDomainSelect = (e) => {
    let newArray = [...multiForm.interests];
    if (!multiForm.interests.includes(e.target.name)) {
      newArray.push(e.target.name);
      setMultiForm({ ...multiForm, interests: newArray });
    }
  };

  const deleteItems = (id) => {
    const updatedItems = multiForm.interests.filter((ele, idx) => {
      return id !== idx;
    });
    setMultiForm({ ...multiForm, interests: updatedItems });
  };

  return (
    <div className={classes.interests}>
      <h1 className={classes.title}>Select your Interests</h1>
      <div className={classes.container}>
        <p>Domains</p>
        <div className={classes.domainWrapper}>
          {domains.map((data, id) =>
            data.domain === selected ? (
              <button
                key={id}
                name={data.domain}
                className={`${classes.domains} ${classes.selected}`}
                onClick={handleSelect}
              >
                {data.domain}
              </button>
            ) : (
              <button
                key={id}
                name={data.domain}
                className={classes.domains}
                onClick={handleSelect}
              >
                {data.domain}
              </button>
            )
          )}
        </div>
      </div>
      <div className={classes.container}>
        <p>Sub Domains</p>
        <div className={classes.domainWrapper}>
          {subDomain.map((data, id) =>
            multiForm.interests.includes(data.SubDomain) ? (
              <button
                key={id}
                name={data.SubDomain}
                className={`${classes.domains}`}
                onClick={handleSubDomainSelect}
              >
                {data.SubDomain}
              </button>
            ) : (
              <button
                key={id}
                name={data.SubDomain}
                className={`${classes.domains} ${classes.subDomains}`}
                onClick={handleSubDomainSelect}
              >
                {data.SubDomain}
              </button>
            )
          )}
        </div>
      </div>
      <div className={classes.container}>
        {multiForm.interests.length > 0 && <p>Your Selections</p>}
        <div className={classes.domainWrapper}>
          {multiForm.interests.map((data, id) => (
            <div className={classes.selections} key={id}>
              <button
                key={id}
                name={data.selection}
                className={classes.selection}
              >
                {data}
              </button>
              <Cross
                className={classes.cross}
                onClick={() => {
                  deleteItems(id);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.button}>
        <button
          className={classes.back}
          onClick={() => {
            setFormPage(formPage - 1);
          }}
        >
          back
        </button>
        <button
          disabled={disabled}
          className={classes.next}
          onClick={() => {
            setFormPage(formPage + 1);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Interests;
