/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../../../../Services/apiClient";
import { ReactComponent as VerifyEmail } from "../../../../Assets/images/StudentOnboard/Register/Button.svg";
import Loader from "../../../Shared/Loader/index";
import { useStyles } from "./Styles";

const VerifyOtp = ({ page, setPage, phonenumber }) => {
  const navigate = useNavigate();
  const [otpForm, setOtpForm] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });
  const [btnValid, setValid] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [disable, setDisable] = useState(true);

  const inputRef = useRef();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const classes = useStyles({ disable, error });

  useEffect(() => {
    if (
      otpForm.otp1.length !== 0 &&
      otpForm.otp2.length !== 0 &&
      otpForm.otp3.length !== 0 &&
      otpForm.otp4.length !== 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [otpForm]);

  useEffect(() => {
    let Interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds == 0) {
        if (minutes == 0) {
          clearInterval(Interval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(Interval);
    };
  });

  useEffect(() => {
    // console.log(inputRef.current.childNodes);
    var inputElements = inputRef.current.childNodes;
    inputElements.forEach((ele, index) => {
      ele.addEventListener("keydown", (e) => {
        if (e.keyCode === 8 && e.target.value === "") {
          inputElements[Math.max(0, index - 1)].focus();
        }
      });
    });
  }, [inputRef.current]);

  const inputOtp = (e, id, index) => {
    var inputElements = inputRef.current.childNodes;

    const lastInputBox = index === inputElements.length - 1;
    const insertedContent = e.target.value != undefined;

    const re = /^[0-9]/;

    if (!re.test(e.target.value)) {
      e.target.value = "";
    }

    if (!lastInputBox && insertedContent && re.test(e.target.value)) {
      inputElements[index + 1].focus();
    }

    if (e.keyCode === 8 && e.target.value === "") {
      inputElements[Math.max(0, index - 1)].focus();
    }

    setOtpForm((prev) => {
      return {
        ...prev,
        [id]: e.target.value,
      };
    });
  };

  const checkValidity = () => {
    let valid = true;
    for (let i in otpForm) {
      valid = otpForm[i] && valid;
    }
    setValid(valid);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (success) {
        setPage(page + 1);
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [success]);

  const onSubmit = async (e) => {
    e.preventDefault();
    let enteredOtp = otpForm.otp1 + otpForm.otp2 + otpForm.otp3 + otpForm.otp4;
    setLoading(true);
    try {
      const res = await axiosClient.post("/verifyOTPStudentResetPassword", {
        countryCode: "+91",
        PhoneNumber: phonenumber,
        otp: enteredOtp,
      });
      if (res.data.success == true) {
        setLoading(false);
        setSuccess(true);
      } else {
        setLoading(false);
        setError("Invalid code entered. ");
      }
    } catch (e) {
      setLoading(false);
      setSuccess(false);
      setError("Someting went wrong. Please try again later");
    }
  };

  const resendOtp = async () => {
    try {
      const res = await axiosClient.post("/getOTPStudentRegister", {
        countryCode: "+91",
        PhoneNumber: phonenumber,
      });
      console.log(res);
      if (res.data.success) {
        setSuccess(false);
        setLoading(false);
        setError("");
        setOtpForm({
          otp1: "",
          otp2: "",
          otp3: "",
          otp4: "",
        });
        setMinutes(1);
        setSeconds(0);
      } else {
        setError("Exceeded maximum limit. Try again after 1 hour");
      }
    } catch (err) {
      setError("Someting went wrong. Please try again later");
      console.log(err);
    }
  };
  return (
    <form className={classes.verifyOtp} onSubmit={onSubmit}>
      <h3 className={classes.title}>
        We have just sent a verification code to +91 {phonenumber}
      </h3>
      <div className={classes.otpContainer} ref={inputRef}>
        <input
          name="otp1"
          onKeyUp={checkValidity}
          onChange={(e) => inputOtp(e, "otp1", 0)}
          value={otpForm.otp1}
          className={classes.otpInput}
          type=""
          required
          maxLength={1}
          placeholder="#"
        />
        <input
          name="otp2"
          onKeyUp={checkValidity}
          onChange={(e) => inputOtp(e, "otp2", 1)}
          value={otpForm.otp2}
          className={classes.otpInput}
          type=""
          required
          maxLength={1}
          placeholder="#"
        />
        <input
          name="otp3"
          onKeyUp={checkValidity}
          onChange={(e) => inputOtp(e, "otp3", 2)}
          value={otpForm.otp3}
          className={classes.otpInput}
          type=""
          required
          maxLength={1}
          placeholder="#"
        />
        <input
          name="otp4"
          onKeyUp={checkValidity}
          onChange={(e) => inputOtp(e, "otp4", 3)}
          value={otpForm.otp4}
          className={classes.otpInput}
          type=""
          required
          maxLength={1}
          placeholder="#"
        />
      </div>
      {loading && <Loader />}
      {!loading && !success && !error ? (
        <button type="submit" className={classes.otpButton} disabled={disable}>
          Verify Phone Number
        </button>
      ) : null}
      {!error && !loading && !success ? (
        <h3 className={classes.title}>
          Didn’t recieve verification code?
          {minutes === 0 && seconds === 0 ? (
            <span
              style={{ cursor: "pointer" }}
              className={classes.timer}
              onClick={resendOtp}
            >
              Resend
            </span>
          ) : (
            <span className={classes.timer}>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </span>
          )}
        </h3>
      ) : null}
      {error && (
        <h3 className={`${classes.title} ${error && classes.error}`}>
          {error}
          <span
            style={{ cursor: "pointer" }}
            className={classes.timer}
            onClick={resendOtp}
          >
            Resend
          </span>
        </h3>
      )}
      {success && <VerifyEmail className={classes.verifyEmail} />}
      {!success ? (
        <div
          className={classes.backButton}
          onClick={() => {
            setPage(page - 1);
          }}
        >
          Back
        </div>
      ) : null}
    </form>
  );
};

export default VerifyOtp;
