/*eslint no-unused-vars:*/
import React, { useEffect, useState } from "react";
import BasicDetails from "./BasicDetails";
import Interests from "./Interests";
import Education from "./Education";
import FinalPage from "./FinalPage";
import { useStyles } from "./Styles";
import Header from "./Header";
import Topbar from "../../Shared/Topbar/Topbar";
import { getStudentDomains } from "../../../Services/lib/StudentOnboarding";
import moment from "moment";
import { loginSuccess } from "../../../redux/Actions/StudentOnboarding/auth";
import { useDispatch, useSelector } from "react-redux";
import { axiosClient } from "../../../Services/apiClient";
import { Navigate } from "react-router-dom";

const MutltStepForm = ({ auth }) => {
  if (!auth) {
    return <Navigate to="/" />;
  }
  const [multiForm, setMultiForm] = useState({
    profilePhotoUrl: "",
    fullName: "",
    email: "",
    interests: [],
  });
  const [file, setFile] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [aspirations, setAspirations] = useState([]);
  const [skills, setSkills] = useState([]);
  const [degree, setDegree] = useState([]);
  const [institute, setInstitute] = useState([]);
  const [fieldOfStudy, setFieldOfStudy] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [aspirationsError, setAspirationsError] = useState(false);
  const [domains, setDomains] = useState([]);
  const [selected, setSelected] = useState("");
  const [subDomain, setSubDomain] = useState([]);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth?.user?._id);

  useEffect(() => {
    const getData = async () => {
      const res = await getStudentDomains();
      setDomains(res.res.data.data);
    };
    getData();
  }, []);

  const [formPage, setFormPage] = useState(0);
  let sd = moment(startDate).format("YYYY-MM-DD");
  let ed = moment(endDate).format("YYYY-MM-DD");

  const handleFinalFormSubmit = async () => {
    if (file) {
      let data = new FormData();
      data.append("profileImage", file);
      try {
        const response = await axiosClient.post(
          "/saveStudentProfilePictureToS3",
          data
        );
        setMultiForm({ ...multiForm, profilePhotoUrl: response.data.data });
        console.log(response.data.data);
        const res = await axiosClient.post("/SaveStudentDetailedInfo", {
          _id: userId,
          ProfilePicURL: response.data.data,
          StudentName: multiForm.fullName,
          StudentEmail: multiForm.email,
          Aspirations: aspirations.map((data) => data.value),
          Skills: skills.map((data) => data.value),
          Domains: multiForm.interests,
          Degree: degree.value,
          InstituteName: institute.value,
          specialization: fieldOfStudy.value,
          recentEducationStartDate: sd,
          recentEducationEndDate: ed,
        });
        console.log(res);
        if (res.data.success) {
          const userData = JSON.parse(window.atob(res.data.data));
          console.log(res);
          console.log(userData);
          dispatch(
            loginSuccess({
              user: userData,
            })
          );
          setFormPage(formPage + 1);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const res = await axiosClient.post("/SaveStudentDetailedInfo", {
          _id: userId,
          StudentName: multiForm.fullName,
          StudentEmail: multiForm.email,
          Aspirations: aspirations.map((data) => data.value),
          Skills: skills.map((data) => data.value),
          Domains: multiForm.interests,
          Degree: degree.value,
          InstituteName: institute.value,
          specialization: fieldOfStudy.value,
          recentEducationStartDate: sd,
          recentEducationEndDate: ed,
        });
        if (res.data.success) {
          const userData = JSON.parse(window.atob(res.data.data));
          console.log(res);
          console.log(userData);
          dispatch(
            loginSuccess({
              user: userData,
            })
          );
          setFormPage(formPage + 1);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const PageRender = () => {
    if (formPage === 0) {
      return (
        <BasicDetails
          multiForm={multiForm}
          setMultiForm={setMultiForm}
          file={file}
          setFile={setFile}
          aspirations={aspirations}
          setAspirations={setAspirations}
          aspirationsError={aspirationsError}
          setAspirationsError={setAspirationsError}
          skills={skills}
          setSkills={setSkills}
          nameError={nameError}
          setNameError={setNameError}
          emailError={emailError}
          setEmailError={setEmailError}
          formPage={formPage}
          setFormPage={setFormPage}
        />
      );
    } else if (formPage === 1) {
      return (
        <Interests
          formPage={formPage}
          setFormPage={setFormPage}
          multiForm={multiForm}
          setMultiForm={setMultiForm}
          domains={domains}
          setDomains={setDomains}
          subDomain={subDomain}
          setSubDomain={setSubDomain}
          selected={selected}
          setSelected={setSelected}
        />
      );
    } else if (formPage === 2) {
      return (
        <Education
          formPage={formPage}
          setFormPage={setFormPage}
          degree={degree}
          setDegree={setDegree}
          institute={institute}
          setInstitute={setInstitute}
          fieldOfStudy={fieldOfStudy}
          setFieldOfStudy={setFieldOfStudy}
          checked={checked}
          setChecked={setChecked}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handlesubmit={handleFinalFormSubmit}
        />
      );
    } else if (formPage === 3) {
      return <FinalPage setFormPage={setFormPage} />;
    }
  };

  const classes = useStyles();
  return (
    <div>
      <Topbar />
      <div className={classes.multiStepForm}>
        <Header page={formPage} setPage={setFormPage} />
        <div className={classes.body}>{PageRender()}</div>
      </div>
    </div>
  );
};

export default MutltStepForm;
