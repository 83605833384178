import React from "react";
import { useStyles } from "./Styles";
import { Carousel } from "react-responsive-carousel";
import { ReactComponent as LeftArrow } from "../../../Assets/images/ExploreCareerPage/ExploreCareerCard/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../Assets/images/ExploreCareerPage/ExploreCareerCard/rightArrow.svg";

const ExploreCarousal = ({ children, width = 525 }) => {
  // accepts a arguement width
  // i.e the width of middle containter
  // width total = width + 45*2 ( 45 of controllers svg )

  const classes = useStyles({ width });

  return (
    <div className={classes.cardCarousalContainer}>
      <Carousel
        className={classes.cardCarousal}
        showArrows={true}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        renderArrowPrev={(onClickHandler, hasPrev) => {
          if (hasPrev) {
            return (
              <div
                className={classes.Carousalcontroller}
                onClick={onClickHandler}
              >
                <LeftArrow className={classes.CarousalcontrollerSVG} />
              </div>
            );
          }
        }}
        renderArrowNext={(onClickHandler, hasNext) => {
          if (hasNext) {
            return (
              <div
                className={classes.Carousalcontroller}
                onClick={onClickHandler}
              >
                <RightArrow className={classes.CarousalcontrollerSVG} />
              </div>
            );
          }
        }}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default ExploreCarousal;
