import React from "react";
import CoverPhotoSection from "./CoverPhotoSection/CoverPhotoSection";
import ProfileDetails from "./ProfileDetails/ProfileDetails";
import AboutSection from "./AboutSection/AboutSection";
import { useStyles } from "./Styles";

const ProfileTop = () => {
  const classes = useStyles();
  return (
    <div className={classes.ProfileTop}>
      <div className={classes.ProfileTopCoverWrap}>
        <CoverPhotoSection />
      </div>
      <div className={classes.ProfileTopProfileWrap}>
        <ProfileDetails />
      </div>

      <div className={classes.ProfileTopAboutSectioneWrap}>
        <AboutSection />
      </div>
    </div>
  );
};

export default ProfileTop;
