/*eslint no-unused-vars:*/
import { makeStyles } from "@mui/styles";
import { theme } from "../../../Utils/breakpoints";
import { pxToMvw, pxToVh, pxToVw } from "../../../Utils/pxToRem";
export const useStyles = makeStyles({
  multiStepForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: pxToVh(50),
    width: "100vw",
    height: "92vh",
    backgroundColor: "#F6F6F6",
    paddingTop: pxToVh(36),
    // overflow: "scroll",
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(48),
    lineHeight: pxToVw(86),
    background: "linear-gradient(89.61deg, #00634C -19%, #009270 92.23%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(28),
      lineHeight: pxToMvw(32),
    },
  },
  body: {
    width: "100%",
    overflow: "scroll",
    paddingRight: pxToVw(20),
    paddingLeft: pxToVw(20),
    // "&::-webkit-scrollbar": {
    //   display: "none",
    // },
  },
  back: {
    width: pxToVw(140),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    backgroundColor: "rgba(227, 255, 249, 1)",
    border: "2px solid rgba(14, 201, 158, 1)",
    fontSize: pxToVw(18),
    fontFamily: "Poppins,sans-serif",
    cursor: "pointer",
  },
  next: {
    width: pxToVw(140),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    border: (props) => (props.disabled ? "none" : "2px solid #0EC99E"),
    background: (props) =>
      props.disabled
        ? "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), linear-gradient(0deg, #0EC99E, #0EC99E)"
        : "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%),linear-gradient(0deg, #0EC99E, #0EC99E)",
    color: "white",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    cursor: (props) => (props.disabled ? "not-allowed" : "pointer"),
  },
});
