import { makeStyles } from "@mui/styles";
import { pxToVw, pxToVh, pxToRem, pxToMvw } from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";

export const useStyles = makeStyles({
  shareModalWrapper: {
    position: "absolute",
    zIndex: 11,
    top: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  shareModal: {
    display: "flex",
    flexDirection: "column",
    width: pxToRem(683),
    height: pxToRem(501),
    background: "#FFFFFF",
    borderRadius: pxToRem(24),
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,.2)",
    border: "2px solid #0EC99E",
    padding: pxToRem(25) + " " + pxToRem(30),
  },
  shareModalHeader: {
    width: pxToRem(585),
    height: pxToRem(91),
    textAlign: "center",
    fontSize: pxToRem(36),
    fontWeight: "500",
    background: "linear-gradient(89.61deg, #8DD2C2 -19%, #009270 92.23%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  shareModalBody: {
    padding: pxToRem(30),
  },
  shareLinkGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  shareLink: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  shareLinkIcon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    width: pxToRem(63),
    height: pxToRem(63),
    border: "2px solid #ECECEC",
    borderRadius: "100%",
  },
  shareLinkText: {
    marginTop: pxToRem(12),
    fontSize: pxToRem(18),
    fontWeight: "400",
    color: "#9D9D9D",
  },
  sharePageLink: {
    display: "flex",
    flexDirection: "column",
    marginTop: pxToRem(50),
  },
  pageLink: {
    fontWeight: "400",
    fontSize: pxToRem(18),
    color: "#212433",
    marginBottom: pxToRem(18),
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    background: "#F7F7F7",
    borderRadius: pxToRem(8),
    padding: pxToRem(8) + " " + pxToRem(16),
  },
  link: {
    fontWeight: "400",
    fontSize: pxToRem(18),
    color: "#9D9D9D",
  },
  closeIcon: {
    width: pxToVw(43),
    height: pxToVw(43),
    border: "none",
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(14, 201, 158, 0.2)",
    borderRadius: "100%",
    cursor: "pointer",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(30),
      height: pxToMvw(30),
    },
  },
  close: {
    width: pxToVw(20),
    height: pxToVw(20),
    color: "#0EC99E",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(10),
      height: pxToMvw(10),
    },
  },
});
