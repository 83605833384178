import Chip from "../Chip/Chip";
import ProfilePic from "../../../../Assets/images/ProfilePage/ProfileTop/Profile/profileImage.png";
import MetricsCard from "../MetricsCard/MetricsCard";
import { pxToVh, pxToVw } from "../../../../Utils/pxToRem";
import {
  AiFillLinkedin,
  AiFillDribbbleCircle,
  AiFillBehanceSquare,
  AiFillInstagram,
  AiFillFacebook,
  AiOutlineGitlab,
} from "react-icons/ai";
import { FaUniversity } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { useStyles } from "./Styles";

const ProfileDetails = () => {
  const classes = useStyles();
  return (
    <div className={classes.Profile}>
      <main className={classes.Profile__User}>
        <div className={classes.Profile__Image_Wrapper}>
          <img
            src={ProfilePic}
            alt=""
            className={classes.Profile__User_image}
          />
          <div className={classes.Profile__User_actions}>
            <button
              className={`${classes.Profile__User_actions_button} ${classes.Profile__User_actions_follow}`}
            >
              Follow
            </button>
            <button
              className={`${classes.Profile__User_actions_button} ${classes.Profile__User_actions_message}`}
            >
              Message
            </button>
          </div>
        </div>
        <div className={classes.Profile__User_info}>
          <h2 className={classes.Profile__User_Info__name}>Kevin Pietersen</h2>
          <p className={classes.Profile__User_Info__oneLiner}>
            User Experience Designer at Google | CSE KiiT 23 | HCI/UX | Design |
            XR (AR/VR/MR) enthusiast
          </p>
          <p className={classes.Profile__User_Info__desc_aspire}>
            Aspire to be{" "}
            <div className={classes.Profile__User_Info__chip}>
              <Chip val="UX Designer" /> <Chip val="UX Researcher" />{" "}
              <Chip val="UI Designer" />
            </div>
          </p>

          <p className={classes.Profile__User_Info__desc_skills}>
            Top Skills{" "}
            <div className={classes.Profile__User_Info__chip}>
              <Chip skills val="User research" />{" "}
              <Chip skills val="Prototyping" />{" "}
              <Chip skills val="Wireframing" />
              <Chip skills val="Figma" />
            </div>
          </p>

          <div className={classes.Profile__User_Info__univ_loc_wrapper}>
            <p className={classes.Profile__User_Info__desc_university}>
              <FaUniversity style={{ fontSize: pxToVw(17) }} />
              <p className={classes.University}>
                Kalinga Institute of Information Technology
              </p>
            </p>

            <p className={classes.Profile__User_Info__desc_location}>
              <ImLocation style={{ color: "grey", fontSize: pxToVw(17) }} />
              <p className={classes.Location}>Bhubaneswar, Odisha</p>
            </p>
          </div>
          <div className={classes.Profile__Social_Icon__Wrapper}>
            <AiFillLinkedin /> <AiFillDribbbleCircle />
            <AiFillBehanceSquare /> <AiFillFacebook /> <AiFillInstagram />{" "}
            <AiOutlineGitlab />
          </div>
        </div>
        <div className={classes.Profile__User_metrics}>
          <MetricsCard />
          <MetricsCard />
          <MetricsCard />
        </div>
      </main>
    </div>
  );
};

export default ProfileDetails;
