import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Utils/breakpoints";
import {
  pxToVw,
  pxToVh,
  pxToRem,
  pxToMvh,
  pxToMvw,
} from "../../../../Utils/pxToRem";

export const useStyles = makeStyles({
  CareersQuestionsWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
    margin: pxToRem(40) + " 0",
    paddingRight: pxToRem(58),
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: pxToMvh(250),
      "&::-webkit-scrollbar": {
        width: pxToMvw(8),
      },
    },
  },
  CareersQuestions: {
    display: "flex",
    alignItems: "center",
    gap: pxToRem(20),
    padding: pxToRem(12) + " 0",
    paddingLeft: pxToRem(22),
    fontWeight: "400",
    color: "#212433",
    fontSize: pxToRem(18),
    margin: pxToRem(3) + " 0",
    borderRadius: pxToRem(10),
    cursor: "pointer",
  },
  playButton: {
    cursor: "pointer",
    width: pxToVw(28),
    height: pxToVw(28),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(28),
      height: pxToMvw(28),
    },
  },
  active: {
    background: "rgba(14, 201, 158, 0.15)",
  },
  Questions: {
    fontSize: pxToVw(16),
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    },
  },
});
