/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { ReactComponent as Envelope } from "../../../../Assets/images/RecruiterOnboard/Envelope.svg";
import { ReactComponent as Lock } from "../../../../Assets/images/RecruiterOnboard/Lock.svg";
import { ReactComponent as Pen } from "../../../../Assets/images/RecruiterOnboard/pen.svg";
import { ReactComponent as CompanyUrl } from "../../../../Assets/images/RecruiterOnboard/Company_Page/company_url.svg";
import { ReactComponent as CompanyType } from "../../../../Assets/images/RecruiterOnboard/Company_Page/company_type.svg";
import { ReactComponent as CompanySize } from "../../../../Assets/images/RecruiterOnboard/Company_Page/company_size.svg";
import { ReactComponent as Tagline } from "../../../../Assets/images/RecruiterOnboard/Company_Page/tagline.svg";
import { ReactComponent as TextAreaIcon } from "../../../../Assets/images/RecruiterOnboard/Company_Page/TextArea.svg";
import { ReactComponent as Industry } from "../../../../Assets/images/RecruiterOnboard/Company_Page/industry.svg";
import Checkbox from "../../../Shared/input/checkbox";
import InputText from "../../../Shared/input/text";
import Dropdown from "../../../Shared/input/dropdown";
import {
  addCompanyApi,
  saveCompanyPage,
} from "../../../../Services/lib/RecruiterOnboarding";
import TextArea from "../../../Shared/input/textarea";
import * as types from "../../../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import TextAreaAuto from "../../../Shared/input/textAreaAuto";

// import Password from "../../../Shared/input/password";

const CompanyPage = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const persistFormData = useSelector(
    (state) => state.RecruiterOnboard.companyPage
  );
  const CompanyTypes = useSelector(
    (state) => state.RecruiterOnboard.companyTypes
  );
  const CompanySizes = useSelector(
    (state) => state.RecruiterOnboard.companySize
  );
  const CompanyNames = useSelector(
    (state) => state.RecruiterOnboard.companyNames
  );
  const RecruiterId = useSelector(
    (state) => state.RecruiterOnboard.recruiterId
  );
  const Industries = useSelector((state) => state.RecruiterOnboard.industries);
  const General = useSelector((state) => state.RecruiterOnboard.generalInfo);

  const [formError, setFormError] = useState({
    errorState: false,
    errorNote: null,
  });

  const [generalForm, setGeneralForm] = useState({
    CompanyName: {
      label: "Name",
      elementType: "asyncdropdown",
      data: [],
      editable: true,
      placeholder: "Enter your Company Name",
      icon: <Pen />,
      value: "",
      validation: {
        required: true,
        isName: true,
      },
      errorNote: "Name can only contain letters",
      valid: false,
      touched: false,
    },
    CompanyURL: {
      label: "url",
      elementType: "text",
      editable: true,
      placeholder: "Company URL (if exists)",
      icon: <Envelope />,
      value: "",
      validation: {
        required: true,
      },
      errorNote: "Enter a valid email id",
      valid: true,
      touched: false,
    },
    Industry: {
      label: "industry",
      elementType: "asyncdropdown",
      data: [],
      editable: true,
      placeholder: "Your Industry",
      icon: <Lock />,
      value: "",
      validation: {
        required: true,
      },
      errorNote: null,
      valid: false,
      touched: false,
    },
    CompanyType: {
      label: "type",
      elementType: "asyncdropdown",
      data: [],
      editable: true,
      placeholder: "Your Company Type",
      icon: <Lock />,
      value: "",
      validation: {
        required: true,
      },
      errorNote: null,
      valid: false,
      touched: false,
    },
    CompanySize: {
      label: "size",
      elementType: "dropdown",
      editable: true,
      data: [],
      placeholder: "Your Company Size",
      icon: <Lock />,
      value: "",
      validation: {
        required: true,
      },
      errorNote: null,
      valid: false,
      touched: false,
    },
    Tagline: {
      label: "companyName",
      elementType: "textarea",
      editable: true,
      placeholder: "Tag Line ( Optional )",
      icon: <Pen />,
      value: "",
      validation: {
        required: true,
      },
      errorNote: null,
      valid: true,
      touched: false,
    },
    AboutCompany: {
      label: "about",
      elementType: "textarea",
      editable: true,
      placeholder: "About the Company",
      icon: <Pen />,
      value: "",
      validation: {
        required: true,
      },
      errorNote: null,
      valid: false,
      touched: false,
    },
  });

  const [formValid, setFormValid] = useState(false);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    if (persistFormData) {
      for (let identifier in generalForm) {
        const updatedElement = {
          ...generalForm[identifier],
        };
        updatedElement.value = persistFormData[identifier];
        updatedElement.valid = true;
        updatedElement.touched = true;
        updatedElement.editable = false;

        setGeneralForm((prev) => {
          prev[identifier] = updatedElement;
          return {
            ...prev,
          };
        });
      }

      setAgree(persistFormData.IsVerified);
      setFormValid(true);
    } else {
      dispatch({ type: types.SET_COMPANY_TYPE_REQUESTED });
    dispatch({ type: types.SET_COMPANY_SIZE_REQUESTED });
    dispatch({ type: types.GET_COMPANY_REQUESTED });
    dispatch({ type: types.GET_INDUSTRY_REQUESTED });
      setGeneralForm((prev) => {
        prev["CompanyName"].value = General.companyName;
        prev["CompanyName"].valid = true;
        prev["CompanyName"].touched = true;

        return {
          ...prev,
        };
      });
    }
    
  }, []);

  useEffect(() => {
    const updatedElement = {
      ...generalForm["CompanySize"],
    };
    updatedElement.data = CompanySizes;
    setGeneralForm((prev) => {
      prev["CompanySize"] = updatedElement;
      return {
        ...prev,
      };
    });
  }, [CompanySizes]);

  useEffect(() => {
    const updatedElement = {
      ...generalForm["Industry"],
    };

    updatedElement.data = Industries;
    setGeneralForm((prev) => {
      prev["Industry"] = updatedElement;
      return {
        ...prev,
      };
    });
  }, [Industries]);

  useEffect(() => {
    const updatedElement = {
      ...generalForm["CompanyType"],
    };

    updatedElement.data = CompanyTypes;
    setGeneralForm((prev) => {
      prev["CompanyType"] = updatedElement;
      return {
        ...prev,
      };
    });
  }, [CompanyTypes]);

  useEffect(() => {
    const updatedElement = {
      ...generalForm["CompanyName"],
    };

    updatedElement.data = CompanyNames;
    updatedElement.valid = true;
    setGeneralForm((prev) => {
      prev["CompanyName"] = updatedElement;
      return {
        ...prev,
      };
    });
  }, [CompanyNames]);

  const checkValidity = (value, rules) => {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    if (rules.notRequired) {
      isValid = true;
    }

    if (rules.isName) {
      let pattern = /^[a-zA-Z]*$/;
      isValid = pattern.test(value) && isValid;
    }
    if (rules.isEmail) {
      let pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = pattern.test(value) && isValid;
    }
    if (value.length == 0) {
      return true;
    }

    return isValid;
  };

  const inputChangedHandler = (e, id) => {
    const updatedElement = {
      ...generalForm[id],
    };

   
    updatedElement.value = e.target.value;

    updatedElement.valid = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    setFormError((prev) => ({
      ...prev,
      errorNote: null,
      errorState: false,
    }));

    updatedElement.touched = true;
    //  if (id == "CompanyURL") {
    //   generalForm.CompanyURL.touched = true;
    //   generalForm.CompanyURL.valid = true;
    // }

    if (updatedElement.value == "") {
      updatedElement.touched = false;
      updatedElement.valid = false;
    }
    setGeneralForm((prev) => {
      prev[id] = updatedElement;

      let FormValid = true;

      for (let inputIdentifier in generalForm) {
        if (inputIdentifier === "CompanyURL" || inputIdentifier === "Tagline") {
          continue;
        }
        
        FormValid = generalForm[inputIdentifier]?.valid && FormValid && agree;
      }
      setFormValid(FormValid);
      return {
        ...prev,
      };
    });
  };

  let formElement = [];

  for (let key in generalForm) {
    formElement.push({
      id: key,
      config: generalForm[key],
    });
  }

  const Next = () => {
    if (persistFormData) {
      props.setStep(props.step + 1);
      props.setCompleted((step) => [...step, props.step]);
      props.setCurrent(props.step + 1);
      return;
    }

    const formData = {
      CompanyName: generalForm.CompanyName.value,
      CompanyURL: generalForm.CompanyURL.value,
      Industry: generalForm.Industry.value,
      CompanyType: generalForm.CompanyType.value,
      CompanySize: generalForm.CompanySize.value,
      Tagline: generalForm.Tagline.value,
      AboutCompany: generalForm.AboutCompany.value,
      IsVerified: agree,
      RecruiterIDs: RecruiterId,
    };

    saveCompanyPage(formData)
      .then((res) => {
        if (res.res.data.success) {
          dispatch({
            type: types.SET_COMPANY_SUCCESS,
            companyPage: formData,
            companyDetailsId: res.res.data?.user.companyDetailsId,
          });

          props.setStep(props.step + 1);
          props.setCompleted((step) => [...step, props.step]);
          props.setCurrent(props.step + 1);
        } else {
          dispatch({ type: types.SET_COMPANY_FAIL });
        }
      })
      .catch((err) => {
        setFormError((prev) => ({
          ...prev,
          errorNote: "Temporarily Out Of Service",
          errorState: true,
        }));
        dispatch({ type: types.SET_COMPANY_FAIL });
      });
  };

  const Skip = () => {
    props.setStep(props.step + 1);
    props.setCompleted((step) => [...step, props.step]);
    props.setCurrent(props.step + 1);
  };

  const agreeHandler = (e) => {
    setAgree(e.target.checked);
    let FormValid = true;
    for (let inputIdentifier in generalForm) {
      FormValid =
        generalForm[inputIdentifier]?.valid && FormValid && e.target.checked;
    }
    setFormValid(FormValid);
  };

  const selectHandler = (e, id) => {
  
    setGeneralForm((prev) => {
      const updatedElement = {
        ...generalForm[id],
      };
      updatedElement.value = e.target.value;
      return {
        ...prev,
      };
    });
  };

  const setDropdownValue = (value) => {
   
    const updatedElement = {
      ...generalForm["CompanyName"],
    };

    updatedElement.value = value;

    setGeneralForm((prev) => {
      prev["CompanyName"] = updatedElement;
      return {
        ...prev,
      };
    });
  };

  return (
    <div className={styles.container}>
      {/* <h1 className={styles.heading}>Create Company Page</h1> */}
      <div className={styles.inputs}>
        <InputText
          placeholder="Company Name"
          onchange={(e) => inputChangedHandler(e, "CompanyName")}
          icon={<Pen />}
          editable={generalForm.CompanyName.editable}
          value={generalForm.CompanyName.value}
          valid={generalForm.CompanyName.valid}
          touched={generalForm.CompanyName.touched}
          errorNote={generalForm.CompanyName.errorNote}
          validation={{
            required: true,
          }}
        />

        <InputText
          elementType="text"
          placeholder="Company URL (if exists)"
          onchange={(e) => inputChangedHandler(e, "CompanyURL")}
          icon={<CompanyUrl />}
          editable={generalForm.CompanyURL.editable}
          value={generalForm.CompanyURL.value}
          valid={generalForm.CompanyURL.valid}
          touched={generalForm.CompanyURL.touched}
          errorNote={generalForm.CompanyURL.errorNote}
          validation={{
            required: true,
          }}
        />
        {persistFormData ? (
          <InputText
            elementType="text"
            placeholder="Company URL (if exists)"
            onchange={(e) => inputChangedHandler(e, "Industry")}
            icon={<Industry />}
            editable={false}
            value={persistFormData["Industry"]}
            valid={true}
            touched={true}
            errorNote={generalForm.Industry.errorNote}
            validation={{
              required: true,
            }}
          />
        ) : (
          <Dropdown
            data={generalForm.Industry.data}
            label="industry"
            // setDropdownValue={() => setDropdownValue("type")}
            placeholder="Your Industry"
            onchange={(e) => inputChangedHandler(e, "Industry")}
            onselect={(value) => selectHandler(value, "Industry")}
            editable={generalForm.Industry.editable}
            icon={<Industry />}
            value={generalForm.Industry.value}
            valid={generalForm.Industry.valid}
            touched={generalForm.Industry.touched}
            errorNote={generalForm.Industry.errorNote}
            validation={{
              required: true,
              isEmail: true,
            }}
          />
        )}
        {persistFormData ? (
          <InputText
            elementType="text"
            placeholder="Company URL (if exists)"
            onchange={(e) => inputChangedHandler(e, "CompanyType")}
            icon={<CompanyType />}
            editable={false}
            value={persistFormData["CompanyType"]}
            valid={true}
            touched={true}
            errorNote={generalForm.CompanyType.errorNote}
            validation={{
              required: true,
            }}
          />
        ) : (
          <Dropdown
            data={generalForm.CompanyType.data}
            elementType="text"
            label="companyType"
            // setDropdownValue={() => setDropdownValue("type")}
            placeholder="Your Company Type"
            onchange={(e) => inputChangedHandler(e, "CompanyType")}
            onselect={(value) => selectHandler(value, "CompanyType")}
            editable={generalForm.CompanyType.editable}
            icon={<CompanyType />}
            value={generalForm.CompanyType.value}
            valid={generalForm.CompanyType.valid}
            touched={generalForm.CompanyType.touched}
            errorNote={generalForm.CompanyType.errorNote}
            validation={{
              required: true,
              isEmail: true,
            }}
          />
        )}
        {persistFormData ? (
          <InputText
            elementType="text"
            placeholder="Company URL (if exists)"
            onchange={(e) => inputChangedHandler(e, "CompanyType")}
            icon={<CompanyType />}
            editable={false}
            value={persistFormData["CompanySize"]}
            valid={true}
            touched={true}
            errorNote={generalForm.CompanyType.errorNote}
            validation={{
              required: true,
            }}
          />
        ) : (
          <Dropdown
            data={generalForm.CompanySize.data}
            label="companySize"
            elementType="text"
            placeholder="Your Company Size"
            editable={generalForm.CompanySize.editable}
            onchange={(e) => inputChangedHandler(e, "CompanySize")}
            icon={<CompanySize />}
            value={generalForm.CompanySize.value}
            valid={generalForm.CompanySize.valid}
            touched={generalForm.CompanySize.touched}
            errorNote={generalForm.CompanySize.errorNote}
            validation={{
              required: true,
              isEmail: true,
            }}
          />
        )}
        <TextAreaAuto
          icon={<Tagline />}
          elementType="text"
          placeholder="Tagline ( Optional )"
          onchange={(e) => inputChangedHandler(e, "Tagline")}
          editable={generalForm.Tagline.editable}
          value={generalForm.Tagline.value}
          valid={generalForm.Tagline.valid}
          touched={generalForm.Tagline.touched}
          errorNote={generalForm.Tagline.errorNote}
          note={true}
          limit={120}
          validation={{
            required: true,
            isName: true,
          }}
        />
        <TextArea
          elementType="text"
          placeholder="About the Company"
          onchange={(e) => inputChangedHandler(e, "AboutCompany")}
          icon={<TextAreaIcon />}
          editable={generalForm.AboutCompany.editable}
          note={true}
          limit={2000}
          value={generalForm.AboutCompany.value}
          valid={generalForm.AboutCompany.valid}
          touched={generalForm.AboutCompany.touched}
          errorNote={generalForm.AboutCompany.errorNote}
          validation={{
            required: true,
            isEmail: true,
          }}
        />
       
      </div>
      <div className={styles.checkboxContainer}>
        <label className={styles.checkLabel} htmlFor="check">
          <Checkbox id="check" agree={agree} setAgree={agreeHandler} />I verify
          that I am authorized representative of this organisation and have the
          right to act on its behalf in the creation and management of this
          page.The organisation and I agree to the additional terms for pages.
        </label>
      </div>
      {formError.errorState && <div className={styles.errorText}>
        {formError.errorNote}
      </div>}
      <div className={styles.buttons}>
        <button
          onClick={Next}
          disabled={!formValid}
          className={`${styles.submit} ${
            !formValid ? styles.disabledSubmit : styles.enabledSubmit
          }`}
        >
          Save
        </button>
        {/* <button onClick={Skip} className={styles.skip}>
          skip
        </button> */}
      </div>
    </div>
  );
};

export default CompanyPage;
