import React, { useState } from "react";
import { useStyles } from "./Styles";
import poster1 from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/poster1.png";
import posterMb from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/posterMb.png";
import poster2 from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/poster2.png";
import Company3png from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/company3.png";
import { ReactComponent as Seen } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/seen.svg";
import { useDispatch } from "react-redux";
import { setSelectedReel } from "../../../../redux/Actions/ExploreCareer";
import { openModal } from "../../../../redux/Actions/Modal/modal";
import { pxToMvw } from "../../../../Utils/pxToRem";
const CardCarousalItem = ({
  carousalCardData,
  mobileView = false,
  data,
  isOddAndLast = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(true);

  const handleModalOpen = () => {
    setModalOpen(true);
    dispatch(openModal({ type: "REELSMODAL", open: modalOpen, data: data }));
  };

  // if (mobileView) {
  //   return (
  //     <>
  //       <div className={classes.cardCarousalItemContainerMobile}>
  //         <div
  //           className={classes.cardCarousalItem}
  //           style={{
  //             backgroundImage: `url(${posterMb})`,
  //             backgroundSize: "cover",
  //           }}
  //           onClick={handleModalOpen}
  //         >
  //           <div className={classes.cardCorosalItemOverlay}>
  //             <div className={classes.cardCarousalItemHeader}>
  //               <div className={classes.cardCarousalItemIcon}>
  //                 <Seen className={classes.cardCarousalItemIconSvg} /> &nbsp;
  //                 {carousalCardData?.view_count}
  //               </div>
  //               <div className={classes.cardCarousalItemCompany}>
  //                 <img
  //                   src={Company3png}
  //                   className={classes.cardCarousalItemCompanyImg}
  //                 />
  //               </div>
  //             </div>

  //             <div className={classes.cardCarousalItemFooter}>
  //               <div className={classes.cardCarousalItemName}>
  //                 {carousalCardData?.name}
  //               </div>
  //               <div className={classes.cardCarousalItemPosition}>
  //                 {carousalCardData?.designation}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  return (
    <>
      <div className={classes.cardCarousalItemContainer}>
        <div
          className={classes.cardCarousalItem}
          style={{
            // backgroundImage: `url(${poster1})`,
            backgroundImage:
              "url(https://images.unsplash.com/photo-1524504388940-b1c1722653e1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)",
            backgroundSize: "cover",
          }}
          onClick={handleModalOpen}
        >
          <div className={classes.cardCorosalItemOverlay}>
            <div className={classes.cardCarousalItemHeader}>
              <div className={classes.cardCarousalItemIcon}>
                <Seen className={classes.cardCarousalItemIconSvg} /> &nbsp;{" "}
                {data?.view_count}
              </div>
              <div className={classes.cardCarousalItemCompany}>
                <img
                  src={Company3png}
                  className={classes.cardCarousalItemCompanyImg}
                />
              </div>
            </div>

            <div className={classes.cardCarousalItemFooter}>
              <div className={classes.cardCarousalItemName}>{data?.name}</div>
              <div className={classes.cardCarousalItemPosition}>
                {data?.designation}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardCarousalItem;
