import { makeStyles } from "@mui/styles";
import { pxToVw, pxToVh, pxToRem, pxToMvw } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const themeStyles = makeStyles({
    container: {
        width: pxToVw(620),
        display: "block",
        [theme.breakpoints.down("sm")]: {
            width:"unset"
        }
        
        // height:pxToVh(267)
    },

    containerOpen: {
        display: "block",

    },
    suggestionsContainer: {
        marginTop: pxToVh(8),
        width: pxToVw(620),
        display: "none",
        overflow:"hidden",
        backgroundColor:"red",
        position: "absolute",
        borderRadius: pxToRem(40),
        padding: `${pxToVh(26.5)} 0`,
        border: "2px solid #0EC99E",
        
        
        [theme.breakpoints.down("sm")]: {
            width:pxToMvw(416)
        }
        
        
    },
    
    suggestionsContainerOpen: {
        marginTop: pxToVh(8),
        backgroundColor: "white",
        display:"block",
        padding: `${pxToVh(26.5)} 0`,
        borderRadius: pxToRem(40),
        border: "2px solid #0EC99E",
            maxHeight: pxToVh(267),
        position: "absolute",
        overflow:"hidden",
        
        zIndex: 10,
        boxShadow: "0 10px 20px 0 #0EC99E12",
        [theme.breakpoints.down("sm")]: {
            borderRadius: pxToMvw(29),

        }
      
        
    },
    suggestion: {
        height: pxToVh(57.5),
        listStyle: "none",
        display: "flex",
        justifyContent: "flex-start",
        padding:`0 ${pxToVw(20)}`,
        alignItems: "center",
        // "&:hover": {
        //     backgroundColor:"#0EC99E69"
        // },
        [theme.breakpoints.down("sm")]: {
            fontSize: pxToMvw(12),
            width:"100%"
        }
    },
    input: {
        padding: 0,
        width: "88%",
        height: "100%",
        border: "none",
        outline: "none",
        fontSize:pxToRem(18),
            fontFamily: "Poppins,sans-serif",
        color: "rgba(84, 84, 84, 1)",
        fontWeight:500,
        "&::placeholder": {
            fontFamily: "Poppins,sans-serif",
            color: "#9D9D9D",
            [theme.breakpoints.down("sm")]: {
                fontSize:pxToMvw(12)
            }
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: pxToMvw(12),
        }
    },
    suggestionHighlighted: {
        backgroundColor: (props) => props.bg,
        cursor:"pointer"
    },
});
