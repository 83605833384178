import { makeStyles } from "@mui/styles";
import {
  pxToVw,
  pxToVh,
  pxToRem,
  pxToMvh,
  pxToMvw,
} from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";

export const useStyles = makeStyles({
  cardContent: {
    width: "62%",
    borderRadius: "0.75rem",
    paddingTop: pxToVh(30),
    paddingLeft: pxToVw(30),
    paddingRight: pxToVw(13),
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      paddingTop: pxToMvh(16),
      paddingLeft: pxToMvw(16),
      paddingRight: pxToMvw(16),
    },
  },

  cardTitleWrapper: {
    display: "flex",
  },
  cardTitleBox: {
    width: "50%",
    display: "flex",
  },
  cardTitleImg: {
    height: pxToVw(50),
    width: pxToVw(50),
    marginRight: pxToVw(16),
    [theme.breakpoints.down("mb")]: {
      height: pxToMvw(36),
      width: pxToMvw(36),
      marginRight: pxToVw(30),
    },
  },
  cardTitle: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    paddingTop: pxToVh(5),
    display: "flex",
    fontSize: pxToVw(24),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(16),
      marginTop: pxToMvh(2),
    },
  },
  cardAvatar: {
    borderRadius: "100%",
    height: "100%",
    width: "100%",
  },
  cardButtons: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  cardButton: {
    borderRadius: pxToRem(12),
    border: "none",
    outline: "none",
    background: "#0EC99E",
    padding: pxToVh(10) + " " + pxToVw(24),
    color: "white",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
    fontSize: pxToVw(16),
    marginRight: pxToVw(25),
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(14),
      padding: pxToMvh(5) + " " + pxToMvw(16),
    },
  },
  cardMiddleContainer: {
    display: "flex",
    // paddingRight: pxToVw(35),
    paddingTop: pxToVh(35),
    paddingBottom: pxToVh(47),
    [theme.breakpoints.down("mb")]: {
      paddingTop: pxToMvh(16),
      paddingBottom: pxToMvh(0),
      flexDirection: "column",
    },
  },
  cardDescription: {
    display: "flex",
    width: "60%",
    paddingRight: pxToVw(15),
    wordSpacing: pxToVw(10),
    fontSize: pxToVw(14),
    fontWeight: "400",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      fontSize: pxToMvw(14),
      borderRight: "none",
    },
  },
  cardDescriptionShowMore: {
    color: "#0EC99E",
    fontWeight: "400",
    wordSpacing: "normal",
  },
  cardOtherDetails: {
    display: "flex",
    width: "40%",
    paddingTop: pxToVw(6),
    paddingBottom: pxToVw(6),
    marginLeft: pxToVw(30),
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      justifyContent: "normal",
      paddingBottom: pxToVh(10),
      paddingTop: pxToVh(10),
    },
  },
  cardSalaryContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: pxToRem(65),
    backgroundColor: "#F8F8F8",
    padding: pxToVw(10),
    // marginLeft: pxToVw(30),
    marginRight: pxToVw(15),
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      marginLeft: pxToMvw(0),
      marginRight: pxToMvw(25),
      padding: pxToMvw(7),
    },
  },
  cardOtherDetailsContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: pxToRem(65),
    backgroundColor: "#F8F8F8",
    padding: pxToVw(10),
    paddingRight: pxToVw(20),
    textAlign: "left",
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      padding: pxToMvw(6),
      paddingRight: pxToMvw(20),
    },
  },
  cardCompanyDiv: {
    display: "flex",
    "&::hover": {
      zIndex: "1",
    },
  },
  cardCompanyIcon: {
    zIndex: "1",
    display: "inline-block",
    position: "relative",
    marginRight: "-" + pxToVw(8),
    height: pxToVw(32),
    width: pxToVw(32),
    borderRadius: "50%",
    // border: "1.5px solid white",
    [theme.breakpoints.down("mb")]: {
      marginRight: "-" + pxToMvw(8),
      height: pxToMvw(28),
      width: pxToMvw(28),
    },
    "&::hover": {
      zIndex: "1",
    },
  },
  cardCompanysManyIcon: {
    height: pxToVw(33),
    width: pxToVw(33),
    backgroundColor: "#0EC99E",
    borderRadius: "50%",
    zIndex: "1",
    display: "flex",
    position: "relative",
    marginRight: "-" + pxToVw(6),
    fontSize: pxToVw(14),
    color: "#474747",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    border: "1.5px solid white",
    background:
      "linear-gradient(0deg, rgba(14, 201, 158, 0.2), rgba(14, 201, 158, 0.2)),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    [theme.breakpoints.down("mb")]: {
      height: pxToMvw(26),
      width: pxToMvw(26),
      fontSize: pxToMvw(12),
    },
  },
  cardSalaryIcon: {
    height: pxToVw(30),
    width: pxToVw(30),
    [theme.breakpoints.down("mb")]: {
      height: pxToMvw(25),
      width: pxToMvw(25),
    },
  },
  cardSalary: {
    paddingLeft: pxToVw(12),
    paddingRight: pxToVw(12),
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: pxToVw(14),
    color: "#0EC99E",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
      paddingLeft: pxToMvw(15),
      paddingRight: pxToMvw(15),
    },
  },
  cardSalaryTooltip: {
    fontSize: pxToVw(28),
  },
  cardContentFooter: {
    display: "flex",
    alignItems: "center",
    paddingBottom: pxToVh(33),
    [theme.breakpoints.down("mb")]: {
      paddingBottom: pxToMvh(0),
    },
  },
  cardContentFooterHeading: {
    display: "flex",
    color: "#B0B0B0",
    marginRight: pxToVw(20),
    fontSize: pxToVw(16),
    width: "fit-content",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  cardContentFooterSkills: {
    // background: "black",
    // width: pxToVw(662),
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    // height: pxToVh(35),
    alignItems: "center",
    overlay: "hidden",
    // width: "100%",
    [theme.breakpoints.down("mb")]: {},
  },
  cardContentFooterSkill: {
    borderRadius: pxToRem(25),
    whiteSpace: "nowrap",
    border: "none",
    outline: "none",
    background: "#DBF7F0",
    display: "inline-block",
    padding: pxToVh(3) + " " + pxToVw(10),
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
    fontSize: pxToVw(14),
    margin: pxToVh(5) + " 0",
    color: "#0EC99E",
    marginRight: "1rem",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
      padding: pxToMvh(3) + " " + pxToMvw(15),
    },
  },
  cardContentFooterRemSkill: {
    borderRadius: pxToRem(25),
    whiteSpace: "nowrap",
    border: "none",
    outline: "none",
    background: "#DBF7F0",
    display: "inline-block",
    padding: pxToVh(3) + " " + pxToVw(10),
    fontFamily: "Poppins, sans-serif",
    fontWeight: "300",
    fontSize: pxToVw(13),
    margin: pxToVh(5) + " 0",
    color: "#0EC99E",
    marginRight: "1rem",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
      padding: pxToMvh(3) + " " + pxToMvw(15),
    },
  },
  cardMobileTextDiv: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "500",
      color: "#0EC99E",
      paddingTop: pxToMvh(20),
      fontSize: pxToMvw(14),
      display: "flex",
      justifyContent: "center",
    },
  },
  tooltipchartDiv: {
    height: pxToVw(90),
    width: pxToVw(350),
    display: "grid",
    placeContent: "center",
  },
  tooltipTopCompaniesHiring: {
    // boxShadow: "0px 2px 11px 0px #77777738",
    fontFamily: "Poppins",
    fontSize: pxToVw(13),
    color: "#0EC99E",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "0.01em",
  },
  tooltipchartHeading: {
    fontSize: pxToVw(18),
    color: "#0EC99E",
    display: "flex",
    justifyContent: "center",
    lineHeight: "18px",
    letterSpacing: "0em",
    paddingBottom: pxToVh(18),
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
  },
  tooltipchartLabels: {
    display: "flex",
    width: pxToVw(320),
    fontFamily: "Poppins, sans-serif",
    fontWeight: "500",
    fontSize: pxToVw(10),
    alignItems: "center",
    // paddingBottom: pxToVh(5),
    // margin: pxToVw(3) + " 0",
  },
  tooltipchartLabel1: {
    display: "flex",
    justifySelf: "flex-end",
  },
  tooltipchartLabel2: {
    display: "flex",
    justifySelf: "flex-end",
    fontWeight: "600",
    fontSize: pxToVw(13),
    marginLeft: (props) => {
      return pxToVw(
        ((props.median - props.low) / (props.high - props.low)) * 290 - 12
      );
    },
  },
  tooltipchartLabel3: {
    display: "flex",
    justifySelf: "flex-end",
    marginLeft: "auto",
  },
  tooltipchartBottomLineDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    margin: "0 auto",
    width: "fit-content",
  },
  tooltipchartBottomLine: {
    borderRadius: "3px",
    width: pxToVw(290),
    border: "0.5px solid #0EC99E",
    background: "#0Ec99e",
  },
  tooltipchartIndicatorLine: {
    border: "0.5px solid #0EC99E",
    background: "#0Ec99e",
    position: "absolute",
    borderRadius: "3px",
    width: pxToVw(2),
    height: pxToVw(14),
    marginLeft: (props) => {
      return pxToVw(
        ((props.median - props.low) / (props.high - props.low)) * 290
      );
    },
    // border: "2px solid #0EC99E",
  },
});
