import { makeStyles } from "@mui/styles";
import { pxToMvw, pxToRem , pxToVh, pxToVw , pxToMvh} from "../../../../Utils/pxToRem";
import { theme} from "../../../../Utils/breakpoints";

export const useStyles = makeStyles(() => ({
    heading: {
            fontSize: pxToRem(48),
            background: "-webkit-linear-gradient(#00634C, #009270)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily:"Poppins,sans-serif",
            fontWeight: 600,
            letterSpacing: "-1.5px",
            margin: 0,
            lineHeight:pxToVh(86),
            textAlign: "center",
            marginTop: pxToVh(32),
            marginBottom: pxToVh(32),
            [theme.breakpoints.down("sm")]: {
           
            fontSize: pxToMvw(24),
            marginTop: pxToMvh(18),
            marginBottom: pxToMvh(24),
            
        }
    },
    progressBar: {
        width: pxToVw(180),
        height: pxToVw(180),
        backgroundColor: "#004031",
        display: "flex",
        alignItems: "center",
        position:"relative",
        justifyContent: "center",
        borderRadius: "50%",
        border: `${pxToRem(10)} solid #0EC99E`,
       [theme.breakpoints.down("sm")]: {
            width: pxToMvw(138),
            height:pxToMvw(138)
        },
        "& svg": {
            width: pxToVw(62),
            height: pxToVw(42),
            [theme.breakpoints.down("sm")]: {
            width: pxToMvw(47),
            height:pxToMvw(32.32)
        }
        },
        
    },
    moreButtons: {
        display: "flex",
        flexDirection: "column",
        gap: pxToVh(47),
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "row",
            gap:pxToMvw(27)
        }
    },
    explore: {
        width: pxToVw(217),
        height: pxToVw(80),
        color: "white",
        fontFamily: "Poppins,sans-serif",
        background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
        border: "2px solid #0EC99E",
        borderRadius: pxToRem(40),
        fontSize: pxToRem(18),
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(158),
            height: pxToMvw(48),
            borderRadius: pxToMvw(40),
            fontSize:pxToMvw(12)
        }
        
    },
    progressWrapper: {
        border: `${pxToRem(20)} solid rgba(14, 201, 158, 0.5)`,
        marginBottom:pxToVh(64),
        borderRadius: "50%",
    
    },
    CompletedContainer: {
        display: "flex",
        flexDirection:"column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: pxToVh(274),
        [theme.breakpoints.down("sm")]: {
            marginTop: pxToMvh(142),
        }
    },
    post: {
        width: pxToVw(185),
        height: pxToVw(80),
        border: "2px solid #0EC99E",
        backgroundColor: "#E3FFF9",
        borderRadius: pxToRem(40),
        color: "#004031",
        fontFamily: "Poppins,sans-serif",
        fontSize: pxToRem(18),
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(137),
            height: pxToMvw(48),
            borderRadius: pxToMvw(40),
            fontSize:pxToMvw(12)
        }
        
    }
}))