import React from "react";
import { useStyles } from "./styles";
import { ReactComponent as Completed } from "../../../Assets/images/RecruiterOnboard/StepCompleted.svg";

const Step = (props) => {

    const styles = useStyles();

    // const isIndex = () => {
    //     console.log(props.index);
    //     return ;
    // }

    return (
        <div className={styles.stepOuterContainer} onClick={props.onclick}>
            <div className={styles.stepContainer} style={{backgroundColor:props.current === props.index  | props.completed.includes(props.index) ? "#0EC99E" : "#E3FFF9",color: props.current === props.index ? "#fff" : "#0EC99E"}}>
                {props.completed.includes(props.index) ? <Completed /> : props.index}
            </div>
            {/* <div className={styles.stepContainer} style={{backgroundColor:props.current === props.index ? "#0EC99E" : "#E3FFF9",color: props.current === props.index ? "#fff" : "#0EC99E"}}>
                {props.completed.includes(props.index) ? <Completed /> : props.index}
            </div> */}
            <div className={styles.stepLabel}>
                {props.step}
            </div>
        </div>
    )
}

export default Step;