import { makeStyles } from "@mui/styles";
import {
  pxToVw,
  pxToVh,
  pxToRem,
  pxToMvw,
  pxToMvh,
} from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const useStyles = makeStyles({
  cardCarousalContainer: {
    // border: "solid",
    padding: pxToVh(30) + "  " + pxToVw(18),
    [theme.breakpoints.down("mb")]: {
      padding: pxToMvh(15) + "  " + pxToMvw(10),
      width: "100%",
    },
  },
  cardCarousal: {
    height: "100%",
    // width: pxToVw(525),
    width: (props) => pxToVw(props.width),
    // backgroundColor: "red",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("mb")]: {
      width: "100% !important",
    },
    "& .carousel-slider": {
      display: "flex",
      height: "100%",
    },
  },
  Carousalcontroller: {
    display: "flex",
    alignItems: "center",
    padding: "0 1px",
    cursor: "pointer",
  },
  CarousalcontrollerSVG: {
    height: pxToVw(45),
    width: pxToVw(45),
    borderRadius: "50%",
    boxShadow: "2px 0.3333333134651184px 4.3333330154418945px -1px #AFAFAF",
    [theme.breakpoints.down("mb")]: {
      height: pxToMvw(28),
      width: pxToMvw(28),
      // width: "100%",
    },
  },
});
