/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useStyles } from "./Styles";
import Topbar from "../../Shared/Topbar/Topbar";
import Text from "../../Shared/inputV2/Text/input";
import { ReactComponent as DialPad } from "../../../Assets/images/StudentOnboard/LoginPage/Dialpad.svg";
import { ReactComponent as Lock } from "../../../Assets/images/StudentOnboard/LoginPage/Lock.svg";
import Left from "../../../Assets/images/RecruiterOnboard/leftillustration.png";
import Right from "../../../Assets/images/RecruiterOnboard/rightIllustration.png";
import { ReactComponent as Eye } from "../../../Assets/images/StudentOnboard/LoginPage/Eye.svg";
import { ReactComponent as EyeClosed } from "../../../Assets/images/StudentOnboard/LoginPage/eyeClosed.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  loginFailure,
  loginStart,
  loginSuccess,
} from "../../../redux/Actions/StudentOnboarding/auth";
import Error from "../../Shared/Error/Error";
import { axiosClient } from "../../../Services/apiClient";

const StudentLogin = () => {
  const [values, setValues] = useState({
    phonenumber: "",
    password: "",
  });
  const [showPassord, setShowPassword] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (values.phonenumber.length === 0) {
      setPhoneError(false);
    }
  }, [values.phonenumber]);

  useEffect(() => {
    if (
      values.phonenumber.length === 10 &&
      values.password.length !== 0 &&
      !phoneError
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values.phonenumber, values.password, phoneError]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phoneError) {
      dispatch(loginStart());
      try {
        const response = await axiosClient.post("/studentLogin", {
          PhoneNumber: values.phonenumber,
          Password: values.password,
        });
        console.log(response);
        if (response.data.success === false) {
          setLoginError(response.data.message);
        } else {
          let userData = JSON.parse(window.atob(response.data.data));
          let isDetailedFilled = response.data.isDetailedFilled;
          dispatch(
            loginSuccess({
              user: userData,
              token: response.data.token,
            })
          );
          if (isDetailedFilled) {
            navigate("/explorecareer");
            setLoginError("");
          } else {
            navigate("/studentDetailsform");
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const classes = useStyles({ disabled });
  return (
    <div className={classes.studentLogin}>
      <Topbar />
      <div className={classes.container}>
        <h1 className={classes.title}>Welcome back to Glosity</h1>
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          className={classes.form}
        >
          <div className={classes.inputWrappers}>
            <Text
              icon={<DialPad />}
              onChange={onChange}
              value={values.phonenumber}
              name="phonenumber"
              type="text"
              placeholder="Enter your Phone number."
              errorMessage="Not a valid phone number"
              error={phoneError}
              setError={setPhoneError}
              loginError={loginError}
            />
            <Text
              icon={<Lock />}
              eye={
                !showPassord ? (
                  <Eye
                    onClick={() => {
                      setShowPassword(true);
                    }}
                  />
                ) : (
                  <EyeClosed
                    onClick={() => {
                      setShowPassword(false);
                    }}
                  />
                )
              }
              onChange={onChange}
              value={values.password}
              name="password"
              type={showPassord ? "text" : "password"}
              placeholder="Enter Password."
              loginError={loginError}
            />
          </div>
          {loginError && <Error text={loginError} color="Red" />}
          <button disabled={disabled} type="submit" className={classes.button}>
            Login
          </button>
        </form>
        <div className={classes.bottomWrapper}>
          <div className={classes.forgotPasswordWrapper}>
            <p
              className={classes.forgot}
              onClick={() => {
                navigate("/forgotpassword");
              }}
            >
              Forgot Password?
            </p>
            <p className={classes.or}>or</p>
          </div>
          <div className={classes.bottomOptions}>
            <span>New to Glosity ?</span>
            <button
              className={classes.signUp}
              onClick={() => {
                navigate("/studentregister");
              }}
            >
              Sign up
            </button>
          </div>
        </div>

        <img src={Left} className={classes.left} />
        <img src={Right} className={classes.right} />
      </div>
    </div>
  );
};

export default StudentLogin;
