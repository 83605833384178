import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw } from "../../../../Utils/pxToRem";

export const useStyles = makeStyles({
  Profile: { margin: "auto" },
  Profile__User: {
    position: " relative",
    padding: pxToVw(22),
    display: "flex",
    maxWidth: pxToVw(1510),
    background: "white",
    marginLeft: "9.5%",
    marginTop: pxToVw(180),
    alignItems: "center",
    border: `${pxToVw(3)} solid #ECECEC`,
    borderRadius: pxToVw(40),
    // boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.25)",
  },
  Profile__User_image: {
    height: pxToVw(220),
    width: pxToVw(220),
    position: "relative",
    borderRadius: "50%",
    objectFit: "cover",
    marginRight: pxToVw(30),
    marginLeft: pxToVw(50),
    marginTop: pxToVh(-200),
    marginBottom: pxToVh(45),
  },
  Profile__User_info: {
    flex: "2",
    marginLeft: pxToVw(50),
    marginTop: pxToVh(5),
    marginBottom: pxToVh(10),
  },
  Profile__Image_Wrapper: {
    marginTop: pxToVh(-40),
    marginLeft: pxToVw(20),
  },
  Profile__User_Info__name: {
    fontSize: pxToVw(40),
    fontWeight: "500",
    marginBottom: pxToVh(10),
  },

  Profile__User_Info__oneLiner: {
    fontSize: pxToVw(20),
    fontWeight: "400",
    marginBottom: pxToVh(14),
    color: "rgba(38, 38, 38, 0.774)",
  },
  Profile__User_Info__desc_aspire: {
    display: "flex",
    fontSize: pxToVw(17),
    color: "rgba(128, 128, 128, 0.774)",
  },

  Profile__User_Info__chip: {
    display: "flex",
    marginLeft: pxToVw(10),
  },

  Profile__User_Info__desc_skills: {
    marginTop: pxToVh(10),
    marginBottom: pxToVh(4),
    display: "flex",
    fontSize: "pxToVw(17)",
    color: "rgba(128, 128, 128, 0.774)",
  },

  Profile__User_Info__desc_university: {
    marginTop: pxToVh(18),
    display: "flex",
  },
  Profile__User_Info__desc_location: {
    marginTop: pxToVh(18),
    marginLeft: pxToVw(18),
    display: "flex",
  },

  University: {
    marginLeft: pxToVw(6),
    marginTop: pxToVh(3),
    fontSize: pxToVw(12),
    fontWeight: 600,
    color: "rgba(0, 0, 0, 0.774)",
  },

  Location: {
    marginLeft: pxToVw(6),
    marginTop: pxToVh(3),
    fontSize: pxToVw(12),
    fontWeight: 600,
    color: "rgba(127, 127, 127, 0.774)",
  },

  Profile__Social_Icon__Wrapper: {
    marginTop: pxToVh(18),
    marginBottom: pxToVh(-15),
    color: "#0EC99E",
    fontSize: pxToVw(27),
  },

  Profile__User_Info__univ_loc_wrapper: {
    display: "flex",
  },

  Profile__User_actions: {
    flex: 1,
    marginLeft: pxToVw(25),
    marginTop: pxToVh(-15),
    marginRight: pxToVw(10),
  },

  Profile__User_actions_button: {
    fontFamily: "inherit",
    border: "none",
    background: "transparent",
    cursor: "pointer",
    display: "block",
    paddingLeft: pxToVw(15),
    paddingRight: pxToVw(15),
    paddingTop: pxToVh(12),
    paddingBottom: pxToVh(12),
    width: "80%",
    marginLeft: pxToVw(25),
    borderRadius: pxToVw(12),
    fontSize: pxToVw(17),
    fontWeight: 600,
  },

  Profile__User_actions_follow: {
    color: "#fff",
    backgroundColor: "#0EC99E",
    marginBottom: pxToVh(19),
  },

  //   Profile__User_actions_follow:hover {
  //       backgroundColor:" #82e7cf",
  //   }

  Profile__User_actions_message: {
    backgroundColor: "#005b7a",
    color: "#fff",
  },

  //   Profile__User_actions_message:hover {
  //       backgroundColor:" #82e7cf",
  //   }
  Profile__User_metrics: {
    flex: 1,
    marginTop: pxToVh(10),
    marginLeft: pxToVw(25),
    marginRight: pxToVh(10),
  },
});
