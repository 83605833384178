import { makeStyles } from "@mui/styles";
import { pxToMvh, pxToMvw, pxToVw, pxToVh } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";
export const useStyles = makeStyles({
  footer: {
    display: "flex",
    width: pxToVw(1920),
    height: pxToVh(551),
    alignItems: "center",
    backgroundColor: "#131313",
    gap: pxToVw(670),
    position: "relative",
    paddingTop: pxToVh(70),
    paddingLeft: pxToVw(295),
    [theme.breakpoints.down("mb")]: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: pxToVw(0),
      flexDirection: "column",
      width: "100%",
      height: pxToMvh(550),
      paddingLeft: pxToMvw(25),
      paddingTop: pxToMvw(59),
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
    gap: pxToVw(30),
    [theme.breakpoints.down("mb")]: {
      gap: pxToMvw(9),
    },
  },
  logo: {
    width: pxToVw(222),
    height: pxToVh(79),
    objectFit: "contain",
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(150),
      height: pxToMvh(40),
    },
  },
  icons: {
    display: "flex",
    alignItems: "center",
    gap: pxToVw(16),
    [theme.breakpoints.down("mb")]: {
      gap: pxToMvw(16),
    },
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: pxToVw(32),
    height: pxToVh(32),
    backgroundColor: "#0EC99E10",
    borderRadius: "50%",
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(32),
      height: pxToMvh(32),
    },
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "500",
    fontSize: pxToVw(20),
    color: "#FFFFFF",
    marginBottom: pxToVw(8),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(25),
      marginBottom: pxToMvw(15),
    },
  },
  subtitleWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: pxToVw(7),
    [theme.breakpoints.down("mb")]: {
      gap: pxToVw(20),
      marginBottom: pxToVw(20),
    },
  },
  subtitle: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(14),
    color: "#D9DBE1",
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(18),
    },
  },
  right: {
    display: "flex",
    gap: pxToVw(60),
    marginTop: pxToVw(65),
    [theme.breakpoints.down("mb")]: {
      gap: pxToMvw(60),
    },
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: pxToVw(18),
    marginBottom: pxToVh(20),
    [theme.breakpoints.down("mb")]: {
      gap: pxToMvh(20),
    },
  },
  listIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: pxToVw(25),
    height: pxToVw(25),
    [theme.breakpoints.down("mb")]: { width: pxToMvw(25), height: pxToMvh(25) },
  },
  listGroup: {
    display: "flex",
    alignItems: "center",
    gap: pxToVw(12),
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      gap: pxToMvw(12),
    },
  },
  bottomlogo: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: pxToVw(230),
    height: pxToVw(250),
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
});
