/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { useState } from "react";
import { useStyles } from "./styles";
import { ReactComponent as Envelope } from "../../../../Assets/images/RecruiterOnboard/Envelope.svg";
import { ReactComponent as Lock } from "../../../../Assets/images/RecruiterOnboard/Lock.svg";
import { ReactComponent as Pen } from "../../../../Assets/images/RecruiterOnboard/pen.svg";
// import Input from "../../../Shared/input/index";
import { Link } from "react-router-dom";
import InputText from "../../../Shared/input/text";
import Password from "../../../Shared/input/password";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../../../../redux/types";

import {
  SaveGeneralInfo,
  //   addCompanyApi,
} from "../../../../Services/lib/RecruiterOnboarding";

const GeneralInfo = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const PersistedGeneralData = useSelector(
    (state) => state.RecruiterOnboard.persistGeneralData
  );

  const [generalForm, setGeneralForm] = useState({
    name: {
      label: "Name",
      elementType: "text",
      editable: true,
      placeholder: "Enter your Full Name",
      icon: <Pen />,
      value: "",
      validation: {
        required: true,
        isName: true,
      },
      errorNote: "Name can only contain letters",
      valid: false,
      touched: false,
    },
    email: {
      label: "email",
      elementType: "text",
      editable: true,
      placeholder: "Enter your Company Email Id",
      icon: <Envelope />,
      value: "",
      validation: {
        required: true,
        isEmail: true,
      },
      errors: [
        "Enter a valid email id",
        <>
          Seems like you are already on Glosity.{" "}
          <Link className={styles.login} to="/login">
            Login to continue
          </Link>
        </>,
      ],
      errorNote: null,
      valid: false,
      touched: false,
    },
    password: {
      label: "password",
      elementType: "password",
      editable: true,
      placeholder: "Create Your Password",
      icon: <Lock />,
      value: "",
      validation: {
        required: true,
        isPassword: true,
      },
      note: "Must be 8 characters or longer. Use at least one number or symbol(eg, !@#$%^)",
      errorNote: null,
      valid: false,
      touched: false,
    },
    cnfPass: {
      label: "cnfPass",
      elementType: "password",
      editable: true,
      placeholder: "Confirm your Password",
      icon: <Lock />,
      value: "",
      validation: {
        required: true,
      },
      errorNote: "Passwords do not match",
      valid: false,
      touched: false,
    },
    cname: {
      label: "companyName",
      elementType: "text",
      editable: true,
      placeholder: "Enter your Company Name",
      icon: <Pen />,
      data: [],
      value: "",
      validation: {
        required: true,
      },
      errorNote: null,
      valid: false,
      touched: false,
    },
  });

  const [formError, setFormError] = useState({
    errorState: false,
    errorNote: null,
  });

  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
 
    if (PersistedGeneralData) {

      for (let identifier in generalForm) {
        const updatedElement = {
          ...generalForm[identifier],
        };
        updatedElement.value = PersistedGeneralData[identifier];
        updatedElement.valid = true;
        updatedElement.touched = true;
        updatedElement.editable = false;

        setGeneralForm((prev) => {
          prev[identifier] = updatedElement;
          let FormValid = true;

          for (let inputIdentifier in generalForm) {
            FormValid = generalForm[inputIdentifier].valid && FormValid;
          }

          return {
            ...prev,
          };
        });
      }

      setFormValid(true);
    }
    dispatch({ type: types.GET_COMPANY_REQUESTED });
  }, [PersistedGeneralData]);

  const checkValidity = (value, rules) => {
    let isValid = true;
    if (value.length == 0) {
      return true;
    }
    if (rules.isName) {
      let pattern = /^[a-zA-Z ]*$/;
      isValid = value.trim() != "" && pattern.test(value) && isValid;
    }
    if (rules.isEmail) {
      let pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = value.trim() != "" && pattern.test(value) && isValid;
    }
    // password validation
    if (rules.isPassword) {
      let pattern = /(?=.{8,})((?=.*\d)|(?=.*[!@#$%^"]))/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  };

  const inputChangedHandler = (e, id) => {
    const updatedElement = {
      ...generalForm[id],
    };

    updatedElement.value = e.target.value;
    updatedElement.touched = true;
    setFormError((prev) => ({
      ...prev,
      errorNote: null,
      errorState: false,
    }));
    if (updatedElement.errors != undefined) {
      updatedElement.errorNote =
        updatedElement.errors[0] || updatedElement.errorNote;
    }

    updatedElement.valid = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    
    if (updatedElement.value == "") {
      updatedElement.touched = false;
      updatedElement.valid = false;
    }

    setGeneralForm((prev) => {
      prev[id] = updatedElement;
      // password match
      if (generalForm.cnfPass.value === generalForm.password.value) {
        generalForm.cnfPass.valid = true;
      } else {
        generalForm.cnfPass.valid = false;
      }

      let FormValid = true;

      for (let inputIdentifier in generalForm) {
        
        FormValid = generalForm[inputIdentifier].valid && FormValid;
      }
      setFormValid(FormValid);
      return {
        ...prev,
      };
    });
  };

  let formElement = [];

  for (let key in generalForm) {
    formElement.push({
      id: key,
      config: generalForm[key],
    });
  }

  const Next = () => {
  
  
    if (PersistedGeneralData) {
        props.setStep(props.step + 1);
        props.setCompleted(step => [...step, props.step]);
        props.setCurrent(props.step + 1);
        return;
    }

    const Data = {
      name: generalForm.name.value,
      companyEmailId: generalForm.email.value,
      password: generalForm.password.value,
      companyName: generalForm.cname.value,
      sendCount: 0,
    };

    const PersistGeneralData = {
      name: generalForm.name.value,
      email: generalForm.email.value,
      password: generalForm.password.value,
      cnfPass: generalForm.password.value,
      cname: generalForm.cname.value,
    };

    SaveGeneralInfo(Data)
      .then((res) => {
        console.log(res);
        if (res.success) {
     
          dispatch({
            type: types.SET_GENERAL_SUCCESS,
            generalInfo: Data,
            PersistGeneralData: PersistGeneralData,
          });
          props.setStep(-1);

        } else {
         
            setFormError((prev) => ({
              ...prev,
              errorNote: res.message,
              errorState: true,
            }));
        
          dispatch({ type: types.SET_GENERAL_FAIL, generalInfo: Data });
        }
      })
      .catch((err) => {
        setFormError((prev) => ({
          ...prev,
          errorNote: "Temporarily Out Of Service",
          errorState: true,
        }));
      });
  };

  return (
    <div className={styles.mainContent}>
      <div className={styles.inputContainer}>
        <div className={styles.inputs}>
          <InputText
            elementType="text"
            placeholder="Enter Your Full Name"
            onchange={(e) => inputChangedHandler(e, "name")}
            icon={<Pen />}
            editable={generalForm.name.editable}
            value={generalForm.name.value}
            valid={generalForm.name.valid}
            touched={generalForm.name.touched}
            errorNote={generalForm.name.errorNote}
            validation={{
              required: true,
              isName: true,
            }}
          />
          <InputText
            elementType="text"
            placeholder="Enter Your Company Email Id"
            onchange={(e) => inputChangedHandler(e, "email")}
            icon={<Envelope />}
            editable={generalForm.email.editable}
            value={generalForm.email.value}
            valid={generalForm.email.valid}
            touched={generalForm.email.touched}
            errorNote={generalForm.email.errorNote}
            validation={{
              required: true,
              isEmail: true,
            }}
          />
          <Password
            elementType="password"
            placeholder="Create Your Password"
            onchange={(e) => inputChangedHandler(e, "password")}
            icon={<Lock />}
            editable={generalForm.password.editable}
            value={generalForm.password.value}
            note={generalForm.password.note}
            valid={generalForm.password.valid}
            touched={generalForm.password.touched}
            errorNote={generalForm.password.errorNote}
            validation={{
              required: true,
              isPassword: true,
            }}
          />

          <Password
            elementType="password"
            placeholder="Confirm Your Password"
            onchange={(e) => inputChangedHandler(e, "cnfPass")}
            icon={<Lock />}
            editable={generalForm.cnfPass.editable}
            value={generalForm.cnfPass.value}
            valid={generalForm.cnfPass.valid}
            touched={generalForm.cnfPass.touched}
            errorNote={generalForm.cnfPass.errorNote}
            validation={{
              required: true,
            }}
          />
         
          <InputText
            elementType="text"
            placeholder="Enter Your Company Name"
            onchange={(e) => inputChangedHandler(e, "cname")}
            icon={<Pen />}
            editable={generalForm.cname.editable}
            value={generalForm.cname.value}
            valid={generalForm.cname.valid}
            touched={generalForm.cname.touched}
            errorNote={generalForm.cname.errorNote}
            validation={{
              required: true,
            }}
          />
        </div>
        {formError.errorState && (
          <div className={styles.errorContainer}>{formError.errorNote}</div>
        )}

        <button
          onClick={Next}
          disabled={!formValid}
          className={`${styles.submit} ${
            !formValid ? styles.disabledSubmit : styles.enabledSubmit
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default GeneralInfo;
