import axios from "axios";
import { axiosClient } from "../../../Services/apiClient";

export const requestLogin = ({ phonenumber, password }) => {
  const response = axiosClient.post("/studentLogin", {
    PhoneNumber: phonenumber,
    Password: password,
  });
  return response;
};

export const requestSignUp = ({ email, password, firstname, lastname }) => {
  const response = axios.post("http://localhost:5000/api/auth/register", {
    email,
    password,
    firstname,
    lastname,
  });
  return response;
};
