import React from "react";
import { pxToMvw, pxToVw } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  error: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(16),
    color: (props) => props.color,
    textAlign: "left",
    marginLeft: pxToVw(20),

    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
    },
  },
});

const Error = ({ text, color }) => {
  const classes = useStyles({ color });

  return <p className={classes.error}>{text}</p>;
};

export default Error;
