import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useStyles } from "./Styles";
import TextField from "@mui/material/TextField";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/private-theming";
import {
  DatePicker,
  MobileDatePicker,
  KeyboardDatePicker,
  DesktopDatePicker,
} from "@mui/lab";
import moment from "moment";
import PickersDay, {
  PickersDayProps,
  pickersDayClasses,
} from "@mui/lab/PickersDay";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as DateIcon } from "../../../../Assets/images/StudentOnboard/Form/date.svg";
import { pxToMvw, pxToVw } from "../../../../Utils/pxToRem";
import { Box } from "@mui/system";

const CustomDatePicker = ({ text, value, setValue, placeholder, disabled }) => {
  const [focused, setFocused] = useState(false);
  const [borderColor, setBorderColor] = useState("white");
  const classes = useStyles({ borderColor });

  useEffect(() => {
    if (value) {
      setBorderColor("#0EC99E");
    }
  }, [value]);

  const handleFocus = (e) => {
    setBorderColor("white");
    setFocused(true);
  };
  const handleBlur = (e) => {
    if (!value) {
      setBorderColor("white");
    }
  };

  // // eslint-disable-next-line react/display-name
  // const CustomInput = forwardRef((props, ref) => {
  //   return (
  //     <div
  //       className={classes.datePickerWrapper}
  //       ref={ref}
  //       onBlur={handleBlur}
  //       onFocus={handleFocus}
  //       style={{ border: `2px solid ${borderColor}` }}
  //     >
  //       <DateIcon className={classes.icon} />
  //       <input {...props} className={classes.datePicker} />
  //     </div>
  //   );
  // });

  // const inputref = useRef(null);

  // console.log(value);

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    return (
      <PickersDay
        {...pickersDayProps}
        sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: "#0EC99E",
          },
        }}
      />
    );
  };

  return (
    <div className={classes.dateWrapper}>
      {/* <p className={classes.text}>{text}</p> */}
      {/* <div className={classes.dateWrapper}>
        <DatePicker
          selected={value}
          onChange={(date) => setValue(date)}
          placeholderText={placeholder}
          dateFormat="MM/yyyy"
          // showMonthYearPicker
          // showFullMonthYearPicker
          customInput={<CustomInput inputRef={inputref} />}
        /> */}
      <div className={classes.desktopPicker}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DesktopDatePicker
            label="Custom input"
            disabled={disabled}
            // inputFormat="MM/yyyy"
            // views={["year", "month"]}
            renderDay={renderWeekPickerDay}
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Box
                onFocus={handleFocus}
                onBlur={handleBlur}
                // className={classes.datePickerWrapper}
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  backgroundColor: disabled ? "#f0f0f0" : "white",
                  cursor: "pointer",
                  width: pxToVw(288),
                  height: pxToVw(79),
                  borderRadius: pxToVw(40),
                  paddingLeft: pxToVw(50),
                  paddingRight: pxToVw(60),
                }}
              >
                <input
                  ref={inputRef}
                  {...inputProps}
                  placeholder={text}
                  className={classes.dateInput}
                />

                {InputProps?.endAdornment}
              </Box>
            )}
          />
        </LocalizationProvider>
      </div>
      <div className={classes.mobilePicker}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <MobileDatePicker
            disabled={disabled}
            // views={["year", "month"]}
            // placeholder="-select date-"
            inputFormat="MM/yyyy"
            orientation="portrait"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Box
                onFocus={handleFocus}
                onBlur={handleBlur}
                // className={classes.datePickerWrapper}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: disabled ? "#f0f0f0" : "white",
                  cursor: "pointer",
                  width: pxToMvw(170),
                  height: pxToMvw(52),
                  borderRadius: pxToMvw(40),
                }}
              >
                <input
                  ref={inputRef}
                  {...inputProps}
                  placeholder={text}
                  className={classes.dateInput}
                />
                <DateIcon className={classes.icon} />
                {/* {InputProps?.endAdornment} */}
              </Box>
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default CustomDatePicker;
