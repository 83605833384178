import { makeStyles } from "@mui/styles";
import { pxToVw, pxToVh, pxToRem } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const useStyles = makeStyles({
  exploreCard: {
    display: "flex",
    borderRadius: pxToRem(24),
    boxShadow: "0px 0px 50px 2px #E3E6EC",
    background: "#FFFFFF",
    [theme.breakpoints.down("mb")]: {
      flexDirection: "column",
    },
  },
});
