import React , {useState,useEffect} from "react";
// import Header from "./Header";
// import { withRouter } from "react-router-dom";
import StepHeader from "./StepsHeader";
import { useNavigate  } from "react-router-dom";
// import {Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import GeneralInfo from "./RecruiterSignUp/GeneralInfo/index";
import VerifyEmail from "./RecruiterSignUp/VerifyEmail";
import CompanyPage from "./RecruiterSignUp/CompanyPage";
import SelectDomain from "./RecruiterSignUp/SelectDomain";
import Completed from "./RecruiterSignUp/Completed";
import Header from "./Header";
import * as Types from "../../redux/types";

import { ReactComponent as Back } from "../../Assets/images/RecruiterOnboard/back.svg";
import { useStyles } from "./styles";

const Index = () => {

  const [step, setStep] = useState(1);
  const [current, setCurrent] = useState(1);
  const [completed, setCompleted] = useState([]);
  const titles = ["General Info", "Company Page", "Hiring For"];
  const styles = useStyles();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [finishStatus, setfinishStatus] = useState(false);

const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
        if (window.confirm("Do you want to go back ? Your Progress may lost ")) {
            setfinishStatus(true)
            // your logic
          navigate("/login");
        } else {
            window.history.pushState(null, null, window.location.pathname);
            setfinishStatus(false)
        }
    }
  }
  
  useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
      dispatch({ type: Types.REMOVE_PERSISTENCE})
      
    }
        
  }, [])
  
 
  const PageRender = () => {
    switch (step) {
      case 1:
        return <GeneralInfo setCurrent={setCurrent} setCompleted={setCompleted} step={step} setStep={setStep} />
      // case 2:
      //   return <VerifyEmail setCurrent={setCurrent} setCompleted={setCompleted} step={step} setStep={setStep} />
      case 2:
        return <CompanyPage setCurrent={setCurrent} setCompleted={setCompleted} step={step} setStep={setStep} />
      case 3:
        return <SelectDomain setCurrent={setCurrent} setCompleted={setCompleted} step={step} setStep={setStep} />
      case 4:
        return <Completed />
      default:
        return <VerifyEmail setCurrent={setCurrent} setCompleted={setCompleted} step={step} setStep={setStep} />;
      }
  }

  const handleBack = () => {
    setStep(step - 1);
    setCurrent(current - 1);
    completed.pop();
  }

  return (
    <Header step={step}>
       {step > 1 && step < 4 && <div className={styles.backButton} onClick={handleBack}>
            <Back />
        </div>}
      <div style={{display:"flex",alignItems:"center",flexDirection:"column",position:"relative"}}>
        <StepHeader setCurrent={setCurrent} setStep={setStep} current={current} setCompleted={setCompleted} completed={completed} step={step} titles={titles} />
        {PageRender()}
      </div>
    </Header>
  )
};

export default Index;
