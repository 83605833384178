import { axiosClient } from "../apiClient";

export async function getStudentDomains() {
  return axiosClient
    .get("/getStudentDomains")
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function getStudentAspirations() {
  return axiosClient
    .get("/getStudentAspirations")
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function getStudentSkills() {
  return axiosClient
    .get("/getStudentSkills")
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function getStudentDegree() {
  return axiosClient
    .get("/getStudentDegrees")
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function getStudentInstitute() {
  return axiosClient
    .get("/getStudentInstitutes")
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}

export async function getStudentFieldOfStudy() {
  return axiosClient
    .get("/getStudentRecentSpecializations")
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
}
