import React, { useState } from "react";
import { useStyles } from "./Styles";
import { ReactComponent as CloseIcon } from "../../../Assets/images/Roadmap/cross.svg";
import { ReactComponent as FileIcon } from "../../../Assets/images/Roadmap/fileIcon.svg";
import { ReactComponent as VideoIcon } from "../../../Assets/images/Roadmap/videoIcon.svg";
import { ReactComponent as CompletedIcon } from "../../../Assets/images/Roadmap/completedIcon.svg";

// import ModalManager from "../../Shared/ModalManager/ModalManager";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../redux/Actions/Modal/modal";
import Modal from "@mui/material/Modal";

export const RoadmapModal = ({ open }) => {
  const classes = useStyles();
  const [close, setClose] = useState(open);
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.modal.data);

  const handleModalOpen = () => {
    setClose(false);
    dispatch(closeModal({ type: "ROADMAPMODAL", close: close }));
  };
  return (
    <Modal open={open} className={classes.modalWrapper}>
      <div className={classes.modalContent}>
        <CloseIcon className={classes.closeIcon} onClick={handleModalOpen} />
        <div className={classes.modalBody}>
          <h1 className={classes.modalHeaderTitle}>{modalData.heading}</h1>
          <p className={classes.modalDescription}>{modalData.description}</p>
          <p className={classes.modalResourceToFollow}>Resources to follow</p>
          <div className={classes.modalResourcesDiv}>
            {modalData.resources.map((resource, index) => {
              if (resource.type === "File") {
                return (
                  <div className={classes.modalResource} key={index}>
                    <FileIcon
                      className={`${
                        resource.completed
                          ? classes.ResourceIconOpened
                          : classes.ResourceIcon
                      }`}
                    />
                    <p
                      className={`${
                        resource.completed
                          ? classes.modalResourcesNameOpened
                          : classes.modalResourcesName
                      }`}
                    >
                      {resource.title}
                    </p>
                  </div>
                );
              } else if (resource.type === "Video") {
                return (
                  <div className={classes.modalResource} key={index}>
                    <VideoIcon
                      className={`${
                        resource.completed
                          ? classes.ResourceIconOpened
                          : classes.ResourceIcon
                      }`}
                    />
                    <p
                      className={`${
                        resource.completed
                          ? classes.modalResourcesNameOpened
                          : classes.modalResourcesName
                      }`}
                    >
                      {resource.title}
                    </p>
                  </div>
                );
              }
            })}

            {/* <div className={classes.modalResource}>
              <FileIcon className={classes.ResourceIconOpened} />
              <p className={classes.modalResourcesNameOpened}>
                How to build a career in Data Science?
              </p>
            </div>
            <div className={classes.modalResource}>
              <VideoIcon className={classes.ResourceIcon} />
              <p className={classes.modalResourcesName}>
                Who are Data Sciencetist?
              </p>
            </div> */}
          </div>
          <button className={classes.modalMarkAsCompleted}>
            <CompletedIcon className={classes.CompletedIcon} />
            Mark as Completed
          </button>
        </div>
      </div>
    </Modal>
  );
};
