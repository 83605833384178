import { makeStyles } from "@mui/styles";
import { pxToMvw, pxToRem , pxToVh, pxToVw} from "../../../../Utils/pxToRem";
import { theme} from "../../../../Utils/breakpoints";

export const useStyles = makeStyles(() => ({
    heading: {
            fontSize: pxToRem(48),
            background: "-webkit-linear-gradient(#00634C, #009270)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily:"Poppins,sans-serif",
            fontWeight: 600,
            letterSpacing: "-1.5px",
            margin: 0,
            lineHeight:pxToVh(86),
            textAlign: "center",
            marginTop: pxToVh(32),
        marginBottom: pxToVh(36),
            [theme.breakpoints.down("sm")]: {
           
            fontSize:pxToMvw(24)
        }
    },
    mainContent: {
        // position: "fixed",
        // top:pxToVh(150 + 71)
        marginTop: pxToVh(278),
        [theme.breakpoints.down("sm")]: {
            marginTop: pxToVh(172),
        }
        
    },
   
    inputs: {
        display: "flex",
        flexDirection: "column",
        gap: pxToVh(24),
        marginBottom: pxToVh(32),
        
    },
    inputContainer: {

        [theme.breakpoints.down("sm")]: {
            position:"relative",
            zIndex:20
        }
    },
    submit: {
        width: pxToVw(131),
        height: pxToVw(80),
        borderRadius: pxToRem(40),
        // background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
        // background: "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), #0EC99E",
        // border:"2px solid #0EC99E",
        margin: `${pxToVh(24)} auto ${pxToVh(32)} auto`,
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: pxToVh(118),
        fontSize: pxToRem(18),
        fontFamily: "Poppins,sans-serif",
        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(158),
            height: pxToMvw(61),
            borderRadius: pxToMvw(40),
            fontSize:pxToMvw(15)
        }
        // cursor:"pointer"
    },
    disabledSubmit: {
        background: "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), #0EC99E",
        border: "none",
        cursor:"no-drop"
    },
    enabledSubmit: {
        background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
        border:"2px solid #0EC99E",
        cursor:"pointer"
    },
    login: {
        color: "#0EC99E",
        textDecoration: "none",
        fontWeight: 400,
        fontSize: pxToRem(16),
        [theme.breakpoints.down("sm")]: {
            fontSize:pxToMvw(10)
        }
    },
    errorContainer: {
        color: "#F44336",
        fontSize: pxToVw(16),
        textAlign: "center",
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize:pxToMvw(14)
        }
    }
}))