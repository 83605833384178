import { makeStyles } from "@mui/styles";
import { pxToVw, pxToVh, pxToRem, pxToMvw, pxToMvh } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";


export const useStyles = makeStyles({
    stepContainer: {
        width: pxToVw(40),
        height: pxToVw(40),
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: pxToRem(12),
        position: "relative",
        zIndex: 5,
        transition: "all .5s ease",
        border: "1px solid rgba(14, 201, 158, 0.5)",
        "& svg": {
            width: pxToVw(12.5),
            height: pxToVw(8.5)
        }
    },
    wrapperContainer: {
        // backgroundColor: "#f6f6f6",
        width: "98%",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        height: pxToVh(242),
        zIndex: 10,
        background: "linear-gradient(178.61deg, #F6F6F6 66.75%, rgba(246, 246, 246, 0.44) 153.29%)",
        [theme.breakpoints.down("sm")]: {
            height: "unset"
        }
    },
    stepsContainer: {
        display: "flex",
        // gap: pxToVw(200),
        gap: pxToVw(170),
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    stepOuterContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: pxToVh(15),
        cursor: "pointer"
    },
    stepLabel: {
        fontSize: pxToRem(12),
        color: "#009270",
        fontFamily: "Poppins,sans-serif"
    },
    heading: {
        fontSize: pxToRem(48),
        background: "-webkit-linear-gradient(#00634C, #009270)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontFamily: "Poppins,sans-serif",
        fontWeight: 600,
        letterSpacing: "-1.5px",
        margin: 0,
        lineHeight: pxToVh(86),
        textAlign: "center",
        marginTop: pxToVh(32),
        marginBottom: pxToVh(36),
        [theme.breakpoints.down("sm")]: {
            fontSize: pxToMvw(30),
            marginTop: pxToMvh(60),
            marginBottom: 0

        }
    },
    stepHeaderWrapper: {
        marginTop: pxToVh(36),
        zIndex: 10,
        width: "auto",
        position: "fixed",
        [theme.breakpoints.down("sm")]: {
            marginTop: 0,
            width: "100%",
            background: "white"
        }

    },
    selectDomainText: {
        fontSize: pxToRem(18),
        color: "rgba(157, 157, 157, 1)!important"

    },
    upperTag: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    progress: {
        height: pxToVh(4),
        backgroundColor: "#0EC99E",
        transition: "all .5s ease"
    },
    faded: {
        position: "absolute",
        top: 0,
        left: 0,
        height: pxToVh(150),
        backgroundColor: "red"
    },
    stepProgressBar: {
        height: pxToRem(4),
        backgroundColor: "#82DFCA",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%,31%)",
        top: pxToVw(15),
        // width: `calc(100% - ${pxToVw(90)})`,
        width:`26vw`,
        overflow: "hidden",
        zIndex: 2,
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        [theme.breakpoints.down("md")]: {
            width: `calc(100% - ${pxToVw(100)})`,


        },


    },


});
