// export const GET_USERS_REQUESTED = "GET_USERS_REQUESTED";
// export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
// export const GET_USERS_FAILED = "GET_USERS_FAILED";
export const SET_GENERAL_SUCCESS = "SET_GENERAL_SUCCESS";
export const SET_GENERAL_FAIL = "SET_GENERAL_FAIL";
export const SET_COMPANY = "SET_COMPANY";
export const SET_GENERAL_REQUESTED = "SET_GENERAL_REQUESTED";
export const SET_COMPANY_REQUESTED = "SET_COMPANY_REQUESTED";
export const SET_COMPANY_SUCCESS = "SET_COMPANY_SUCCESS";

export const SET_COMPANY_FAIL = "SET_COMPANY_FAIL";

export const SET_EMAIL_VERIFIED_SUCCESS = "SET_EMAIL_VERIFIED_SUCCESS";
export const SET_EMAIL_VERIFIED_FAIL = "SET_EMAIL_VERIFIED_FAIL";
export const SET_EMAIL_VERIFIED_REQUESTED = "SET_EMAIL_VERIFIED_REQUESTED";

export const SET_COMPANY_TYPE_REQUESTED = "SET_COMPANY_TYPE_REQUESTED";
export const SET_COMPANY_TYPE_SUCCESS = "SET_COMPANY_TYPE_SUCCESS";
export const SET_COMPANY_TYPE_FAIL = "SET_COMPANY_TYPE_FAIL";

export const GET_COMPANY_REQUESTED = "GET_COMPANY_REQUESTED";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL";

export const GET_INDUSTRY_REQUESTED = "GET_INDUSTRY_REQUESTED";
export const GET_INDUSTRY_SUCCESS = "GET_INDUSTRY_SUCCESS";
export const GET_INDUSTRY_FAIL = "GET_INDUSTRY_FAIL";

export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"

export const SET_COMPANY_SIZE_FAIL = "SET_COMPANY_SIZE_FAIL";
export const SET_COMPANY_SIZE_REQUESTED = "SET_COMPANY_SIZE_REQUESTED";
export const SET_COMPANY_SIZE_SUCCESS = "SET_COMPANY_SIZE_SUCCESS";

export const GET_DOMAIN_REQUESTED = "GET_DOMAIN_REQUESTED";
export const GET_DOMAIN_FAIL = "GET_DOMAIN_FAIL";
export const GET_DOMAIN_SUCCESS = "GET_DOMAIN_SUCCESS";

export const SET_DOMAIN_REQUESTED = "SET_DOMAIN_REQUESTED";
export const SET_DOMAIN_SUCCESS = "SET_DOMAIN_SUCCESS";
export const SET_DOMAIN_FAIL = "SET_DOMAIN_FAIL";

export const REMOVE_PERSISTENCE = "REMOVE_PERSISTENCE";