import * as type from "../types";

const initialState = {
  generalInfo: null,
  companyPage: null,
  emailVerified: false,
  domains: [],
  companyTypes: [],
  companySize: [],
  companyNames: [],
  selectedDomains: [],
  industries: [],
  recruiterId: null,
  companyDetailsId: null,
  persistGeneralData: null,
  persistDomains: [],
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case type.SET_COMPANY_TYPE_SUCCESS:
      return {
        ...state,
        companyTypes: action.companyTypes,
      };
    case type.SET_COMPANY_SIZE_SUCCESS:
      return {
        ...state,
        companySize: action.companySize,
      };
    case type.SET_GENERAL_SUCCESS:
      return {
        ...state,
        generalInfo: action.generalInfo,
        persistGeneralData: action.PersistGeneralData,
      };
    case type.SET_COMPANY_SUCCESS:
      return {
        ...state,
        companyPage: action.companyPage,
        companyDetailsId: action.companyDetailsId,
      };
    case type.GET_DOMAIN_SUCCESS:
      return {
        ...state,
        domains: action.domains,
      };
    case type.SET_DOMAIN_SUCCESS:
      return {
        ...state,
        selectedDomains: action.selectedDomains,
        persistDomains: action.persistDomains,
      };
    case type.SET_EMAIL_VERIFIED_SUCCESS:
      return {
        ...state,
        emailVerified: action.emailVerified,
        recruiterId: action.recruiterId,
      };
    case type.GET_COMPANY_SUCCESS:
      return {
        ...state,
        companyNames: action.companyNames,
      };
    case type.ADD_COMPANY_SUCCESS:
      return {
        ...state,
        companyName: action.companyName,
      };
    case type.GET_INDUSTRY_SUCCESS:
      return {
        ...state,
        industries: action.industries,
      };
    case type.REMOVE_PERSISTENCE:
      return {
        ...state,
        persistGeneralData: null,
        selectedDomains: [],
        companyPage:null
        
      }
    default:
      return state;
  }
}
