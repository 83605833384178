import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw } from "../../../Utils/pxToRem";

export const useStyles = makeStyles({
  section: {
    display: "flex",
    flexDirection: "column",
    gap: pxToVh(28),
    paddingLeft: pxToVw(195),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icons: {
    display: "flex",
    gap: pxToVw(16),
    paddingRight: pxToVw(195),
  },
  icon: {
    width: pxToVw(42),
    height: pxToVw(42),
    cursor: "pointer",
  },
  sectionTop: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: pxToVw(50),
  },
  heading: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(36),
    color: "#474747",
    background:
      "linear-gradient(88.03deg, #012E3E 3.33%, #1E977C 32.4%, #157665 45.08%, #20A284 60.67%, #075353 80.1%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  carousal: {
    width: "100%",
  },
});
