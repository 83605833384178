import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw } from "../../../../Utils/pxToRem";

export const useStyles = makeStyles({
  addButton: {
    display: "flex",
    alignItems: "center",
    width: pxToVw(1010),
    height: pxToVh(132),
    border: "3px solid #0EC99E",
    borderRadius: pxToVw(24),
    paddingTop: pxToVh(12),
    paddingBottom: pxToVw(21),
    paddingLeft: pxToVw(32),
    paddingRight: pxToVw(20),
    background:
      "linear-gradient(264.79deg, rgba(14, 201, 158, 0.2) -2.72%, rgba(255, 255, 255, 0.2) 34.07%)",
  },
  left: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 6.5,
    gap: pxToVh(12),
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: pxToVw(20),
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "500",
    fontSize: pxToVw(24),
    lineHeight: pxToVw(36),
    color: "#474747",
  },
  desc: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(14),
    lineHeight: pxToVw(19.8),
    color: "#9D9D9D",
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 3.5,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: pxToVw(80),
    height: pxToVw(80),
    backgroundColor: "#0EC99E33",
    border: "3px solid #0EC99E",
    borderRadius: "50%",
    cursor: "pointer",
  },
});
