import { makeStyles } from "@mui/styles";
import { pxToVw, pxToVh, pxToMvw, pxToMvh } from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";

export const useStyles = makeStyles({
  cardCarousalDiv: {
    borderRadius: pxToVw(12),
    width: "38%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      // display: "none",
    },
  },
  carousal: {
    width: "80%",
    position: "relative",
    marginLeft: pxToVw(50),
    [theme.breakpoints.down("mb")]: {
      width: "100%",
      // display: "none",
      marginLeft: pxToVw(0),
    },
  },
  cardCarousalItemContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: pxToVh(5) + "  " + pxToVw(18),
    gap: pxToVw(20),
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("mb")]: {
      // display: "none",
    },
  },
  cardCorosalItemOverlay: {
    background: "#00000033",
    display: "flex",
    height: pxToVw(218),
    flexDirection: "column",
    justifyContent: "space-between",
    width: pxToVw(202),
    cursor: "pointer",
    borderRadius: pxToVw(27),
    position: "relative",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(260),
      height: pxToMvw(320),
      padding: "0px",
      borderRadius: pxToMvw(10),
    },
  },
  cardCorosalItemContainerMobile: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: "flex",
      justifyContent: "space-evenly",
      gap: pxToMvw(8),
      height: pxToMvw(340),
      padding: "0px",
      width: pxToMvw(280),
    },
  },
  cardCarousalItem: {
    display: "flex",
    height: pxToVw(218),
    flexDirection: "column",
    justifyContent: "space-between",
    width: pxToVw(202),
    cursor: "pointer",
    borderRadius: pxToVw(27),
    backgroundColor: "beige",
    position: "relative",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(260),
      height: pxToMvw(320),
      padding: "0px",
      borderRadius: pxToMvw(10),
    },
  },
  cardCarousalItemPng: {
    // position: "absolute",
  },
  cardCarousalItemHeader: {
    display: "flex",
    padding: pxToVw(10) + " " + pxToVw(12),
    paddingLeft: pxToVw(20),
    justifyContent: "space-between",
    [theme.breakpoints.down("mb")]: {
      padding: pxToMvw(20) + " " + pxToMvw(25),
    },
  },
  cardCarousalItemIcon: {
    display: "flex",
    alignItems: "center",
    color: "white",
    fontSize: pxToVw(12),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
    },
  },
  cardCarousalItemIconSvg: {
    height: pxToVw(15),
    width: pxToVw(15),
    [theme.breakpoints.down("mb")]: {
      height: pxToMvw(15),
      width: pxToMvw(15),
    },
  },
  cardCarousalItemCompany: {
    display: "flex",
    alignItems: "center",
  },
  cardCarousalItemCompanyImg: {
    height: pxToVw(25),
    width: pxToVw(25),
    [theme.breakpoints.down("mb")]: {
      height: pxToMvw(25),
      width: pxToMvw(25),
    },
  },
  cardCarousalItemFooter: {
    padding: pxToVw(4),
    borderRadius: pxToVw(35),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [theme.breakpoints.down("mb")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
  },
  cardCarousalItemName: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "500",
    fontSize: pxToVw(24),
    color: "white",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(18),
    },
  },
  cardCarousalItemPosition: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "300",
    fontSize: pxToVw(18),
    color: "white",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(14),
      marginBottom: pxToMvw(15),
    },
  },
  cardCarousalDivMobile: {
    height: pxToMvw(350),
    width: pxToMvw(425),
    marginTop: pxToMvh(15),
    overflow: "hidden",
    margin: "4px -1px",
    display: "none",
    [theme.breakpoints.down("mb")]: {
      display: "block",
    },
    "& *.slider-content": {
      boxShadow: "none",
    },

    "& .slider-single-content": {
      boxShadow: "none !important",
    },

    "& .react-3d-carousel": {
      boxShadow: "none !important",
    },

    "& .slider-right": {
      padding: "20px 35px !important",
    },
    "& .slider-left": {
      padding: "20px 35px !important",
    },
    "& .slider-right > div": {
      display: "hidden",
      border: "none !important",
    },
    "& .slider-left > div": {
      display: "hidden",
      border: "none !important",
    },
    "& .proactive > .slider-single-content ": {
      opacity: "1 !important",
      transform: "translateX(98%) scale(0.9) !important",
      transition: "500ms cubic-bezier(0.17, 0.67, 0.55, 1) !important",
    },
    "& .preactive > .slider-single-content ": {
      transition: "500ms cubic-bezier(0.17, 0.67, 0.55, 1) !important",
      transform: "translateX(-98%) scale(0.9) !important",
      opacity: "1 !important",
    },
  },
  cardCarousal3DContainer: {
    height: pxToMvw(430),
    width: pxToMvw(430),
  },
  leftArrow: {
    position: "absolute",
    left: pxToVw(-50),
    top: "45%",
    zIndex: 2,
    cursor: "pointer",
    height: pxToVw(45),
    width: pxToVw(45),
    borderRadius: "50%",
    boxShadow: "2px 0.3333333134651184px 4.3333330154418945px -1px #AFAFAF",
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  rightArrow: {
    position: "absolute",
    right: pxToVw(-50),
    top: "45%",
    zIndex: 2,
    height: pxToVw(45),
    width: pxToVw(45),
    borderRadius: "50%",
    cursor: "pointer",
    boxShadow: "2px 0.3333333134651184px 4.3333330154418945px -1px #AFAFAF",
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
});
