/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { useStyles } from "./styles";
import { ReactComponent as TextAreaIcon } from "../../../Assets/images/RecruiterOnboard/Company_Page/TextArea.svg";
import { makeStyles } from "@mui/styles";
import { pxToVw } from "../../../Utils/pxToRem";
import TextAreaAutoSize from "react-textarea-autosize";

const TextArea = (props) => {
  const styles = useStyles();

  const textContainer = useRef();
  const focusRef = useRef();
  const [text, setText] = useState(0);
  const [foc, setFoc] = useState(false);

  // useEffect(() => {
  //     // focusRef.current.style.height = pxToVw(35);

  //     focusRef.current.addEventListener("keydown", autoResize);

  // }, [focusRef.current])

  const autoResize = () => {
    console.log("Resizing");
    var el = focusRef.current;
    setTimeout(function () {
      el.style.height = "auto";

      el.style.height = pxToVw(el.scrollHeight);
    }, 0);
  };

  const setFocus = () => {
    setFoc(true);
    focusRef.current.focus();
  };

  const status = () => {
    if (!props.valid && props.touched) {
      return "2px solid rgba(244, 67, 54, 1)";
    }
    if (props.valid && props.touched) {
      return "2px solid #0EC99E";
    } else {
      return "";
    }
  };

  const iconStatus = () => {
    if (!props.valid && props.touched) {
      return "rgba(169, 169, 169, 1)";
    }
    if (props.valid && props.touched) {
      return "rgba(84, 84, 84, 1)";
    } else {
      return "";
    }
  };

  const textHandler = (e) => {
    props.onchange(e);
    setText(e.target.value.length);
  };

  const dynamicStyle = makeStyles({
    iconContainer: {
      "& svg": {
        fill: iconStatus(),
      },
    },
  })();

  return (
    <div className={styles.textWrapper}>
      <div
        ref={textContainer}
        className={`${styles.textAreaAutoContainer} ${dynamicStyle.iconContainer}`}
        style={{ border: status() }}
        onClick={setFocus}
      >
        {props.icon}
        {/* <textarea maxLength={props?.limit || 3000}  autoComplete="off" type={"text"} onBlur={() => setFoc(false)} ref={focusRef} className={styles.inputTextArea} placeholder={props.placeholder} value={props.value} onChange={textHandler}>
                    </textarea> */}
        <TextAreaAutoSize
          maxRows={6}
          maxLength={props?.limit || 3000}
          type="text"
          onBlur={() => setFoc(false)}
          ref={focusRef}
          className={styles.inputTextArea}
          placeholder={props.placeholder}
          value={props.value}
          onChange={textHandler}
        />
      </div>

      {props.note && foc && (
        <span className={styles.noteContainer}>
          <p>
            {text}/{props.limit}
          </p>
        </span>
      )}
    </div>
  );
};

export default TextArea;
