import React from "react";
import { useSelector } from "react-redux";
import ShareModal from "../../ExploreCareersPage/Modal/Share/ShareModal";

const NestedModalManager = () => {
  const open = useSelector((state) => state.modal.nestedModal);
  const modalType = useSelector((state) => state.modal.nestedModalType);

  let Modal = null;

  switch (modalType) {
    case "SHAREMODAL":
      Modal = <ShareModal open={open} />;
      break;
    default:
      Modal = null;
      break;
  }
  return <>{Modal}</>;
};

export default NestedModalManager;
