import React from "react";
import { useStyles } from "./Styles";
import { FaUserFriends } from "react-icons/fa";

const MetricsCard = () => {
  const classes = useStyles();
  return (
    <div className={classes.MetricsCard}>
      <div className={classes.MetricsCardWrapper}>
        {" "}
        <div>
          <div className={classes.MetricsCardFollower}>Followers</div>
          <FaUserFriends style={{ color: "#c7c6c5" }} />
        </div>
        <div className={classes.MetricsCardFollowerNumber}>23.5K</div>
      </div>
    </div>
  );
};

export default MetricsCard;
