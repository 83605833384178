/*eslint no-unused-vars:*/
import { pxToVw, pxToMvw, pxToVh, pxToMvh } from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";
import { makeStyles } from "@mui/styles";

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "90%",
    height: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    border: "none",
    transition: "all .3s ease",
    color: "#545454",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: pxToVw(45),
    height: pxToVw(35),
    cursor: "pointer",
    color: state.isFocused ? "#0EC99E" : "#1E433B",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(35),
      height: pxToMvw(45),
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    display: state.selectProps.error && "none",
    width: pxToVw(610),
    backgroundColor: "white",
    borderRadius: pxToVw(40),
    border: "2px solid #0EC99E",
    color: "#545454",
    paddingLeft: pxToVw(30),
    paddingRight: pxToVw(30),
    marginTop: pxToVh(30),
    marginRight: pxToVw(35),
    webkitScrollbar: {
      display: "none",
    },
    "&:focus-within": {
      border: "2px solid #0EC99E",
    },
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(390),
      marginTop: pxToMvh(10),
      marginRight: pxToMvw(10),
      borderRadius: pxToMvw(16),
      border: "1px solid #0EC99E",
      "&:focus-within": {
        border: "1px solid #0EC99E",
      },
    },
  }),
  menuList: (provided, state) => ({
    ...provided,
    display: "flex",
    flexDirection: "column",
    gap: pxToVw(10),
    "::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvh(10),
    },
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "transparent",
    },
  }),
  option: () => ({
    width: "100%",
    cursor: "pointer",
    color: "#545454",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    [theme.breakpoints.down("sm")]: {
      borderRadius: pxToMvw(12),
      fontSize: pxToMvw(14),
    },
  }),
  group: (provided) => ({
    ...provided,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: "100%",
    height: "100%",
    display: "flex",
    outline: "none",
    border: "none",
    transition: "all .3s ease",
    color: "#545454",
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: "100%",
    height: "100%",
    overflow: "scroll",
    overflowX: "hidden",
    // "&::-webkit-scrollbar": {
    //   display: "none",
    // },
  }),
  input: (provided) => ({
    ...provided,
    color: "#545454",
  }),
  multiValue: (provided) => {
    return {
      ...provided,
      fontSize: pxToVw(12),
      backgroundColor: "#E3FFF9",
      paddingRight: pxToVw(8),
      paddingTop: pxToVw(3),
      paddingBottom: pxToVw(3),
      paddingLeft: pxToVw(8),
      borderRadius: pxToVw(40),
      marginRight: pxToVw(10),
      border: "2px solid #0EC99E80",
      [theme.breakpoints.down("sm")]: {
        fontSize: pxToMvw(12),
        paddingRight: pxToMvw(8),
        paddingTop: pxToMvw(3),
        paddingBottom: pxToMvw(3),
        paddingLeft: pxToMvw(8),
        borderRadius: pxToMvw(40),
        marginRight: pxToMvw(10),
      },
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "#545454";

    return { ...provided, color, opacity, transition };
  },
};

export const useStyles = makeStyles({
  inputContainer: {
    display: "flex",
    alignItems: "center",
    width: pxToVw(620),
    height: pxToVw(80),
    maxHeight: "fit-content",
    backgroundColor: "white",
    borderRadius: pxToVw(40),
    boxShadow: "0 10px 20px 0 #0EC99E12",
    paddingLeft: pxToVw(47),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(400),
      height: pxToMvw(52),
      paddingLeft: pxToMvw(20),
      borderRadius: pxToMvw(40),
    },
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    width: pxToVw(620),
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(400),
    },
  },
  // placeholder: {
  //   width: "80%",
  //   marginLeft: pxToVw(20),
  //   color: "#9D9D9D",
  //   fontFamily: "Poppins,sans-serif",
  //   fontSize: pxToVw(18),
  //   fontWeight: 400,
  //   letterSpacing: 0,
  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: pxToMvw(12),
  //   },
  // },
  inputIcon: {
    width: pxToVw(24),
    height: pxToVw(24),

    "& svg": {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(16),
      height: pxToMvw(16),

      "& svg": {
        width: "100%",
        height: "100%",
      },
    },
  },
});
