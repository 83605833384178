import React from "react";
import { pxToVw } from "../../../../Utils/pxToRem";
import Tag from "../../../Shared/Tag/Tag";
import { useStyles } from "./Styles";
import { ReactComponent as Link } from "../../../../Assets/images/ProfilePage/Section/Card/link.svg";

const Card = ({
  title,
  subtitle,
  date,
  time,
  img,
  titleTag,
  tags,
  acheivement,
  acheivementTagText,
  grade,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div className={classes.top}>
        <div className={classes.topWrapper}>
          <div className={classes.titleWrapper}>
            {title && <p className={classes.title}>{title}</p>}
            {titleTag && (
              <Tag
                text={titleTag}
                bg="white"
                textbg="#0EC99E"
                border="1px solid #0EC99E"
              />
            )}
          </div>
          {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
        </div>
        {img && <img src={img} className={classes.image} />}
      </div>
      <div className={classes.bottom}>
        {date && (
          <div className={classes.dateWrapper}>
            {date && <p className={classes.date}>{date}</p>}
            {time && <p className={classes.time}>{time}</p>}
          </div>
        )}
        {tags && (
          <div className={classes.tagsContainer}>
            <Tag text="User Research" bg="#0EC99E26" textbg="#0EC99E" />
            <Tag text="Prototyping" bg="#0EC99E26" textbg="#0EC99E" />
            <Tag text="Wireframing" bg="#0EC99E26" textbg="#0EC99E" />
            <Tag text="+2" bg="#0EC99E26" textbg="#0EC99E" />
          </div>
        )}
        {acheivement && (
          <div className={classes.acheivements}>
            <p className={classes.acheivementText}>{acheivement}</p>
            <Tag
              text={acheivementTagText}
              bg="white"
              textbg="#0EC99E"
              border="1px solid #0EC99E"
            />
          </div>
        )}
        {grade && (
          <p className={classes.acheivementText}>
            Grade: <span style={{ color: "black" }}>{grade}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Card;
