import React from "react";
import { ReactComponent as Hand } from "../../../Assets/images/LandingPage/Section/hand.svg";
import { useStyles } from "./Styles";

const MobileSectionText = ({ item }) => {
  const classes = useStyles();
  return (
    <div className={classes.details}>
      <h3 className={classes.title}>{item.title}</h3>
      <p className={classes.subtitle}>{item.subtitle}</p>
      <div className={classes.listWrapper}>
        {item.list.map((l, id) => (
          <div key={id} className={classes.handWrapper}>
            <div>
              <Hand className={classes.hand} />
            </div>
            <p className={classes.list}>{l}</p>
          </div>
        ))}
      </div>
      <button className={classes.button}>{item.buttontext}</button>
      <div className={classes.mobileImageWrapper}>
        <img src={item.img} className={classes.mobileImage} />
      </div>
    </div>
  );
};

export default MobileSectionText;
