import React from "react";
import { useStyles } from "./Styles";
const AboutSection = () => {
  const classes = useStyles();
  return (
    <div className={classes.About__Wrapper}>
      <div className={classes.About__Header}>About</div>
      <div className={classes.About__Description}>
        <p>
          {" "}
          I love solving problems. Be it counselling a heart-broken friend or
          breaking my head to build a complex UX flow - I do them all. I am an
          Engineer from the prestigious Indian Institute of Technology Madras
          but I chose Digital Experience Designin...
          <b className={classes.About__SeeMore}>See more</b>
        </p>{" "}
      </div>
    </div>
  );
};

export default AboutSection;
