import { makeStyles } from "@mui/styles";
import { pxToMvw, pxToRem, pxToVh, pxToVw } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const useStyles = makeStyles({
    container: {
        // height: "100vh",    
        alignSelf: "center",
        display: "flex",
        justifyContent: "center",
      
        
    },
    formWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        "& h1": {
            fontSize: pxToRem(48),
            background: "-webkit-linear-gradient(#00634C, #009270)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily:"Poppins,sans-serif",
            fontWeight: 600,
            letterSpacing: "-1.5px",
            margin: 0,
            lineHeight:pxToVh(86),
            textAlign: "center",
            marginTop: pxToVh(52),
            marginBottom: pxToVh(36),
            [theme.breakpoints.down("sm")]: {
                fontSize: pxToMvw(24),
                marginBottom:pxToMvw(20)
            }
        }
    },
    inputs: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: pxToVh(24),
        marginBottom:pxToVw(32)
    },
    loginContainer: {
        //   width: pxToVw(258),
        // height: pxToVw(80),

        background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
        // margin: `${pxToVh(0)} auto ${pxToVh(32)} auto`,
        borderRadius: pxToVw(40),

        //  [theme.breakpoints.down("mb")]: {
            // width: pxToMvw(124),
            // height: pxToMvw(48),
            //  borderRadius: pxToMvw(40),
            //  display: "flex",
            //  alignItems: "center",
            //  justifyContent: "center",
             
        // },
         [theme.breakpoints.down("sm")]: {
            //  fontSize: pxToMvw(12),
            //  padding: `${pxToMvw(30)} ${pxToMvw(58)}`,
            //  width:"unset"
        },
    },
    successNote: {
        color: "rgba(14, 201, 158, 1)",
        fontSize: pxToRem(16),
        marginBottom:pxToVw(24)
    },
    errorNote:{
        color: "#F44336",
        fontSize: pxToRem(16),
        marginBottom:pxToVw(24)
    },
    btnLogin: {
        color: "white",
        textDecoration: "none",
        fontSize: pxToRem(18),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight:400,
        border: "none",
        background: "transparent",
        fontFamily: "Poppins,sans-serif",
        cursor: "pointer",
        borderRadius: pxToRem(40),
         padding: `${pxToVw(24)} ${pxToVw(46)}`,
         [theme.breakpoints.down("sm")]: {
             fontSize: pxToMvw(12),
             padding: `${pxToMvw(24)} ${pxToMvw(46)}`,
             marginTop:pxToMvw(32)
        },
        // width: "223px"
    },
    disabledSubmit: {
        background: "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), #0EC99E",
        border: "none",
        cursor: "no-drop",
        
        [theme.breakpoints.down("sm")]: {
            // width: pxToMvw(131),
            // height: pxToMvw(48),
            borderRadius: pxToMvw(40),
            fontSize: pxToMvw(12),
            // padding: `${pxToMvw(30)} ${pxToMvw(58)}`,
            // width:"unset"

        }
    },
    enabledSubmit: {
        background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
        border:"2px solid #0EC99E",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            borderRadius: pxToMvw(40),
            fontSize: pxToMvw(12),
            // padding: `${pxToMvw(30)} ${pxToMvw(58)}`,
            // width:"unset"

        }
    },
    contentWrapper:{
        display:"flex",
        flexDirection:"column",
        gap: pxToVw(32),
        alignItems:"center",
        marginTop: pxToVw(129),
        [theme.breakpoints.down("sm")]: {
            gap:pxToMvw(20)
        }
    },
    problemNote:{
        background: "linear-gradient(90.08deg, #9D9D9D 32.91%, #999999 90.43%)",
        textAlign: "center",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontSize: pxToVw(18),
        [theme.breakpoints.down("sm")]: {
            fontSize:pxToMvw(12)
        }
    },
    content:{
        color:"#9D9D9D",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        gap:pxToVw(20),
        fontSize: pxToVw(28),
        width: "70%",
        margin: "0 auto",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: pxToMvw(14),
            gap: pxToMvw(20),
            width:"85%"
        }
    },
    resetText: {
        fontWeight: 500,
        background: "linear-gradient(to right,rgba(1, 110, 85, 1) 30%, rgba(1, 143, 110, 1))",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        textAlign: "center",
        marginBottom: pxToVh(10),
        marginTop: pxToVh(30),
        [theme.breakpoints.down("sm")]: {
            fontSize:pxToMvw(12)
        }
        
    },
    resetBtn: {
        color: "white",
        textDecoration: "none",
        fontSize: pxToRem(18),
        fontWeight:400,
        border: "none",
        fontFamily: "Poppins,sans-serif",
        cursor: "pointer",
        borderRadius: pxToRem(40),
        padding: `${pxToVw(24)} ${pxToVw(46)}`,
        background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
         [theme.breakpoints.down("sm")]: {
             fontSize: pxToMvw(12),
             padding: `${pxToMvw(24)} ${pxToMvw(46)}`,
            borderRadius: pxToMvw(40),

            //  marginTop:pxToMvw(32)
        },
    },
    note: {
        color: "rgba(157, 157, 157, 1)",
        fontSize: pxToRem(18),
        lineHeight: pxToVh(32),
        marginBottom: pxToVh(36),
        [theme.breakpoints.down("sm")]: {
            fontSize: pxToMvw(14),
            textAlign:"center"
        }
    }
    
})