import { makeStyles } from "@mui/styles";
import { theme } from "../../Utils/breakpoints";

export const useStyles = makeStyles({
  landingpage: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    backgroundColor: "#131313",
  },
  [theme.breakpoints.down("mb")]: {},
});
