import React from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./Styles";

const Auth = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.auth}>
      {/* <div className={classes.inputWrapper}>
        <input
          className={classes.input}
          placeholder="yesyoucandoit@iamproudofyou.com"
        />
        <button className={classes.button}>Get Started</button>
      </div> */}
      <button
        className={classes.button}
        onClick={() => {
          navigate("/studentregister");
        }}
      >
        Get Started
      </button>
    </div>
  );
};

export default Auth;
