import { makeStyles } from "@mui/styles";
import { theme } from "../../../Utils/breakpoints";
import {
  pxToMvh,
  pxToMvw,
  pxToRem,
  pxToVh,
  pxToVw,
} from "../../../Utils/pxToRem";

export const useStyles = makeStyles({
  studentLogin: {},
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    height: "92vh",
    gap: pxToVh(36),
    paddingTop: pxToVh(52),
    position: "relative",
    background: "#F6F6F6",
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvh(10),
      paddingTop: pxToMvh(32),
      height: "94vh",
    },
  },
  left: {
    position: "absolute",
    width: "30%",
    bottom: 0,
    left: 0,
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  right: {
    position: "absolute",
    width: "25%",
    top: -50,
    right: 0,
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: pxToVh(20),
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(48),
    lineHeight: pxToVw(86),
    background: "linear-gradient(89.61deg, #00634C -19%, #009270 92.23%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(32),
      lineHeight: pxToMvw(86),
    },
  },
  inputWrappers: {
    display: "flex",
    flexDirection: "column",
    gap: pxToVw(24),
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvh(8),
    },
  },
  button: {
    width: pxToVw(140),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    border: (props) => (props.disabled ? "none" : "2px solid #0EC99E"),
    background: (props) =>
      props.disabled
        ? "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), linear-gradient(0deg, #0EC99E, #0EC99E)"
        : "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%),linear-gradient(0deg, #0EC99E, #0EC99E)",
    color: "white",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    cursor: (props) => (props.disabled ? "not-allowed" : "pointer"),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(106),
      height: pxToMvw(48),
      borderRadius: pxToMvw(40),
      fontSize: pxToMvw(12),
    },
  },
  forgot: {
    fontWeight: 500,
    background:
      "linear-gradient(to right,rgba(1, 110, 85, 1) 30%, rgba(1, 143, 110, 1))",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
    marginBottom: pxToVh(31),
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    },
  },
  bottomOptions: {
    display: "flex",
    alignItems: "center",
    gap: pxToVw(25),
    "& span": {
      fontWeight: 400,
      background:
        "linear-gradient(to right,rgba(1, 110, 85, 1) 50%, rgba(1, 143, 110, 1))",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textAlign: "center",
      fontSize: pxToRem(18),
      fontFamily: "Poppins,sans-serif",
      [theme.breakpoints.down("sm")]: {
        fontSize: pxToMvw(12),
      },
    },
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvw(20),
    },
  },
  or: {
    color: "rgba(208, 208, 208, 1)",
    fontSize: pxToVw(18),
    marginBottom: pxToVh(15),
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    },
  },
  signUp: {
    width: pxToVw(160),
    height: pxToVw(80),
    borderRadius: pxToRem(40),
    backgroundColor: "rgba(227, 255, 249, 1)",
    border: "2px solid rgba(14, 201, 158, 1)",
    fontSize: pxToRem(18),
    fontFamily: "Poppins,sans-serif",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(106),
      height: pxToMvw(48),
      borderRadius: pxToMvw(40),
      fontSize: pxToMvw(12),
    },
  },
  forgotPasswordWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});
