/* eslint-disable no-unused-vars */
// import Input from "../../../Shared/input/index";
import { useState , useEffect, useRef, useMemo } from "react";
import React from "react";
import { useStyles } from "./styles";
import FacebookCircularProgress from "../../../Shared/Loader/index";
import { ReactComponent as Checked } from "../../../../Assets/images/RecruiterOnboard/Verify_Email/checked.svg";
import { useSelector, useDispatch } from "react-redux";
import { verifyEmailApi , otpTimerExpired, resendOtpApi} from "../../../../Services/lib/RecruiterOnboarding";
import * as types from "../../../../redux/types";


const VerifyEmail = (props) => {

    const [loading, setLoading] = useState(false);
    // const [verify, setVerify] = useState(false);
    const [btnValid, setValid] = useState(false);
    const [sendCount, setSendCount] = useState(1);
    const [timeOver,setTimeOver] = useState(false);
    const dispatch = useDispatch();
    const inputRef = useRef();
    const recruiterEmail = useSelector(state => state.RecruiterOnboard.generalInfo.companyEmailId);
    const verifyEmailState = useSelector(state => state.RecruiterOnboard.emailVerified);
    const [inValidOtp, setInvalidOtp] = useState(false);
    const styles = useStyles({inValidOtp});

    useEffect(() => {
        // console.log(inputRef.current.childNodes);
        var inputElements = inputRef.current.childNodes;
        inputElements.forEach((ele, index) => {
            ele.addEventListener("keydown", (e) => {
                if (e.keyCode === 8 && e.target.value === "") {
                    inputElements[Math.max(0, index - 1)].focus();
                }
            })

        })
           
            // ele.addEventListener("input", (e) => {
            //     const [first, ...rest] = e.target.value;
            //     const re = /^[0-9]/;
            //     console.log(index);
            //     if (!re.test(first)) {
            //         e.target.value = ""; 
            //     } else {
            //     e.target.value = first;

            //     }
            //     const lastInputBox = index === inputElements.length - 1;
            //     const insertedContent = first != undefined;
                
            //     if (!lastInputBox && insertedContent && re.test(first)) {

            //         inputElements[index + 1].focus();
            //         // console.log(rest);
            //         inputElements[index + 1].value = rest.join("")
            //         inputElements[index + 1].dispatchEvent(new Event("input"));

            //     }
            // })
        // })
    }, [inputRef.current])


    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(59);

    useEffect(() => {

        let Interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds == 0) {
                if (minutes == 0) {
                    clearInterval(Interval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000); 
        return () => {
            clearInterval(Interval);
            
        }
    })

    useEffect(() => {

       
        
             const email = {
            companyEmailId:recruiterEmail
        };
         let TimeOut = setTimeout(() => {
            otpTimerExpired(email).then(res => {
                console.log(res);
                if (sendCount == 2) {
                    setTimeOver(true);
                }
            })
         }, 120000)   
        
        return () => {
            clearTimeout(TimeOut)
        }
    },[sendCount])

    const Next = () => {
        
        console.log(generalForm);
        let codeOtp = [];
        for (let otp in generalForm) {
            // console.log(generalForm[otp]);
            codeOtp.push(generalForm[otp]);
        }
        // console.log(codeOtp.join(""));
        setLoading(true);
        let otpData = {
            companyEmailId: recruiterEmail,
            otp:parseInt(codeOtp.join(""))
        }
        console.log(otpData);
        verifyEmailApi(otpData).then(res => {
            console.log(res);
            if (res.res.data.success) {
                setLoading(false);
                dispatch({ type: types.SET_EMAIL_VERIFIED_SUCCESS, emailVerified: true, recruiterId: res.res.data.recruiterId });
            
                setTimeout(() => {
                    props.setCurrent(2);
                props.setStep(2);
                props.setCompleted(step => [...step, 1]);
                },1000)
            } else if (res.res.data.message == "Wrong OTP") {
                setInvalidOtp(true);
                setLoading(false);
                dispatch({ type: types.SET_EMAIL_VERIFIED_FAIL, emailVerified: false })
                    

            }  else {
                setLoading(false);
                dispatch({ type: types.SET_EMAIL_VERIFIED_FAIL, emailVerified: false })
            }
        })

    }
    const [generalForm,setGeneralForm] = useState({
        otp1: "",
        otp2:"",
        otp3: "",
        otp4: ""
    });
  
    const inputOtp = (e, id, index) => {

        var inputElements = inputRef.current.childNodes;
        if (e.keyCode === 8 && e.target.value === "") {
            inputElements[Math.max(0, index - 1)].focus();
        }   
         const lastInputBox = index === inputElements.length - 1;
        const insertedContent = e.target.value != undefined;
        
          
        const re = /^[0-9]/;

        if (!re.test(e.target.value)) {
            e.target.value = "";
        }
        
        if (!lastInputBox && insertedContent && re.test(e.target.value)) {
            inputElements[index + 1].focus();
        }
        
        setGeneralForm(prev => {
            
            return ({
                ...prev,
                [id]:e.target.value
            })
        })

    }

    const checkValidity = () => {
            let valid = true;
            for (let i in generalForm) {
                valid = generalForm[i] && valid;
        }
        setValid(valid);
    }

    const VerifyCode = () => {
        if (minutes === 0 && seconds === 0 && sendCount < 2) {
            return (
                <span onClick={resendOtp} style={{ cursor: "pointer" }} className={styles.timer}>Resend</span>
            )
        } 
        
        else if (minutes == 0 && seconds == 0 && sendCount >= 2) {
            
            return <span>Still facing issue? please contact support@glosity.club</span>;
            
        }
        else {
            return (
                <span className={styles.timer}>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
            )
        }
    }



    const resendOtp = () => {

        setGeneralForm(prev => {
                prev["otp1"] = "";
                prev["otp2"] = "";
                prev["otp3"] = "";
                prev["otp4"] = "";

                return ({
                    ...prev
                })
        })
        
         const resendOtpObj = {
            companyEmailId:recruiterEmail,
            sendCount:sendCount
        }


        if (sendCount <= 2) {
            setMinutes(1);
            setSeconds(59);
            setInvalidOtp(false);
            setSendCount(prev => prev + 1);
            resendOtpApi(resendOtpObj).then(res => {
            console.log(res);
            if (res.res.data.success) {
                // setSendCount(res.res.data.user.sendCount);
            } else {
                console.log("Not Available",res);
            }
        }).catch(err => {
            console.log(err);
        })
            return;
        }

        

       
        
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>Verify your Email Address</h1>
            <div className={styles.note}>We have just sent a verification code to {recruiterEmail}</div>

            <div className={styles.codeContainer} ref={inputRef}>

                <input name="otp1" onKeyUp={checkValidity}  onChange={(e) => inputOtp(e,"otp1",0)} value={generalForm.otp1} className={styles.code} type="" required maxLength={1} />
                <input name="otp2" onKeyUp={checkValidity} onChange={(e) => inputOtp(e,"otp2",1)} value={generalForm.otp2} className={styles.code} type="" required maxLength={1} />
                <input name="otp3" onKeyUp={checkValidity} onChange={(e) => inputOtp(e,"otp3",2)} value={generalForm.otp3} className={styles.code} type="" required maxLength={1} />
                <input name="otp4" onKeyUp={checkValidity} onChange={(e) => inputOtp(e,"otp4",3)} value={generalForm.otp4} className={styles.code} type="" required maxLength={1} />
            </div>
            
            {!verifyEmailState ?
                (
                    <>
                        {!loading ?
                <button disabled={!btnValid} className={`${styles.submit} ${btnValid ? styles.enableSubmit : styles.disableSubmit}`} onClick={Next}>Verify Email
                    
                    </button> :
                        <div className={styles.loadingState}>
                            <FacebookCircularProgress />
                        </div>
            }
                    </>
                ) :
            <button className={styles.emailVerified}>
                <div className={styles.checked}>
                    <Checked />
                </div>
                  <span>Email Verified</span>
                </button>}
            <div className={styles.timerContainer}>
                <h1>
                    {inValidOtp ? <span className={styles.invalidOtpMessage}>Invalid code entered</span> : <>
                        {!timeOver && <>Didn’t recieve verification code?</>}
                    </>} {VerifyCode()}
                </h1>
                
            </div>

        </div>
    )
}

export default VerifyEmail;
