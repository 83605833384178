import { makeStyles } from "@mui/styles";
import { pxToVw, pxToVh, pxToRem, pxToMvw, pxToMvh } from "../../Utils/pxToRem";
import { theme } from "../../Utils/breakpoints";

export const useStyles = makeStyles({
  roadmapPage: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#fff",
    msOverFlowStyle: "none" /* IE and Edge */,
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
      width: "0px",
    },
  },
  roadmapPageWrapper: {
    paddingLeft: pxToVw(200),
    paddingRight: pxToVw(200),
    height: "100%",
    paddingTop: pxToVh(65),
    [theme.breakpoints.down("mb")]: {
      paddingTop: pxToMvh(25),
      paddingLeft: pxToMvw(20),
      paddingRight: pxToMvw(25),
    },
  },
  roadmapHeading: {
    display: "flex",
    alignItems: "center",
    color: "#0D1C2E",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "700",
    fontSize: pxToVw(40),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(20),
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  cardCompanyDiv: {
    display: "flex",
    "&::hover": {
      zIndex: "1",
    },
  },
  cardCompanyIcon: {
    zIndex: "1",
    display: "inline-block",
    position: "relative",
    marginRight: "-" + pxToVw(10),
    height: pxToVw(33),
    width: pxToVw(33),
    borderRadius: "50%",
    [theme.breakpoints.down("mb")]: {
      marginRight: "-" + pxToMvw(10),
      height: pxToMvw(30),
      width: pxToMvw(30),
    },
    "&::hover": {
      zIndex: "1",
    },
  },
  roadmapHeadingCompanyDetailsWrapper: {
    display: "flex",
    marginLeft: pxToVw(50),
    [theme.breakpoints.down("mb")]: {
      marginLeft: pxToMvw(0),
      marginTop: pxToMvw(24),
    },
  },
  roadmapHeadingCompanyTitle: {
    fontFamily: "Poppins",
    fontSize: pxToVw(14),
    fontWeight: "450",
    lineHeight: "21px",
    letterSpacing: "0.15000000596046448px",
    marginLeft: pxToVw(20),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(10),
      marginLeft: pxToMvw(20),
      paddingTop: "2px",
    },
  },
  roadmapContentWrapper: {
    display: "flex",
    [theme.breakpoints.down("mb")]: {
      flexDirection: "column-reverse",
    },
  },
  roadmapSvgText: {
    fontFamily: "Poppins",
    fontSize: "85px",
    fontWeight: "700",
    lineHeight: "144px",
    letterSpacing: "0.01em",
    textAlign: "center",
  },
  numberSvg: {
    margin: pxToVw(7) + " " + pxToVw(15),
  },
  numberFontLast: {
    marginRight: pxToVw(16),
    marginBottom: pxToVw(12),
    "& svg ": {
      height: pxToVw(60),
      width: pxToVw(60),
    },
    height: pxToVw(40),
    width: pxToVw(40),
  },
  numberFont: {
    fontFamily: "Poppins",
    fontSize: pxToVw(85),
    color: "white",
    WebkitTextStrokeWidth: "1px",
    WebkitTextStrokeColor: "#0EC99E",
    textShadow:
      "linear-gradient(56.87deg, #0EC99E 54.99%, #FFFFFF 88.29%),linear-gradient(0deg, #000000, #000000)",
    fontWeight: "700",
    lineHeight: pxToVw(144),
    letterSpacing: "0.01em",
    textAlign: "center",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(42),
    },
  },
  Cards: {
    marginTop: pxToVh(60),
    paddingBottom: pxToVh(10),
    display: "flex",
    flexDirection: "column",
    gap: pxToVw(21),
    [theme.breakpoints.down("mb")]: {
      marginTop: pxToMvw(30),
      gap: pxToMvw(21),
    },

    "& .MuiAccordionSummary-root": {
      padding: "0 " + pxToVw(10),

      height: pxToVh(160),
      [theme.breakpoints.down("mb")]: {
        padding: "0 " + pxToMvw(20),
        height: pxToMvh(74),
      },
    },
    "& .MuiAccordion-root": {
      borderRadius: pxToVw(15),
      padding: "0 " + pxToVw(45),
      boxShadow: "rgba(0, 0, 0, 0.08) 0px 3px 10px",
      width: pxToVw(932),
      [theme.breakpoints.down("mb")]: {
        width: "100%",
      },
    },
    "& .MuiAccordion-root::before": {
      height: "0px",
    },
    "& > ul li:not(first-child) > :first-child > :first-child ": {
      WebkitFlexGrow: "0",
      height: pxToVh(80),
      [theme.breakpoints.down("mb")]: {
        height: pxToMvh(35),
      },
    },
    "& ul > :first-child > :first-child > :nth-child(2) ": {
      backgroundColor: "#0EC99E",
    },
    "& ul > :first-child > :first-child > :first-child ": {
      visibility: "hidden",
    },
    "& ul > :last-child > :first-child > :last-child ": {
      visibility: "hidden",
    },
    "& ul li > :first-child :nth-child(2) ": {
      margin: "0px",
    },
    "& .MuiTimelineContent-root": {
      paddingLeft: pxToVw(60),
      [theme.breakpoints.down("mb")]: {
        paddingRight: "0px",
      },
    },
    "& ul ": {
      padding: "0px",
    },
    "& ul li::before": {
      padding: "0px",

      flex: "0",
      webkitFlex: "0",
      [theme.breakpoints.down("mb")]: {
        flex: "0",
        webkitFlex: "0",
      },
    },
  },
  insideAccordion: {
    display: "flex",
    marginLeft: pxToVw(35),
    marginBottom: pxToVh(25),
    [theme.breakpoints.down("mb")]: {
      marginLeft: pxToMvw(0),
      marginBottom: pxToMvh(25),
    },
    "& ul > :first-child > :first-child > :first-child": {
      display: "block",
      height: pxToVh(18),
      [theme.breakpoints.down("mb")]: {
        height: pxToVh(6),
      },
    },
    "& ul > :first-child > :nth-child(2) ": {
      display: "flex",
      paddingLeft: pxToVw(34),
      alignItems: "center",
      color: "#AFAFAF",
    },

    "& > ul li:not(first-child) > :first-child > :first-child ": {
      height: pxToVh(80),
      display: "none",
    },
    "& ul > :first-child > :first-child > :last-child ": {
      width: pxToRem(2.5),
      [theme.breakpoints.down("mb")]: {
        flexGrow: "0.75",
        width: "0.5px",
        marginRight: "1px",
      },
    },
    "& ul > :first-child > :first-child > :nth-child(2) ": {
      backgroundColor: "#0EC99E",
      height: pxToRem(1),
      width: pxToRem(1),
      padding: pxToRem(2),
      [theme.breakpoints.down("mb")]: {
        padding: pxToRem(0),
        marginLeft: "-1px",
      },
    },
    "& ul > li:not(:first-child) > :first-child > :last-child": {
      [theme.breakpoints.down("mb")]: {
        visibility: "hidden",
      },
    },
    "& ul li:not(:first-child) :nth-child(2) svg": {
      marginTop: "-15px",
      [theme.breakpoints.down("mb")]: {
        width: pxToMvw(30),
        height: pxToMvw(30),
        marginTop: "3px",
      },
    },
    "& ul li:not(:first-child) :nth-child(2)": {
      display: "flex",
      alignItems: "center",
      background: "transparent",
      boxShadow: "none",
      padding: "0px",
      margin: "0px",
      border: "0px",
      marginLeft: pxToVw(3.35),
      marginTop: "-10px",
    },
    "& ul li > :first-child :not(:first-child) > :nth-child(2) ": {
      padding: pxToRem(2),
      border: "0px",
      boxShadow: "none",
    },

    "& ul > :first-child": {
      // paddingBottom: "1rem",
    },
    "& ul li": {
      minHeight: pxToVh(30),
    },
    "& ul > :last-child > :first-child > :last-child ": {
      height: pxToRem(1),
      width: pxToRem(1),
      padding: pxToRem(4),
      visibility: "hidden",
    },
  },
  insideAccordionHeading: {
    fontFamily: "Poppins",
    fontSize: pxToVw(16),
    fontWeight: "400",
    letterSpacing: "0.125em",
    textAlign: "left",
    minHeight: pxToVh(55),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
      minHeight: pxToMvh(20),
      lineHeight: "3px",
      marginLeft: pxToMvw(10),
    },
  },
  CardHeadingBold: {
    fontFamily: "Poppins",
    fontSize: pxToVw(22),
    fontWeight: "600",
    letterSpacing: "0.125em",
    textAlign: "left",
    minHeight: pxToVh(55),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
      minHeight: pxToMvh(20),
      lineHeight: "3px",
      marginLeft: pxToMvw(10),
    },
  },
  insideAccordionTopicDiv: {
    display: "flex",
    marginLeft: pxToVw(32),
    width: pxToVw(342),
    height: pxToVw(38),
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: pxToVw(10),
    border: "1px solid #0EC99E33",
    [theme.breakpoints.down("mb")]: {
      borderRadius: pxToMvw(8),
      marginLeft: pxToVw(32),
      width: pxToMvw(258),
      height: pxToMvw(22),
    },
  },
  insideAccordionTopicWrapper: {
    display: "flex",
    paddingTop: "10px",
  },
  insideAccordionTopic: {
    fontFamily: "Poppins",
    fontSize: pxToVw(14),
    fontWeight: "400",
    lineHeight: pxToVw(18),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
      fontWeight: "500",
    },
  },
  insideAccordionTopicTick: {
    height: pxToVw(20),
    width: pxToVw(20),
  },
  CardHeadingContainer: {
    display: "flex",
    alignItems: "center",
  },
  CardHeading: {
    marginLeft: pxToVw(40),
    fontFamily: "Poppins",
    fontSize: pxToVw(24),
    fontWeight: "500",
    marginRight: pxToVw(20),
    letterSpacing: "0.01em",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
      lineHeight: pxToMvh(16),
      paddingLeft: pxToMvw(15),
    },
  },
  roadmapSideContent: {
    marginTop: pxToVh(60),
    paddingLeft: pxToVh(60),
    [theme.breakpoints.down("mb")]: {
      marginTop: pxToMvh(35),
      paddingLeft: pxToMvh(0),
    },
  },
  roadmapSideContentHeading: {
    fontFamily: "Poppins",
    fontSize: pxToVw(14),
    fontWeight: "500",
    lineHeight: pxToVw(21),
    letterSpacing: "0.125em",
    textAlign: "left",
    color: "#646464",
    paddingBottom: pxToVh(21),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
    },
  },
  roadmapAuthurCardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: pxToVh(14),
    [theme.breakpoints.down("mb")]: {
      display: "grid",
      grid: pxToMvh(80) + " / 2fr 2fr",
    },
  },
  roadmapAuthurCard: {
    width: pxToVw(441),
    borderRadius: pxToVw(11),
    display: "flex",
    alignItems: "center",
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 3px 10px",
    [theme.breakpoints.down("mb")]: {
      boxShadow: "none",
      width: "100%",
    },
  },
  roadmapAurthurImg: {
    height: pxToVw(72),
    width: pxToVw(72),
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(50),
      height: pxToMvw(50),
    },
  },
  roadmapAuthurCardPicContainer: {
    paddingLeft: pxToVw(41),
    paddingTop: pxToVh(20),
    paddingBottom: pxToVh(21),
    [theme.breakpoints.down("mb")]: {
      paddingTop: pxToVh(10),
      paddingBottom: pxToVh(10),
      paddingLeft: pxToVw(0),
    },
  },
  roadmapAuthurCardName: {
    fontFamily: "Poppins",
    fontSize: pxToVw(16),
    color: "#646464",
    fontWeight: "500",
    lineHeight: pxToVw(24),
    letterSpacing: "0.15000000596046448px",
    textAlign: "left",
    [theme.breakpoints.down("mb")]: {
      lineHeight: "unset",
      fontSize: pxToMvw(12),
    },
  },
  roadmapAuthurCardPosition: {
    fontFamily: "Poppins",
    fontSize: pxToVw(14),
    color: "#646464",
    fontWeight: "400",
    lineHeight: pxToVw(21),
    letterSpacing: "0.15000000596046448px",
    textAlign: "left",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(10),
      lineHeight: "unset",
    },
  },
  roadmapAuthurCardDetailsContainer: {
    height: "100%",
    padding: pxToVh(17) + " 0px",
    paddingLeft: pxToVw(16),
    [theme.breakpoints.down("mb")]: {
      padding: pxToMvh(12) + " 0px",
      paddingLeft: pxToMvw(16),
      fontSize: pxToMvw(16),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  roadmapAuthurCardSocialIcon: {
    heigth: pxToVw(21),
    width: pxToVw(21),
    cursor: "pointer",
    fontSize: pxToVw(24),
    marginRight: pxToVw(10),
    color: "#0EC99E",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(21),
      height: pxToMvw(21),
      fontSize: pxToMvw(24),
      marginRight: pxToMvw(10),
      display: "none",
    },
  },
});
