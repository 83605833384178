import React from "react";
// import { useState } from "react";
import Step from "./step";
import { useStyles } from "./styles";

const StepHeader = (props) => {

    const styles = useStyles();

    const stepClick = (index) => {
        console.log(index, props.current,props.step,props.completed);
        if (index + 1 < props.current) {
            
            props.setCurrent(index);
            props.setStep(index + 1);
            // props.completed.pop();
        }
        if (props.completed.includes(index)) {
            props.setCurrent(index+1);
            props.setStep(index+1);
        }
        
    }

    const headertext = ["Hello! Welcome to Glosity" ,"Create Company Page","Looking to Hire in ?","You are all done!"]

    return (
        <div className={styles.wrapperContainer}>
        <div className={styles.stepHeaderWrapper}>
            <div className={styles.stepsContainer}>
            <div className={styles.stepProgressBar}>
                <div className={styles.progress} style={{width:`${(props.step - 1) * 50}%`}}></div>
            </div>
                {props.titles.map((step , index) => (
                    <Step onclick={() => stepClick(index)} key={index} step={step} current={props.current} completed={props.completed} index={index+1} />
                ))}
            </div>
            {/* <h1 className={styles.heading}>Hello! Welcome to Glosity</h1> */}
            <h1 className={styles.heading}>{headertext[props.step-1]}</h1>
            </div>
        </div>
    )
}

export default StepHeader;