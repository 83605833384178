import React, { useState } from "react";
import { useStyles } from "./Styles";
import { ReactComponent as CloseIcon } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/closeIcon.svg";
import { pxToRem } from "../../../../Utils/pxToRem";
import { ReactComponent as CopyIcon } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/ShareModal/copyIcon.svg";
import { ReactComponent as Whatsapp } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/ShareModal/whatsapp.svg";
import { ReactComponent as Facebook } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/ShareModal/facebook.svg";
import { ReactComponent as Twitter } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/ShareModal/twitter.svg";
import { ReactComponent as Linkedin } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/ShareModal/linkedin.svg";
import { ReactComponent as Telegram } from "../../../../Assets/images/ExploreCareerPage/ExploreCareerModal/ShareModal/telegram.svg";
import Modal from "@mui/material/Modal";
import { closeNestedModal } from "../../../../redux/Actions/Modal/modal";
import { useDispatch } from "react-redux";

const ShareModal = ({ open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [close, setClose] = useState(false);

  const shareLink = [
    {
      text: "Whatsapp",
      Icon: Whatsapp,
    },
    {
      text: "Facebook",
      Icon: Facebook,
    },
    {
      text: "Twitter",
      Icon: Twitter,
    },
    {
      text: "Linkedin",
      Icon: Linkedin,
    },
    {
      text: "Telegram",
      Icon: Telegram,
    },
  ];

  const handleShareModalClose = () => {
    setClose(false);
    dispatch(closeNestedModal({ type: "SHAREMODAL", close: close }));
  };

  return (
    <Modal open={open} className={classes.shareModalWrapper}>
      <div className={classes.shareModal}>
        <div style={{ width: "100%" }}>
          <div className={classes.closeIcon} onClick={handleShareModalClose}>
            <CloseIcon className={classes.close} />
          </div>
        </div>
        <h1 className={classes.shareModalHeader}>Share</h1>
        <div className={classes.shareModalBody}>
          <div className={classes.shareLinkGroup}>
            {shareLink.map((item, index) => (
              <div className={classes.shareLink} key={index}>
                <div className={classes.shareLinkIcon}>
                  <item.Icon style={{ height: pxToRem(32), width: "auto" }} />
                </div>
                <span className={classes.shareLinkText}>{item.text}</span>
              </div>
            ))}
          </div>
          <div className={classes.sharePageLink}>
            <h3 className={classes.pageLink}>Page link</h3>
            <div className={classes.linkContainer}>
              <p className={classes.link}>
                https://www.figma.com/file/xSJg5ITZXIyIz4
              </p>
              <CopyIcon
                className={classes.copyIcon}
                style={{ marginLeft: "auto", cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
