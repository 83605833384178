import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw } from "../../../Utils/pxToRem";

export const useStyles = makeStyles({
  ProfileTop: {
    // width: "100vw",
  },
  ProfileTopCoverWrap: {
    position: "relative",
    width: "100vw",
  },
  ProfileTopProfileWrap: {
    marginTop: pxToVw(-300),
    marginLeft: pxToVw(10),
  },
  ProfileTopAboutSectioneWrap: {},
});
