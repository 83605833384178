import { makeStyles } from "@mui/styles";
import { pxToRem , pxToVh, pxToVw , pxToMvw, pxToMvh} from "../../../../Utils/pxToRem";
import { theme} from "../../../../Utils/breakpoints";

export const useStyles = makeStyles(() => ({
    heading: {
            fontSize: pxToRem(48),
            background: "-webkit-linear-gradient(#00634C, #009270)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily:"Poppins,sans-serif",
            fontWeight: 600,
            letterSpacing: "-1.5px",
            margin: 0,
            lineHeight:pxToVh(86),
            textAlign: "center",
            marginTop: pxToVh(32),
        marginBottom: pxToVh(32),
        [theme.breakpoints.down("sm")]: {
           
            fontSize: pxToMvw(24),
            marginTop: pxToMvh(18),
            marginBottom: pxToMvh(24),
            
        }
            
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: pxToVh(102),
        position: "relative",
        zIndex:10,
        [theme.breakpoints.down("sm")]: {
            marginTop: pxToMvh(16),
        }
        
    },
    note: {
        color: "rgba(157, 157, 157, 1)",
        fontSize: pxToRem(18),
        lineHeight: pxToVh(32),
        marginBottom: pxToVh(36),
        [theme.breakpoints.down("sm")]: {
            fontSize: pxToMvw(14),
            textAlign:"center"
        }
        
        
    },
    invalidOtpMessage: {
        color: "#E8412E",
        
    },
    codeContainer: {
        display: "flex",
        gap: pxToVw(20),
        marginBottom: pxToVh(32),
        [theme.breakpoints.down("sm")]: {
        gap: pxToMvw(13),
            
        }
    },
    code: {
        width: pxToVw(80),
        height: pxToVw(80),
        borderRadius: pxToVw(29),
        border: (props) => props.inValidOtp ?  "2px solid #E8412E" :"2px solid rgba(14, 201, 158, 0.5)" ,
        backgroundColor: "white",
        background: "transparent",
        outline: "none",
          
            appearance: "textfield",
            MozAppearance: "textfield",
            WebkitAppearance: "none",
            fontSize: pxToRem(38),
            fontFamily:"Poppins,sans-serif",
            textAlign: "center",
            color: "#9BA09F",
            fontWeight:600,
        "& input": {
            
        },
        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(52),
            height: pxToMvw(52),
            borderRadius: pxToMvw(16),
            fontSize: pxToMvw(28),
        }
    },
    submit: {
        // width: pxToVw(197),
        height: pxToVw(80),
        borderRadius: pxToRem(40),
        padding:`${pxToVw(24)} ${pxToVw(46)}`,
        margin: `${pxToVh(24)} auto ${pxToVh(32)} auto`,
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginBottom: pxToVh(118),
        fontSize: pxToRem(18),
        fontFamily: "Poppins,sans-serif",
        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(166),
            height: pxToMvw(48),
            borderRadius: pxToMvw(40),
            fontSize:pxToMvw(12)
            
        }
    },
    enableSubmit: {
        background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
        border: "2px solid #0EC99E",
        cursor: "pointer",
        
    },
    disableSubmit: {
        background: "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), #0EC99E",
        border: "none",
        cursor: "not-allowed",
        
    },
    timerContainer: {

        "& h1": {
            fontSize: pxToRem(18),
            fontWeight: 400,
            color: "rgba(157, 157, 157, 1)",
        }
    },
    timer: {
        color:"#018F6E",
        fontWeight: 500,
        fontSize: pxToRem(18),
        
    },
    emailVerified: {
        width: pxToVw(258),
        height: pxToVw(80),
        border: "2px solid #0EC99E",
        borderRadius: pxToRem(40),
        backgroundColor: "white",
        fontSize: pxToRem(18),
        lineHeight: pxToRem(32),
        color: "#0EC99E",
        fontFamily: "Poppins,sans-serif",
        display: "flex",
        alignItems: "center",
        margin: `${pxToVh(24)} auto ${pxToVh(32)} auto`,
        
        gap:pxToVw(20),
        "& span": {
            fontFamily: "Poppins,sans-serif",
            textAlign:"center"
        },
        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(163),
            height: pxToMvw(48),
            borderRadius: pxToMvw(24),
            fontSize: pxToMvw(12),
            gap:pxToMvw(12.02)

            
        }
        
    },
    checked: {
        backgroundColor: "#CFF4EC",
        width: pxToVw(48),
        height: pxToVw(48),
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: pxToVw(16),
        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(28.84),
            height: pxToMvw(28.84),
            borderRadius: pxToMvw(24),
            marginLeft: pxToMvw(9.61),
            
        }
        
    },
    loadingState: {
        backgroundColor: "#C8EDE4",
        width: pxToVw(96),
        height: pxToVw(96),
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: `${pxToVh(24)} auto ${pxToVh(32)} auto`,
        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(48),
            height: pxToMvw(48),
        },
        "& svg": {
            alignSelf:"center"
        }
    }
    
}))