import React from "react";
import { useStyles } from "./Styles";

const Chip = ({ val, skills }) => {
  const classes = useStyles();
  return (
    <div className={skills ? classes.ChipSkills : classes.Chip}>
      <div className={classes.ChipTittle}>{val}</div>
    </div>
  );
};

export default Chip;
