import React from "react";
import { useStyles } from "./Styles";
import { ReactComponent as Plus } from "../../../../Assets/images/ProfilePage/Section/plus.svg";

const AddButton = ({ buttonText, buttonDesc, buttonIcon }) => {
  const classes = useStyles();
  return (
    <div className={classes.addButton}>
      <div className={classes.left}>
        <div className={classes.titleWrapper}>
          <img src={buttonIcon} alt="icon" />
          <h3 className={classes.title}>{buttonText}</h3>
        </div>
        <p className={classes.desc}>{buttonDesc}</p>
      </div>
      <div className={classes.right}>
        <div className={classes.button}>
          <Plus className={classes.plus} />
        </div>
      </div>
    </div>
  );
};

export default AddButton;
