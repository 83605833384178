import React from "react";
import Card from "../Card/Card";
import { useStyles } from "./Styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as Left } from "../../../../Assets/images/ProfilePage/Section/Carousal/left.svg";
import { ReactComponent as Right } from "../../../../Assets/images/ProfilePage/Section/Carousal/right.svg";
import icon from "../../../../Assets/images/ProfilePage/Section/Card/google.png";

const CardCarousal = () => {
  const classes = useStyles();
  function SampleNextArrow(props) {
    const { currentSlide, onClick } = props;
    if (currentSlide > 4) {
      return false;
    } else {
      return <Right className={classes.rightArrow} onClick={onClick} />;
    }
  }

  function SamplePrevArrow(props) {
    const { currentSlide, onClick } = props;
    if (currentSlide === 0) {
      return false;
    } else {
      return <Left className={classes.leftArrow} onClick={onClick} />;
    }
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className={classes.carousalInnerWrapper}>
      <Slider {...settings} className={classes.carousal}>
        <Card
          title="UX Designer"
          subtitle="Google INc"
          date="Oct 2021 - Current"
          img={icon}
          titleTag="Intership"
          tags
        />
        <Card
          title="TATA 10K National Marathon"
          subtitle="Google INc"
          date="2021"
          img={icon}
          acheivement="3rd Position"
          acheivementTagText="Award"
        />
        <Card
          title="UX Specialization"
          subtitle="Coursera"
          date="2012 - 2014"
          img={icon}
          tags
        />
        <Card
          title="LPU, Chandigarh"
          subtitle="M Tech, Computer Science"
          date="2012 - 2014"
          img={icon}
          grade="10 CGPA"
        />
        <Card
          title="UX Designer"
          subtitle="Google INc"
          date="Oct 2021 - Current"
          img={icon}
          titleTag="Intership"
          tags
        />
        <Card
          title="TATA 10K National Marathon"
          subtitle="Google INc"
          date="2021"
          img={icon}
          acheivement="3rd Position"
          acheivementTagText="Award"
        />
        <Card
          title="UX Specialization"
          subtitle="Coursera"
          date="2012 - 2014"
          img={icon}
          tags
        />
        <Card
          title="LPU, Chandigarh"
          subtitle="M Tech, Computer Science"
          date="2012 - 2014"
          img={icon}
          grade="10 CGPA"
        />
      </Slider>
    </div>
  );
};

export default CardCarousal;
