/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useStyles } from "./Styles";
import Text from "../../../Shared/inputV2/Text/input";
import { axiosClient } from "../../../../Services/apiClient";
import { ReactComponent as DialPad } from "../../../../Assets/images/StudentOnboard/LoginPage/Dialpad.svg";
import Error from "../../../Shared/Error/Error";

const Recover = ({ values, setValues, page, setPage }) => {
  const [phoneError, setPhoneError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (values.phonenumber.length === 0) {
      setPhoneError(false);
      setError("");
    }
  }, [values.phonenumber, error]);

  useEffect(() => {
    if (values.phonenumber.length >= 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values.phonenumber]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phoneError) {
      try {
        const res = await axiosClient.post("/isStudentMobileNumberExist", {
          PhoneNumber: values.phonenumber,
        });
        if (!res.data.status) {
          setPhoneError(true);
        } else {
          try {
            const res = await axiosClient.post("/getOTPStudentResetPassword", {
              countryCode: "+91",
              PhoneNumber: values.phonenumber,
            });
            console.log(res);
            if (res.data.success) {
              setPage(page + 1);
            } else if (res.data.success === false) {
              setError(res.data.message);
            }
          } catch (err) {
            console.log(err);
          }
        }
      } catch (err) {
        setError("Someting went wrong. Please try again later");
      }
    }
  };

  const classes = useStyles({ disabled });
  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit} autoComplete="off" className={classes.form}>
        <h3 className={classes.subtitle}>
          Don&apos;t worry, happens to the best of us.
        </h3>
        <div className={classes.inputWrappers}>
          <Text
            icon={<DialPad />}
            onChange={onChange}
            value={values.phonenumber}
            name="phonenumber"
            type="text"
            placeholder="Enter your Phone number"
            errorMessage="This mobile number isn't registered."
            error={phoneError}
            setError={setPhoneError}
          />
        </div>
        {error && <Error text={error} color="red" />}
        <button disabled={disabled} type="submit" className={classes.button}>
          Send me OTP
        </button>
      </form>
    </div>
  );
};

export default Recover;
