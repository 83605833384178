import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw, pxToMvw } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const useStyles = makeStyles({
  Exploreheader: {
    padding: pxToVw(15) + " " + pxToVw(70),
    display: "flex",
    alignItems: "center",
    height: pxToVw(72),
    boxShadow: "0px 6px 74px 0px #0ec99e25",
    position: "sticky",
    backgroundColor: "#FFFFFF",
    top: 0,
    zIndex: 10,
    [theme.breakpoints.down("mb")]: {
      padding: pxToMvw(17) + " " + pxToMvw(25),
      justifyContent: "space-between",
    },
    // boxShadow: "0px 6px " + pxToRem(72) + " rgba(14, 201, 158, 0.07)",
  },
  ExploreheaderLogoIcon: {
    height: pxToVw(45),
    width: pxToVw(170),
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      height: pxToMvw(40),
      width: pxToMvw(110),
    },
  },
  ExploreheaderSearchBar: {
    padding: pxToVw(10) + " " + pxToVw(40),
    backgroundColor: "rgba(223, 223, 223, 0.37)",
    borderRadius: pxToVw(8),
    fontSize: pxToVw(16),
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
    border: "none",
    borderColor: "#d6e9c6",
    width: "100%",
    color: "#9D9D9D",
    "&:before": {
      position: "absolute",
    },
  },
  ExploreheaderSearchBarDiv: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: pxToVw(550),
    marginRight: pxToVw(461),
    marginLeft: pxToVw(50),
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  ExploreheaderSearchIcon: {
    position: "absolute",
    marginRight: pxToVw(28),
    width: pxToVw(20),
    height: pxToVw(20),
  },
  ExploreheaderNavLinks: {
    display: "flex",
    width: pxToVw(370),
    justifyContent: "space-between",
    fontSize: pxToVw(18),
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  ExploreheaderNavLink: {
    cursor: "pointer",
  },
  ExploreheaderNotificationAndProfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    justifySelf: "flex-end",
    marginLeft: pxToVw(78),
    width: pxToVw(110),
    [theme.breakpoints.down("mb")]: {
      display: "none",
    },
  },
  ExploreheaderNotificationIcon: {
    width: pxToVw(25),
    height: pxToVw(25),
  },
  ExploreheaderProfileIcon: {
    width: pxToVw(40),
    height: pxToVw(40),
    display: "flex",
  },
  ExploreheaderMenuIcon: {
    display: "none",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(40),
      height: pxToMvw(40),
      display: "flex",
    },
  },
});

// export const useStyles = makeStyles({
//   topbar: {
//     display: "flex",
//     justifyContent: "space-between",
//     width: pxToVw(1920),
//     height: pxToVh(72),
//     position: "sticky",
//     top: 0,
//     backgroundColor: "#FFFFFF",
//     paddingLeft: pxToVw(70) + "!important",
//     paddingRight: pxToVw(223) + "!important",
//     border: "2px solid white",
//     boxShadow: "0px 6px 74px 0px #0EC99E25",
//     // boxShadow: "0px 6px 74px rgba(14, 201, 158, 0.07)",
//     zIndex: 999,
//     [theme.breakpoints.down("mb")]: {
//       paddingLeft: pxToMvw(30) + "!important",
//       paddingRight: pxToMvw(30) + "!important",
//       height: "6vh",
//     },
//   },

//   left: {
//     display: "flex",
//     alignItems: "center",
//     gap: pxToVw(87),
//   },
//   buttons: {
//     display: "flex",
//     gap: pxToVw(42),
//     [theme.breakpoints.down("mb")]: {
//       display: "none",
//     },
//   },
//   logo: {
//     width: pxToVw(165),
//     height: pxToVw(45),
//     cursor: "pointer",
//     [theme.breakpoints.down("mb")]: {
//       width: pxToMvw(100),
//       height: pxToVh(37),
//     },
//   },
//   text: {
//     fontSize: pxToVw(18),
//     fontFamily: "'Poppins', sans-serif;",
//     fontWeight: "400",
//     color: "#474747",
//     cursor: "pointer",
//     [theme.breakpoints.down("mb")]: {
//       fontSize: pxToVw(36),
//     },
//   },
//   mobileOptions: {
//     display: "none",
//     [theme.breakpoints.down("mb")]: {
//       display: ({ open }) => (open ? "flex" : "none"),
//       flexDirection: "column",
//       height: "100vh",
//       gap: pxToVw(150),
//       width: "100%",
//       backgroundColor: "#131313",
//       paddingTop: pxToVw(108),
//       paddingLeft: pxToVw(80),
//       zIndex: 9999,
//     },
//   },
//   options: {
//     display: "flex",
//     alignItems: "center",
//     gap: pxToVw(40),
//     paddingBottom: pxToVh(6),
//     [theme.breakpoints.down("mb")]: {
//       display: "none",
//     },
//   },
// });
