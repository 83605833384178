import { makeStyles } from "@mui/styles";
import { pxToRem , pxToVh , pxToVw , pxToMvw, pxToMvh } from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";

export const useStyles = makeStyles({
    container: {
          display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: pxToVh(274),
        [theme.breakpoints.down("sm")]: {
            marginTop: pxToMvh(136),
        }
    },
     nextBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height:"100%"
    },
    heading: {
        fontSize: pxToRem(48),
        background: "-webkit-linear-gradient(#00634C, #009270)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontFamily:"Poppins,sans-serif",
        fontWeight: 600,
        letterSpacing: "-1.5px",
        margin: 0,
        lineHeight:pxToVh(86),
        textAlign: "center",
        marginTop: pxToVh(32),
        marginBottom: pxToVh(32),
        [theme.breakpoints.down("sm")]: {
            fontSize: pxToMvw(24),
            marginTop: pxToMvh(48),
            marginBottom: pxToMvh(24),
        }
      
    },
    subHead: {
        fontSize: pxToRem(18),
        lineHeight: pxToRem(32),
        color: "rgba(157, 157, 157, 1)",
        
        fontFamily: "Poppins,sans-serif",
        fontWeight: 400,
        marginBottom: pxToVh(21),
        [theme.breakpoints.down("sm")]: {
            fontSize:pxToMvw(12)
        }
    },
    fieldButtons: {
        display: "flex",
        gap: pxToVw(30),
        width: "100%",
        height:"100%",
        flexWrap: "wrap",
        justifyContent:"center",
        padding: "15px 0",
        marginBottom: pxToVh(32),
        zIndex:20,
        position:"relative",
        [theme.breakpoints.down("sm")]: {
            // width: pxToMvw(153),
            // height: pxToMvw(78),
            padding: 0,
            display: "grid",
            gridTemplateColumns: `${pxToMvw(195)} ${pxToMvw(195)}`,
            gap: pxToMvw(20),
            zIndex:9
            
        }
    },
    Field: {
        width: pxToVw(360),
        height: pxToVw(136),
        // border: "2px solid rgba(14, 201, 158, 1)",
        borderRadius: pxToRem(40),
        boxShadow: "0px 10px 20px rgba(41, 41, 42, 0.07)",
        backgroundColor: "white",
        padding: `${pxToVw(24)} ${pxToVw(30)}`,
        display: "flex",
        flexDirection: "column",
        gap: pxToRem(17),
        cursor: "pointer",
        transition: "all .3s ease",
        WebkitTapHighlightColor:"transparent",
        
        "& h1": {
            fontSize: pxToVw(24),
            color: "rgba(71, 71, 71, 1)",
            fontWeight: 500,
            [theme.breakpoints.down("sm")]: {
                fontSize: pxToMvw(14),
                lineHeight:pxToMvh(32)
            }
        },
        "& svg": {
            width: pxToVw(32),
            height: pxToVw(32),
            [theme.breakpoints.down("sm")]: {
                width: pxToMvw(18.22),
                height: pxToMvw(18.22),
            }
        },
        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(195),
            height: pxToMvw(100),
            
        }
    },
    errorText: {
        color: "#F44336",
        fontSize: pxToRem(16),
        textAlign: "center",
        marginBottom: pxToVw(32),
        fontWeight:500,
        
        [theme.breakpoints.down("sm")]: {
            fontSize:pxToMvw(14)
        }
    },
    submit: {
        width: pxToVw(131),
        height: pxToVw(80),
        borderRadius: pxToRem(40),
        // background: "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), #0EC99E",
        // border:"2px solid #0EC99E",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginBottom: pxToVh(86),
        fontSize: pxToRem(18),
        fontFamily:"Poppins,sans-serif",
        border: "none",
        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(124),
            height: pxToMvw(48),
            borderRadius: pxToMvw(40),
            fontSize:pxToMvw(12)
        }
    },
    disabled: {
        background: "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), #0EC99E",
        cursor: "no-drop",
        [theme.breakpoints.down("sm")]: {
            margin: 0,
            // width: pxToMvw(124),
            // height: pxToMvw(48),
            borderRadius: pxToMvw(40),
            fontSize:pxToMvw(12)
        }
    },
    enabled: {
        background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            margin: 0,
            // width: pxToMvw(124),
            // height: pxToMvh(48),
            borderRadius: pxToMvw(40),
            fontSize:pxToMvw(12)
        }
        
    },
    ValidContainer: {
        position: "relative",
        margin: `0 auto ${pxToVh(86)} auto`,
        
    },
    validationtext: {
        fontSize: pxToRem(16),
        lineHeight: pxToVh(32),
        color: "rgba(157, 157, 157, 1)",
        fontWeight: 400,
        position: "absolute",
        top:"50%",
        left: pxToVw(154),
        width: "max-content",
        display: "flex",
        alignItems: "center",
        padding:`0 ${pxToVw(16)}`,
        height:pxToVw(64),
        backgroundColor: "white",
        transform: "translateY(-50%)",
        borderRadius: pxToVw(24),
        transition:"all .4s ease"
    },
    selected: {
        border:"1px solid green"
    },
    notSelected: {
        border:"none"
    }
})