import React, { useState } from "react";
import Auth from "./Auth";
// import Hero from "./Hero";
import { useStyles } from "./Styles";
import Title from "./Title";
import image1 from "../../../Assets/images/LandingPage/Header/1.png";
import image2 from "../../../Assets/images/LandingPage/Header/2.png";
import image3 from "../../../Assets/images/LandingPage/Header/3.png";
import image4 from "../../../Assets/images/LandingPage/Header/4.png";
import image5 from "../../../Assets/images/LandingPage/Header/5.png";
import image6 from "../../../Assets/images/LandingPage/Header/6.png";
import image7 from "../../../Assets/images/LandingPage/Header/7.jpg";
import image8 from "../../../Assets/images/LandingPage/Header/8.jpg";
import image9 from "../../../Assets/images/LandingPage/Header/9.jpg";
import image10 from "../../../Assets/images/LandingPage/Header/10.jpg";
import image11 from "../../../Assets/images/LandingPage/Header/11.jpg";
import image12 from "../../../Assets/images/LandingPage/Header/12.jpg";
import image13 from "../../../Assets/images/LandingPage/Header/13.jpg";
import image14 from "../../../Assets/images/LandingPage/Header/14.jpg";
import image15 from "../../../Assets/images/LandingPage/Header/15.jpg";
import image16 from "../../../Assets/images/LandingPage/Header/16.jpg";
import image17 from "../../../Assets/images/LandingPage/Header/17.jpg";
import image18 from "../../../Assets/images/LandingPage/Header/18.jpg";
import polygon from "../../../Assets/images/LandingPage/Header/Star.png";
import star from "../../../Assets/images/LandingPage/Header/Polygon.png";
import rectangle from "../../../Assets/images/LandingPage/Header/Rectangle.png";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const Header = () => {
  const classes = useStyles();
  const [isShown, setIsShown] = useState(false);
  const c1 = [
    [image1, "message1"],
    [image2, "message2"],
    [image3, "message3"],
    [image4, "message4"],
    [image5, "message5"],
    [image6, "message6"],
  ];
  const c2 = [image7, image8, image9, image10, image11, image12];
  const c3 = [image13, image14, image15, image16, image17, image18];
  return (
    <div className={classes.header}>
      <div className={classes.left}>
        <Title />
        <Auth />
      </div>
      <div className={classes.right}>
        <div className={classes.heros}>
          <div className={classes.heroWrapper}>
            <Zoom scale={1} arrows={false} duration={6000}>
              {c1.map((i, id) => (
                <div
                  className={classes.heroImageWrapper}
                  key={id}
                  onMouseEnter={() => setIsShown(true)}
                  onMouseLeave={() => setIsShown(false)}
                >
                  <img src={i[0]} className={classes.heroImage} />
                  <div key={id}>{isShown && <div>{i[1]}</div>}</div>
                </div>
              ))}
            </Zoom>
          </div>
          <div className={classes.heroWrapper}>
            <Zoom scale={1} arrows={false} duration={8000}>
              {c2.map((image) => (
                <div className={classes.heroImageWrapper}>
                  <img src={image} className={classes.heroImage} />
                </div>
              ))}
            </Zoom>
          </div>
          <div className={classes.heroWrapper}>
            <Zoom scale={1} arrows={false} duration={10000}>
              {c3.map((image) => (
                <div className={classes.heroImageWrapper}>
                  <img src={image} className={classes.heroImage} />
                </div>
              ))}
            </Zoom>
          </div>
        </div>
        <div className={classes.heroMobile}>
          <img src={rectangle} className={classes.rectangle} />
          <img src={polygon} className={classes.polygon} />
          <img src={star} className={classes.star} />
        </div>
      </div>
    </div>
  );
};

export default Header;
