import React from "react";
import { useStyles } from "./Styles";

const Title = () => {
  const classes = useStyles();
  return (
    <div className={classes.title}>
      <div className={classes.textWrapper}>
        <h1 className={classes.text}>Not Just any job portal,</h1>
        <h1 className={classes.text}>Prove your work and get hired.</h1>
      </div>
      <div>
        <p className={classes.subtext}>
          We will also guide you incase you need guidance to choose your Career
          path
        </p>
      </div>
    </div>
  );
};

export default Title;
