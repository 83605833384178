import { makeStyles } from "@mui/styles";
// import { pxToVh, pxToVw } from "../../../Utils/pxToRem";

export const useStyles = makeStyles({
  //   Cover__area: {
  //     position: "reletive",
  //   },
  Cover__area_image: {
    width: "100vw",
  },
});
