import React from "react";
import { useStyles } from "./Styles";

const SectionText = ({ title, subtitle, buttontext }) => {
  const classes = useStyles();
  return (
    <div className={classes.details}>
      <h3 className={classes.title}>{title}</h3>
      <p className={classes.subtitle}>{subtitle}</p>
      <button className={classes.button}>{buttontext}</button>
    </div>
  );
};

export default SectionText;
