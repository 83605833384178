import { makeStyles } from "@mui/styles";
import { pxToVw, pxToVh, pxToRem, pxToMvw } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const useStyles = makeStyles({
  inputContainer: {
    backgroundColor: "#fff",
    width: pxToVw(620),
    height: pxToVw(81),
    position: "relative",
    borderRadius: pxToRem(40),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 10px 20px 0 #0EC99E12",
    border: "2px solid #fff",
    transition: "all .3s ease",

    "&:focus-within svg": {
      // border:"1px solid black"
      fill: "rgba(84, 84, 84, 1)",
      // backgroundColor:"red",
      opacity: 1,
      
    },
    "&:focus-within": {
      border: "2px solid #0EC99E",
    },
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(417),
      height: pxToMvw(60),
      borderRadius: pxToMvw(40),
    },
  },
  icon14: {
    width: `${pxToVw(14)}`,
    height: `${pxToVh(14)}`,
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
        width: pxToMvw(8),
        height: pxToMvw(8),
       
    },
  },
  errorWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: pxToVh(8),
    [theme.breakpoints.down("sm")]: {
      zIndex:20
    },
  },
  icon: {
    width: `${pxToVw(24)}`,
    height: `${pxToVh(24)}`,
    opacity: 0,
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(13.5),
      height: pxToMvw(13.5),
    },
  },
  errorNote: {
    position: "relative",
    bottom: 0,
    left: pxToVw(44),
    color: "red",
    fontSize: pxToRem(16),
    "& a": {
      color: "#0EC99E",
      textDecoration: "none",
      fontWeight: 400,
      fontSize: pxToRem(16),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    },
  },
  errorContainer: {
    width: pxToVw(532),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(390),
      marginLeft: pxToMvw(20),
      marginTop: pxToMvw(10),
    },
  },
  suggestionsContainer: {
    position: "absolute",
    left: 0,
    top: pxToVh(85),
    width: pxToVw(620),
    backgroundColor: "white",
    borderRadius: pxToRem(40),
  },
  options: {
    width: pxToVw(619),
    height: pxToVh(57.5),
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "#0EC99E69",
    },
  },
  dropdownAsync: {
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(326),
      height: pxToMvw(48),
    },
  },
  addCompany: {
    width: "100%",
    height: pxToVh(52),
    display: "flex",
    alignItems: "center",
    padding: `0 ${pxToVw(20)}`,
    cursor: "pointer",
    gap: pxToVw(15),
    "& span": {
      color: "#9D9D9D",
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        fontSize: pxToMvw(12),
      },
    },
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvw(15),
    },
  },
  innerAdd: {
    width: "auto",
    height: pxToVh(52),
    padding: `0 ${pxToRem(17)}`,
    border: "1px solid #E3E3E3",
    display: "flex",
    alignItems: "center",
    borderRadius: pxToRem(16),
    gap: pxToVw(8),
    "& svg": {
      width: pxToVw(26),
      height: pxToVh(26),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    },
  },
  childrenWrapper: {
    width: pxToVw(587),
    maxHeight: pxToVh(167),

    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: pxToVw(9),
      transform: "translate(-50%)",
    },
    "&::-webkit-scrollbar-track": {
      width: pxToVw(9),
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      width: pxToVw(9),
      backgroundColor: "#0EC99E33",
      borderRadius: pxToRem(8),
    },
    [theme.breakpoints.down("sm")]: {
      // width: pxToMvw(326),
      width: "100%!important",
    },
  },
  innerContainer: {
    width: pxToVw(517.32),
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: pxToRem(22),
    "& svg": {
      width: pxToVw(24),
      height: pxToVw(24),
      [theme.breakpoints.down("sm")]: {
        width: pxToMvw(18),
        height: pxToMvw(18),
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(352),
    },
  },
  innerTextContainer: {
    width: pxToVw(517.32),
    height: "100%",
    display: "flex",
    padding: pxToRem(20),

    alignItems: "flex-start",
    justifyContent: "center",
    gap: pxToRem(22),
    "& svg": {
      width: pxToVw(24),
      height: pxToVh(24),
    },
  },
  passwordText: {
    width: pxToVw(450),
    fontFamily: "Poppins,sans-serif",
    height: "100%",
    border: "none",
    outline: "none",
    fontSize: pxToRem(18),
    color: "rgba(84, 84, 84, 1)",
    fontWeight: 500,
    letterSpacing: pxToVw(5),
    "&::placeholder": {
      color: "#9D9D9D",
      fontFamily: "Poppins,sans-serif",
      fontSize: pxToRem(18),
      fontWeight: 400,
      letterSpacing: 0,

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        fontSize: pxToMvw(14),
        lineHeight: pxToMvw(30),
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: pxToMvw(14),
    },
  },
  passwordContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: pxToMvw(10),
    },
  },
  dropdownText: {
    width: pxToVw(400),
    fontFamily: "Poppins,sans-serif",
    height: "100%",
    border: "none",
    outline: "none",
    fontSize: pxToRem(18),
    color: "rgba(84, 84, 84, 1)",
    fontWeight: 500,
    // pointerEvents:"all",
    "&::placeholder": {
      color: "#9D9D9D",
      fontFamily: "Poppins,sans-serif",
      fontSize: pxToRem(18),
      fontWeight: 400,
    },
  },
  inputText: {
    width: pxToVw(517.32),
    fontFamily: "Poppins,sans-serif",
    height: "100%",
    border: "none",
    outline: "none",
    fontSize: pxToRem(18),
    color: "rgba(84, 84, 84, 1)",
    fontWeight: 500,
    "&::placeholder": {
      color: "#9D9D9D",
      fontFamily: "Poppins,sans-serif",
      fontSize: pxToRem(18),
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        fontSize: pxToMvw(14),
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: pxToMvw(14),
      

    },
  },
  inputTextArea: {
    width: "100%",
    height: "100%",
    resize: "none",
    // boxSizing:"border-box",
    border: "none",
    outline: "none",
    display: "flex",
    alignItems: "flex-start",
    fontFamily: "Poppins,sans-serif",
    fontSize: pxToRem(18),
    "&::placeholder": {
      color: "#9D9D9D",
      fontFamily: "Poppins,sans-serif",
      fontSize: pxToRem(18),
      [theme.breakpoints.down("sm")]: {
        fontSize: pxToMvw(12),
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    width: "82%",

    },
  },
  dropdownContainer: {
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(417),
    },
  },
  textAreaContainer: {
    width: pxToVw(620),
    height: pxToVw(175),
    backgroundColor: "#fff",
    position: "relative",
    overflow: "hidden",
    padding: `${pxToVw(24)} ${pxToVw(46)}`,
    borderRadius: pxToRem(40),
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: pxToVw(25),
    boxShadow: "0 10px 20px 0 #0EC99E12",
    border: "2px solid transparent",
    transition: "all .2s ease",
    "&:focus-within svg": {
      // border:"1px solid black"
      fill: "rgba(84, 84, 84, 1)",
      // backgroundColor:"red",
    },

    "& svg": {
      width: pxToVw(24),
      height: pxToVw(24),
      [theme.breakpoints.down("sm")]: {
        width: pxToMvw(12.17),
        height: pxToMvw(12.17),
      },
    },
    "&:focus-within": {
      border: "2px solid #0EC99E",
    },
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(417),
      height: pxToMvw(104),
      gap: pxToMvw(10),

      padding:`${pxToMvw(14)} ${pxToMvw(10)}`
    },
  },
  textAreaAutoContainer: {
    width: pxToVw(620),
    // height: pxToVw(80),
    height: "auto",
    backgroundColor: "#fff",
    position: "relative",
    overflow: "hidden",
    padding: `${pxToVw(24)} ${pxToVw(46)}`,
    borderRadius: pxToRem(40),
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: pxToVw(25),
    boxShadow: "0 10px 20px 0 #0EC99E12",
    border: "2px solid transparent",
    transition: "all .2s ease",
    "&:focus-within svg": {
      // border:"1px solid black"
      fill: "rgba(84, 84, 84, 1)",
      // backgroundColor:"red",
    },
    "& textarea": {
      overflowY: "hidden",
      lineHeight: "auto",
      [theme.breakpoints.down("sm")]: {
        lineHeight:pxToMvw(20)
      },
    },

    "& svg": {
      width: pxToVw(24),
      height: pxToVw(24),
      [theme.breakpoints.down("sm")]: {
        width: pxToMvw(12.17),
        height: pxToMvw(12.17),
      },
    },
    "&:focus-within": {
      border: "2px solid #0EC99E",
    },
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(417),
      minHeight: pxToMvw(60),
      borderRadius: pxToMvw(30),
      alignItems: "center",
    gap: pxToMvw(10),
    

    },
  },
  eye: {
    cursor: "pointer",
  },
  checkBoxContainer: {
    display: "block",
    position: "relative",
    marginRight: pxToVw(30),
    cursor: "pointer",
    userSelect: "none",
    "&:hover input + span": {
      backgroundColor: "#ccc",
    },
    "& input:checked + span": {
      backgroundColor: "#0EC99E",
    },
    
  },
  checkInput: {
    position: "absolute",
    opacity: 0,
    cursor: "pointer",
    height: 0,
    width: 0,
  },
  Checkmark: {
    position: "absolute",
    top: 0,
    left: 0,
    height: pxToVw(30),
    width: pxToVw(30),
    border: "1px solid #D5D5D5",
    backgroundColor: "white",
    borderRadius: pxToRem(8),

    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(12),
      height: pxToMvw(12),
    },
  },
  tick: {
    position: "absolute",
    left: pxToVw(11),
    top: pxToVw(7),
    width: pxToVw(7),
    height: pxToVw(13),
    border: "solid white",
    borderWidth: `0 ${pxToVw(2)} ${pxToVw(2)} 0`,
    transform: "rotate(45deg)",
    [theme.breakpoints.down("sm")]: {
      left: pxToMvw(4),
      top: pxToMvw(1),
      width: pxToMvw(3),
      height: pxToMvw(8),
    },
  },
  noteContainer: {
    width: "100%",
    textAlign: "right",
    "& p": {
      color: "#9D9D9D",
      fontSize: pxToRem(16),
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: pxToMvw(10),
      },
    },
  },

  textWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: pxToVh(13),
    "& svg": {
      fill: "#9D9D9D",
      [theme.breakpoints.down("sm")]: {
      width: pxToMvw(16),
      height: pxToMvw(16)
    
    },
    },
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(417),
    
    },
  },
  noticeContainer: {
    width: pxToVw(532),
    marginLeft: pxToVw(50),
    marginTop: pxToVw(8),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(390),
      marginTop: pxToMvw(19),
      marginLeft: pxToMvw(40),
      fontSize: pxToMvw(12),
    },
  },
  notice: {
    color: "#9D9D9D",
  },
});
