import React from "react";
import { useSelector } from "react-redux";
import ReelsModal from "../../ExploreCareersPage/Modal";
import ShareModal from "../../ExploreCareersPage/Modal/Share/ShareModal";
import { RoadmapModal } from "../../RoadmapPage/RoadmapModal/RoadmapModal";

const ModalManager = () => {
  const open = useSelector((state) => state.modal.modal);
  const modalType = useSelector((state) => state.modal.type);

  let Modal = null;

  switch (modalType) {
    case "REELSMODAL":
      Modal = <ReelsModal open={open} />;
      break;
    case "ROADMAPMODAL":
      Modal = <RoadmapModal open={open} />;
      break;
    default:
      Modal = null;
      break;
  }
  return (
    <>
      {Modal}
      {/* <ModalManager /> */}
    </>
  );
};

export default ModalManager;
