import React, { useState, useEffect } from "react";
import { useStyles } from "./Styles";
import CardContent from "./CardContent/CardContent";
import CardCarousal from "./CardCarousal/CardCarousal";

const Card = ({ data }) => {
  const classes = useStyles();
  const [careerExpertsArr, setCareerExpertsArr] = useState();
  useEffect(() => {
    setCareerExpertsArr(data?.career_experts);
  }, [data]);
  return (
    <div className={classes.exploreCard}>
      <CardContent data={data} />
      <CardCarousal careerExpertsArr={careerExpertsArr} />
    </div>
  );
};

export default Card;
