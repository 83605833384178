/*eslint no-unused-vars:*/
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Utils/breakpoints";
import { pxToMvh, pxToMvw, pxToVh, pxToVw } from "../../../../Utils/pxToRem";
export const useStyles = makeStyles({
  education: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      height: "100vh",
    },
  },
  inputWrappers: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: pxToVw(24),
    paddingBottom: pxToVh(50),
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvh(16),
      paddingBottom: pxToVh(50),
    },
  },
  button: {
    display: "flex",
    gap: pxToVw(30),
  },
  title: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "600",
    fontSize: pxToVw(48),
    lineHeight: pxToVw(86),
    background: "linear-gradient(89.61deg, #00634C -19%, #009270 92.23%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    paddingBottom: pxToVh(28),
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(28),
      lineHeight: pxToMvh(32),
      paddingBottom: pxToMvh(32),
    },
  },
  back: {
    width: pxToVw(140),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    backgroundColor: "rgba(227, 255, 249, 1)",
    border: "2px solid rgba(14, 201, 158, 1)",
    fontSize: pxToVw(18),
    fontFamily: "Poppins,sans-serif",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(106),
      height: pxToMvw(48),
      fontSize: pxToMvw(12),
      borderRadius: pxToMvw(40),
    },
  },
  next: {
    width: pxToVw(140),
    height: pxToVw(80),
    borderRadius: pxToVw(40),
    border: (props) => (props.disabled ? "none" : "2px solid #0EC99E"),
    background: (props) =>
      props.disabled
        ? "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), linear-gradient(0deg, #0EC99E, #0EC99E)"
        : "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%),linear-gradient(0deg, #0EC99E, #0EC99E)",
    color: "white",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(18),
    cursor: (props) => (props.disabled ? "not-allowed" : "pointer"),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(106),
      height: pxToMvw(48),
      borderRadius: pxToMvw(40),
      fontSize: pxToMvw(12),
    },
  },
  datePickers: {
    width: "100%",
    height: "100%",
    display: "flex",
    gap: pxToVw(44),
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvw(60),
      justifyContent: "center",
    },
  },
  picker: {
    color: "green !important",
    border: "none",
    borderRadius: `${pxToVw(40)} !important`,
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    gap: pxToVw(5),
    width: "100%",
    paddingLeft: pxToVw(5),
    [theme.breakpoints.down("sm")]: {
      gap: pxToMvw(5),
    },
  },
  checkInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: pxToVw(22),
    height: pxToVw(22),
    borderRadius: pxToVw(7),
    backgroundColor: (props) => (props.checked ? "#0EC99E33" : "white"),
    border: (props) => (props.checked ? "1px solid #0EC99E" : "none"),
    color: "#0EC99E",
    boxShadow: "0 10px 20px 0 #0EC99E12",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(15),
      height: pxToMvw(15),
      borderRadius: pxToMvw(5),
    },
  },
  text: {
    color: "#9D9D9D",
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(14),
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(10),
    },
  },
});
