import React, { useEffect, useState } from "react";
import { useStyles } from "./Styles";
import DataScienctistPng from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/dataSciencetist.png";
import Company1png from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/company1.png";
import Company2png from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/company2.png";
import Company3png from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/company3.png";
import Company4png from "../../../../Assets/images/ExploreCareerPage/ExploreCareerCard/company4.png";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

import { pxToVw } from "../../../../Utils/pxToRem";
import { useNavigate } from "react-router-dom";

const CardContent = ({ data }) => {
  const cardsData = useSelector((state) => state.ExploreCards.cardData);
  const navigate = useNavigate();

  const maxNumberofCompanyIconstoShow = 4;

  const [low, setLow] = useState(60);
  const [median, setMedian] = useState(80);
  const [high, setHigh] = useState(200);
  const [skillsArr, setSkillsArr] = useState([]);
  const [remainingSkillsArr, setRemainingSkillsArr] = useState([]);
  const [companiesArr, setCompaniesArr] = useState([]);

  const [remainingCompanies, setRemainingCompaines] = useState(-1);

  const [shortDescription, setShortDescription] = useState("");
  const [remainingDescription, setRemainingDescription] = useState("");

  var totalChar = 0;
  var isCount = false;
  var totalSkillsShowing = 100;
  var charsCountForDescription = 220;

  useEffect(() => {
    if (data) {
      setLow(data?.salary.low);
      setMedian(data?.salary.mid);
      setHigh(data?.salary.high);
      setSkillsArr(data?.req_skills);
      setCompaniesArr(data?.companies_hiring);
      setRemainingCompaines(
        data?.companies_hiring.length - maxNumberofCompanyIconstoShow
      );
      if (data?.description.length > charsCountForDescription) {
        setShortDescription(
          data?.description.slice(0, charsCountForDescription)
        );
        setRemainingDescription(
          data?.description.slice(charsCountForDescription - 10)
        );
      }
    }
  }, [data]);

  useEffect(() => {
    setRemainingSkillsArr(skillsArr.slice(totalSkillsShowing));
  }, [skillsArr]);

  const classes = useStyles({ low, median, high });

  const Companies = React.forwardRef(function Companies(props, ref) {
    return (
      <div {...props} ref={ref}>
        <div className={classes.cardOtherDetailsContainer}>
          {companiesArr.map((company, index) => {
            if (index < maxNumberofCompanyIconstoShow) {
              return (
                <div className={classes.cardCompanyDiv} key={index}>
                  <img src={Company4png} className={classes.cardCompanyIcon} />
                </div>
              );
            } else if (index == maxNumberofCompanyIconstoShow) {
              return (
                <div className={classes.cardCompanyDiv} key={index}>
                  <div className={classes.cardCompanysManyIcon}>
                    +{remainingCompanies}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  });
  const DesShowmore = React.forwardRef(function SalaryDiv(props, ref) {
    return (
      <span {...props} ref={ref}>
        {/* {shortDescription} */}
        <span className={classes.cardDescriptionShowMore}>
          ...&nbsp;show more
        </span>
      </span>
    );
  });
  const SkillsShowmore = React.forwardRef(function SkillsShowmore(props, ref) {
    return (
      <div {...props} ref={ref}>
        <div className={classes.cardContentFooterSkill}>
          +{skillsArr.length - totalSkillsShowing}
        </div>
      </div>
    );
  });
  const SalaryDiv = React.forwardRef(function SalaryDiv(props, ref) {
    return (
      <div {...props} ref={ref}>
        <div className={classes.cardSalaryContainer}>
          <svg
            className={classes.cardSalaryIcon}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="15" r="15" fill="#0EC99E" />
            <path
              d="M20.0007 9.09064V7.27246H10.0007V9.09064H13.1826C14.3662 9.09064 15.3653 9.85246 15.7416 10.9088H10.0007V12.727H15.7416C15.555 13.2572 15.2088 13.7166 14.7506 14.0422C14.2924 14.3678 13.7446 14.5435 13.1826 14.5452H10.0007V16.7397L15.0789 21.8179H17.6498L12.1953 16.3634H13.1826C14.2298 16.362 15.2445 15.9995 16.0556 15.3371C16.8668 14.6747 17.4247 13.7528 17.6353 12.727H20.0007V10.9088H17.6353C17.4959 10.2472 17.2088 9.62563 16.7953 9.09064H20.0007Z"
              fill="white"
            />
          </svg>
          <div className={classes.cardSalary}>
            &#x20B9;{low}L - &#x20B9;{high}L
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={classes.cardContent}>
      <div className={classes.cardTitleWrapper}>
        <div className={classes.cardTitleBox}>
          <div className={classes.cardTitleImg}>
            <img src={DataScienctistPng} className={classes.cardAvatar} />
          </div>
          <div className={classes.cardTitle}>{data?.name}</div>
        </div>
        <div
          className={classes.cardButtons}
          onClick={() => {
            navigate("/roadmap");
          }}
        >
          <div className={classes.cardButton}>Go To Roadmap</div>
        </div>
      </div>
      <div className={classes.cardMiddleContainer}>
        <div className={classes.cardDescription}>
          <div>
            {data?.description.length < charsCountForDescription ? (
              data?.description
            ) : (
              <>
                <p>
                  {shortDescription}
                  <Tooltip
                    title={remainingDescription}
                    // placement="right-end"
                    placement="right"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "common.white",
                          boxShadow: "0px 2px 11px 0px #77777738 !important",
                          border: "1px solid #0EC99E61 !important",
                          // fontSize: pxToVw(12),
                          // wordSpacing: pxToVw(6),
                          // letterSpacing: "1px",
                          // fontWeight: "450",
                          fontFamily: "Poppins, sans-serif",
                          wordSpacing: pxToVw(10),
                          fontSize: pxToVw(14),
                          fontWeight: "400",
                          // color: "#212433",
                          color: "black",
                          "& .MuiTooltip-arrow": {
                            color: "common.white",
                          },
                        },
                      },
                    }}
                  >
                    <DesShowmore />
                  </Tooltip>
                </p>
              </>
            )}
          </div>
        </div>
        <div className={classes.cardOtherDetails}>
          <div>
            <Tooltip
              title={
                <>
                  <div className={classes.tooltipchartDiv}>
                    <div className={classes.tooltipchartHeading}>
                      Salary Range
                    </div>
                    <div className={classes.tooltipchartMedianLabel}>
                      <div className={classes.tooltipchartLabel2}>
                        &#x20B9;{median}LPA
                      </div>
                    </div>
                    <div className={classes.tooltipchartLabels}>
                      <div className={classes.tooltipchartLabel1}>
                        &#x20B9;{low}LPA
                      </div>
                      <div className={classes.tooltipchartLabel3}>
                        &#x20B9;{high}LPA
                      </div>
                    </div>
                    <div className={classes.tooltipchartBottomLineDiv}>
                      <div className={classes.tooltipchartBottomLine}></div>
                      <div className={classes.tooltipchartIndicatorLine}></div>
                    </div>
                  </div>
                </>
              }
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.white",
                    fontSize: pxToVw(12),
                    letterSpacing: "1px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 2px 8px",
                    boxShadow: "0px 2px 11px 0px #77777738 !important",
                    color: "#212433",
                    "& .MuiTooltip-arrow": {
                      color: "common.white",
                    },
                  },
                },
              }}
            >
              <SalaryDiv />
            </Tooltip>
          </div>
          <div>
            <Tooltip
              title={
                <div className={classes.tooltipTopCompaniesHiring}>
                  Top Companies Hiring
                </div>
              }
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.white",
                    boxShadow: "0px 2px 11px 0px #77777738 !important",
                    fontSize: pxToVw(12),
                    letterSpacing: "1px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    color: "#212433",
                    "& .MuiTooltip-arrow": {
                      color: "common.white",
                    },
                  },
                },
              }}
            >
              <Companies />
            </Tooltip>
          </div>
        </div>
      </div>

      <div className={classes.cardContentFooter}>
        <div className={classes.cardContentFooterHeading}>
          Required Skills :{" "}
        </div>
        <div className={classes.cardContentFooterSkills}>
          {skillsArr.map((skill, index) => {
            totalChar = totalChar + skill.length + 20;
            if (totalChar > 105 && !isCount) {
              isCount = true;
              totalSkillsShowing = index + 1;
            }
            if (index < totalSkillsShowing) {
              return (
                <div className={classes.cardContentFooterSkill} key={index}>
                  {skill}
                </div>
              );
            } else if (index == totalSkillsShowing) {
              return (
                <div key={index}>
                  <Tooltip
                    title={remainingSkillsArr.map((remSkill, index) => {
                      return (
                        <div
                          className={classes.cardContentFooterRemSkill}
                          key={index}
                        >
                          {remSkill}
                        </div>
                      );
                    })}
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "common.white",
                          boxShadow: "0px 2px 11px 0px #77777738 !important",
                          fontSize: pxToVw(12),
                          letterSpacing: "1px",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "400",
                          color: "#212433",
                          "& .MuiTooltip-arrow": {
                            color: "common.white",
                          },
                        },
                      },
                    }}
                  >
                    <SkillsShowmore key={index} />
                  </Tooltip>
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className={classes.cardMobileTextDiv}>
        Know more from the experts with Career Reels
      </div>
    </div>
  );
};

export default CardContent;

// ...
