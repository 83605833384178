import { makeStyles } from "@mui/styles";
import { pxToVh, pxToVw } from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";

export const useStyles = makeStyles({
  Chip: {
    padding: `${pxToVh(3)} ${pxToVw(8)} ${pxToVh(3)} ${pxToVw(8)}`,
    backgroundColor: "#F3F3F3",
    borderRadius: pxToVw(10),
    marginLeft: pxToVw(12),
    [theme.breakpoints.down("lg")]: {
      padding: `${pxToVh(1)} ${pxToVw(4)} ${pxToVh(1)} ${pxToVw(4)}`,
    },
  },
  ChipTittle: {
    fontSize: pxToVw(12),
    [theme.breakpoints.down("lg")]: {
      fontSize: pxToVw(8),
      margin: pxToVw(4),
    },
  },
  ChipSkills: {
    color: "#0EC99E",
    padding: `${pxToVh(4)} ${pxToVw(8)} ${pxToVh(4)} ${pxToVw(8)}`,
    backgroundColor: "#E3FFF9",
    borderRadius: pxToVw(12),
    marginLeft: pxToVw(12),
    fontWeight: "500",
    [theme.breakpoints.down("lg")]: {
      padding: `${pxToVh(1)} ${pxToVw(4)} ${pxToVh(1)} ${pxToVw(4)}`,
    },
  },
});
