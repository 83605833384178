/*eslint no-unused-vars:*/
import { makeStyles } from "@mui/styles";
import { theme } from "../../../../Utils/breakpoints";
import { pxToMvw, pxToVh, pxToVw } from "../../../../Utils/pxToRem";
export const useStyles = makeStyles({
  dateWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: pxToVh(10),
  },
  text: {
    fontFamily: "'Poppins', sans-serif;",
    fontWeight: "400",
    fontSize: pxToVw(16),
    color: "#9D9D9D",
    marginLeft: pxToVw(10),
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
    },
  },
  desktopPicker: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobilePicker: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  icon: {
    width: pxToVw(30),
    height: pxToVw(30),
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(25),
      height: pxToMvw(25),
    },
  },
  datePickerWrapper: {
    display: "flex",
    alignItems: "center",
    gap: pxToVw(10),
    width: pxToVw(288),
    height: pxToVw(79),
    borderRadius: pxToVw(40),
    outline: "none",
    paddingLeft: pxToVw(30),
    backgroundColor: "white",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(12),
      width: pxToMvw(180),
      height: pxToMvw(50),
      borderRadius: pxToMvw(25),
    },
  },
  datePicker: {
    width: "95%",
    height: "95%",
    borderRadius: pxToVw(40),
    fontSize: pxToVw(18),
    outline: "none",
    border: "none",
    cursor: "pointer",
    color: "#545454",
    [theme.breakpoints.down("sm")]: {
      width: pxToMvw(80),
      fontSize: pxToMvw(12),
      borderRadius: pxToMvw(25),
    },
  },
  dateInput: {
    width: "70%",
    height: "95%",
    borderRadius: pxToVw(40),
    fontSize: pxToVw(18),
    outline: "none",
    border: "none",
    cursor: "pointer",
    color: "#545454",
    paddingLeft: pxToVw(20),
    [theme.breakpoints.down("sm")]: {
      height: "95%",
      paddingLeft: pxToMvw(20),
      fontSize: pxToMvw(12),
      borderRadius: pxToMvw(25),
    },
  },
});
