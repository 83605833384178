import { useStyles } from "./styles";
import { useRef, useState } from "react";

import { ReactComponent as Eye } from "../../../Assets/images/RecruiterOnboard/Eye.svg";
import { ReactComponent as EyeClosed } from "../../../Assets/images/RecruiterOnboard/Eye-Closed.svg";
import { makeStyles } from "@mui/styles";



const InputPassword = (props) => {
  const styles = useStyles();
  const passwordRef = useRef();
  const [showPass, setPass] = useState(false);
  const [focus, setFocus] = useState(false);
  

  const togglePassword = () => {
    setPass(!showPass);
    if (passwordRef.current.type === "password") {
      passwordRef.current.type = "text"
    } else {
      passwordRef.current.type = "password"
    }
  }


  const status = () => {
    if (!props.valid && props.touched) {
            return "2px solid rgba(244, 67, 54, 1)"
    } if (props.valid && props.touched) {
            return "2px solid #0EC99E"
        }
        else {
            return ""
        }
    }
    
    const iconStatus = () => {
    if (!props.valid && props.touched) {
            return "rgba(169, 169, 169, 1)"
    } if (props.valid && props.touched) {
            return "rgba(84, 84, 84, 1)"
        }
        else {
            return ""
        }
    }

  const dynamicStyle = makeStyles({
    iconContainer: {
      "& svg": {
        fill: iconStatus()
        
      }
    }
  })();
  return (
      <form style={{position:"relative"}} autoComplete="new-password">
        <div className={styles.inputContainer} style={{border:status()}}>
            <div className={`${styles.innerContainer} ${styles.passwordContainer} ${dynamicStyle.iconContainer}`} >
              {props.icon}
              <input readOnly={!props.editable} ref={passwordRef} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} value={props.value} onChange={props.onchange} type={"password"}  className={styles.passwordText} placeholder={props.placeholder} />
            </div>
            <div className={styles.eye} onClick={togglePassword}>
              {!showPass ? <Eye className={styles.icon} /> : <EyeClosed className={styles.icon} />}
        </div>
        
      </div>
      
      {props.note && focus && (
        <div className={styles.noticeContainer}>
          <span className={styles.notice}>{props.note}</span>
        </div>
      )}
      {!props.valid && props.touched && (
        <div className={styles.errorContainer}>
          <span className={styles.errorNote}>{props.errorNote}</span>
        </div>
      )}
      </form>
    )
}

export default InputPassword;