import { makeStyles } from "@mui/styles";
import {
  pxToVw,
  pxToVh,
  pxToRem,
  pxToMvw,
  pxToMvh,
} from "../../../../Utils/pxToRem";
import { theme } from "../../../../Utils/breakpoints";

export const useStyles = makeStyles({
  ExpertDetailsWrapper: {
    display: "flex",
    alignItems: "center",
    gap: pxToRem(20),
    [theme.breakpoints.down("sm")]: {
      paddingTop: pxToMvh(15),
    },
  },
  ExpertName: {
    fontWeight: "500",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    letterSpacing: pxToRem(0.15),
    color: "#646464",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(16),
      lineHeight: pxToMvw(24),
      letterSpacing: pxToMvw(0.15),
    },
  },
  ExpertDesignation: {
    fontWeight: "400",
    fontSize: pxToRem(14),
    lineHeight: pxToRem(21),
    letterSpacing: pxToRem(0.15),
    color: "#646464",
    [theme.breakpoints.down("sm")]: {
      fontSize: pxToMvw(14),
      lineHeight: pxToMvw(21),
      letterSpacing: pxToMvw(0.15),
    },
  },
  ExpertSocial: {
    color: "#0EC99E",
  },
});
