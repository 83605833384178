import { makeStyles } from "@mui/styles";
import {
  pxToVw,
  pxToVh,
  pxToRem,
  pxToMvw,
  pxToMvh,
} from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

// export const useStyles = makeStyles({});

export const useStyles = makeStyles({
  modalWrapper: {
    position: "absolute",
    // zIndex: 11,
    top: "0",
    left: "0",
    // right: "0",
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // backdropFilter: "blur(10px)",
    //   position: "absolute",
    //   zIndex: "10",
    //   top: "0",
    //   left: "0",
    //   width: "100vw",
    //   height: "100vh",
    //   display: "flex",
    //   justifyContent: "flex-end",
    //   // alignItems: "center",
    paddingTop: pxToVw(85),
    paddingRight: pxToVw(20),
    //   backgroundColor: "rgba(0, 0, 0, 0.12);",
    //   // backdropFilter: "blur(10px)",
    //   // transition: "opacity 0.5s ease-in-out",
    [theme.breakpoints.down("mb")]: {
      justifyContent: "center",
      paddingTop: pxToMvh(100),
    },
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFF",
    width: pxToVw(877),
    // height: pxToVh(900),
    height: pxToVh(1214),
    overflow: "hidden",
    borderRadius: pxToVw(12),
    padding: pxToVw(25) + " " + pxToVw(25),
    marginLeft: "auto",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,.2)",
    [theme.breakpoints.down("mb")]: {
      borderRadius: pxToMvw(12),
      padding: pxToMvw(15) + " " + pxToMvw(15),
      width: pxToMvw(440),
      height: pxToMvh(530),
      //   justifyContent: "center",
    },
  },
  closeIcon: {
    color: "#0EC99E",
    width: pxToVw(55),
    height: pxToVw(55),
    marginLeft: "auto",
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(36),
      height: pxToMvw(36),
    },
  },
  modalBody: {
    paddingLeft: pxToVw(58),
    // gap: pxToRem(50),
    // display: "flex",
    height: "100%",
    overflow: "hidden",
    margin: pxToMvw(20) + " " + 0,
    [theme.breakpoints.down("mb")]: {
      margin: pxToMvw(25) + " " + 0,
    },
  },
  modalHeaderTitle: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: pxToVw(30),
    lineHeight: pxToVw(37),
    letterSpacing: "0.01em",
    color: "#0D1C2E",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(30),
      lineHeight: pxToMvh(50),
    },
  },
  modalDescription: {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: pxToVw(22),
    lineHeight: pxToVw(37),
    letterSpacing: "0.01em",
    color: "#646464",
    marginTop: pxToVw(21),
    marginRight: pxToVw(50),
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
      lineHeight: "normal",
      // lineHeight: pxToMvh(1),
    },
  },
  modalResourceToFollow: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: pxToVw(22),
    lineHeight: pxToVw(37),
    letterSpacing: "0.01em",
    color: "#B0B0B0",
    marginTop: pxToVh(40),
    marginBottom: pxToVh(15),
    [theme.breakpoints.down("mb")]: {
      // marginTop: pxToMvh(40), ask it
      fontSize: pxToMvw(14),
      lineHeight: pxToMvh(24),
      marginBottom: pxToMvh(18),
    },
  },
  modalResourcesDiv: {
    padding: pxToVh(38) + " 0px",
    gap: pxToVw(18),
    display: "flex",
    flexDirection: "column",
    borderTop: "1px solid #DFDFDF",
    borderBottom: "1px solid #DFDFDF",
    marginBottom: pxToVh(15),
    // marginRight:pxToVw()
    [theme.breakpoints.down("mb")]: {
      marginRight: pxToMvw(45),
      padding: pxToMvh(30) + " 0px",
      marginBottom: pxToMvh(20),
    },
  },
  modalResource: {
    display: "flex",
    alignItems: "center",
  },
  ResourceIcon: {
    fill: "#005B7A",
    width: pxToVw(13),
    height: pxToVw(16),
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(16),
      height: pxToMvw(20),
    },
  },
  modalResourcesName: {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: pxToVw(16),
    lineHeight: pxToVw(24),
    letterSpacing: "0em",
    marginLeft: pxToVw(16),
    color: "#005B7A",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
      lineHeight: pxToMvw(28),
      marginLeft: pxToMvw(13),
    },
  },
  ResourceIconOpened: {
    fill: "#0EC99E",
    width: pxToVw(16),
    height: pxToVw(20),
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(16),
      height: pxToMvw(20),
    },
  },
  modalResourcesNameOpened: {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: pxToVw(16),
    lineHeight: pxToVw(24),
    letterSpacing: "0em",
    marginLeft: pxToVw(16),
    color: "#0EC99E",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(12),
      marginLeft: pxToMvw(13),
      lineHeight: pxToMvw(28),
    },
  },
  modalMarkAsCompleted: {
    width: pxToVw(235),
    // height: pxToVh(44),
    padding: pxToVh(10) + " 0px",
    background: "#0EC99E",
    border: "none",
    borderRadius: pxToVw(12),
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: pxToVw(16),
    lineHeight: pxToVw(24),
    cursor: "pointer",
    [theme.breakpoints.down("mb")]: {
      fontSize: pxToMvw(10),
      lineHeight: pxToMvw(15),
      padding: pxToMvh(6) + " 0px",
      width: pxToMvw(145),
      // marginRight: pxToVw(10),
    },
  },
  CompletedIcon: {
    width: pxToVw(18),
    height: pxToVw(18),
    marginRight: pxToVw(10),
    [theme.breakpoints.down("mb")]: {
      width: pxToMvw(11),
      height: pxToMvw(11),
      //   marginRight: pxToVw(10),
    },
  },
});
