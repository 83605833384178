import React from "react";
import { useStyles } from "./Styles";
import { ReactComponent as Line } from "../../../Assets/images/LandingPage/Subscribe/Line.svg";

const Numbers = ({ text, subtext }) => {
  const classes = useStyles();
  return (
    <div className={classes.numbers}>
      <h1 className={classes.numberText}>{text}</h1>
      <p className={classes.numberSubtext}>{subtext}</p>
      <div className={classes.lineWrapper}>
        <Line className={classes.line} />
      </div>
    </div>
  );
};

export default Numbers;
