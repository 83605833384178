import { useStyles } from "./styles";
import { useState } from "react";
import Autosuggest from "react-autosuggest";
import { themeStyles } from "./theme";
import { ReactComponent as More } from "../../../Assets/images/RecruiterOnboard/More.svg";
import { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { pxToVw } from "../../../Utils/pxToRem";

const Dropdown = (props) => {
  const styles = useStyles();
  const theme = themeStyles({ bg: "#0EC99E69" });
  const focusRef = useRef();

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const filtered = props.data.filter(
      (lang) =>
        lang[`${props.label}`].toLowerCase().slice(0, inputLength) ===
        inputValue
    );

    return inputLength === 0 ? props.data : filtered;

    // return inputLength === 0 ? props.data : props.data.filter(lang =>
    //   lang.name.toLowerCase().slice(0, inputLength) === inputValue
    // );
    // return props.data;
  };

  const getSuggestionValue = (suggestion) => suggestion[`${props.label}`];

  const setFocus = () => {
    // focusRef.current.childNodes[1].focus()
    // console.log();
    // focusRef.current.focus();
  };

  const renderSuggestion = (suggestion) => (
    <div>{suggestion[`${props.label}`]}</div>
  );

  const [value, setValue] = useState("");
  const [suggestions, setSuggestion] = useState(props.data);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestion(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestion([]);
  };

  const status = () => {
    if (!props.valid && props.touched) {
      return "2px solid rgba(244, 67, 54, 1)";
    }
    if (props.valid && props.touched) {
      return "2px solid #0EC99E";
    } else {
      return "";
    }
  };

  const iconStatus = () => {
    if (!props.valid && props.touched) {
      return "rgba(169, 169, 169, 1)";
    }
    if (props.valid && props.touched) {
      return "rgba(84, 84, 84, 1)";
    } else {
      return "";
    }
  };

  const dynamicStyle = makeStyles({
    iconContainer: {
      "& svg": {
        fill: iconStatus(),
      },
    },
  })();

  //  const inputProps = {
  //   placeholder: props.placeholder,
  //   value:value.length ? value : props.value,
  //   onChange: onChange
  // }

  const inputProps = {
    placeholder: props.placeholder,
    value: value,
    onChange: onChange,
  };

  const renderInputComponent = (inputProps) => (
    <div
      className={`${styles.inputContainer} ${dynamicStyle.iconContainer}`}
      style={{ border: status() }}
    >
      <div className={styles.innerContainer} ref={focusRef}>
        {props.icon}
        <input
          className={styles.inputText}
          onSelect={(valuee) => props.onchange(valuee, props.id)}
          {...inputProps}
        />
      </div>
      <More className={styles.icon14} />
    </div>
  );

  const dropDownRender = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div
          className={styles.childrenWrapper}
          style={{ width: suggestions.length <= 2 ? pxToVw(620) : pxToVw(587) }}
        >
          {children}
        </div>
      </div>
    );
  };

  return (
    <div
      onClick={setFocus}
      style={{ display: "inline-block" }}
      className={styles.dropdownContainer}
    >
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        theme={{
          container: theme.container,
          containerOpen: theme.containerOpen,
          input: theme.input,
          suggestionsContainer: theme.suggestionsContainer,
          suggestionsContainerOpen: theme.suggestionsContainerOpen,
          suggestion: theme.suggestion,
          suggestionHighlighted: theme.suggestionHighlighted,
        }}
        renderSuggestionsContainer={dropDownRender}
        shouldRenderSuggestions={() => {
          return true;
        }}
      />
    </div>
  );
};

export default Dropdown;
