import React from "react";
import { useStyles } from "./styles";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../Assets/images/RecruiterOnboard/logo.svg";
import Right from "../../../Assets/images/RecruiterOnboard/rightIllustration.png";
// import { ReactComponent as RightIllus } from "../../../Assets/images/RecruiterOnboard/RightIllustration.svg";
// import { ReactComponent as LeftIllustration } from "../../../Assets/images/RecruiterOnboard/LeftIllustration.svg";
import Left from "../../../Assets/images/RecruiterOnboard/leftillustration.png";
const Header = (props) => {

    const styles = useStyles();

    

    return (
        <div className={styles.wrapperContainer}>
        <Link to={"/"} className={styles.container}>
            
            <Logo />
            </Link>
            
            <div className={styles.wrapper}>
                {/* <RightIllus className={styles.rightIllus} /> */}
                {/* <LeftIllustration className={styles.leftIllus} /> */}
                <img src={Right} className={styles.rightIllus} />
                <img src={Left} className={styles.leftIllus} />
                {props.children}
            </div>
        </div>
    )
}

export default Header;