/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useStyles } from "./Styles";
import Text from "../../../Shared/inputV2/Text/input";
import { ReactComponent as DialPad } from "../../../../Assets/images/StudentOnboard/LoginPage/Dialpad.svg";
import { ReactComponent as Lock } from "../../../../Assets/images/StudentOnboard/LoginPage/Lock.svg";
import { ReactComponent as Eye } from "../../../../Assets/images/StudentOnboard/LoginPage/Eye.svg";
import { ReactComponent as EyeClosed } from "../../../../Assets/images/StudentOnboard/LoginPage/eyeClosed.svg";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../../../../Services/apiClient";
import Error from "../../../Shared/Error/Error";

const SignUp = ({ values, setValues, page, setPage }) => {
  const [showPassord, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (values.phonenumber.length === 0) {
      setPhoneError(false);
    }
    if (values.password.length === 0) {
      setPasswordError(false);
    }
    if (values.confirmpassword.length === 0) {
      setConfirmPasswordError(false);
    }
  }, [values.phonenumber, values.password, values.confirmpassword]);

  useEffect(() => {
    if (
      values.phonenumber.length !== 0 &&
      values.password.length !== 0 &&
      values.confirmpassword.length !== 0 &&
      !phoneError &&
      !passwordError &&
      !confirmPasswordError
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    values.phonenumber,
    values.password,
    values.confirmpassword,
    phoneError,
    passwordError,
    confirmPasswordError,
  ]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phoneError && !passwordError && !confirmPasswordError) {
      try {
        const res = await axiosClient.post("/isStudentMobileNumberExist", {
          PhoneNumber: values.phonenumber,
        });
        if (res.data.status === true) {
          setError("User already registered");
        } else {
          try {
            const res = await axiosClient.post("/getOTPStudentRegister", {
              countryCode: "+91",
              PhoneNumber: values.phonenumber,
            });
            if (res.data.success) {
              setPage(page + 1);
            } else if (res.data.success === false) {
              setError(res.data.message);
            }
          } catch (err) {
            console.log(err);
          }
        }
      } catch (err) {
        setError("Someting went wrong. Please try again later");
      }
    }
  };

  const classes = useStyles({ disabled });
  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit} autoComplete="off" className={classes.form}>
        <div className={classes.inputWrappers}>
          <Text
            icon={<DialPad />}
            onChange={onChange}
            value={values.phonenumber}
            name="phonenumber"
            type="text"
            placeholder="Enter your Phone number"
            errorMessage="Not a valid phone number"
            error={phoneError}
            setError={setPhoneError}
            loginError={error}
          />
          <Text
            icon={<Lock />}
            eye={
              !showPassord ? (
                <Eye
                  onClick={() => {
                    setShowPassword(true);
                  }}
                />
              ) : (
                <EyeClosed
                  onClick={() => {
                    setShowPassword(false);
                  }}
                />
              )
            }
            onChange={onChange}
            value={values.password}
            name="password"
            type={showPassord ? "text" : "password"}
            placeholder="Create Password"
            errorMessage="Must be 8 characters or longer. Use at least one number or symbol(eg, !@#$%^)"
            helperText="Must be 8 characters or longer. Use at least one number or symbol(eg, !@#$%^)"
            error={passwordError}
            setError={setPasswordError}
            loginError={error}
          />
          <Text
            icon={<Lock />}
            eye={
              !showConfirmPassword ? (
                <Eye
                  onClick={() => {
                    setShowConfirmPassword(true);
                  }}
                />
              ) : (
                <EyeClosed
                  onClick={() => {
                    setShowConfirmPassword(false);
                  }}
                />
              )
            }
            onChange={onChange}
            password={values.password}
            value={values.confirmpassword}
            name="confirmpassword"
            type={showConfirmPassword ? "text" : "password"}
            errorMessage="Passwords do not match"
            placeholder="Confirm Password"
            error={confirmPasswordError}
            setError={setConfirmPasswordError}
            loginError={error}
          />
        </div>
        {error && <Error text={error} color="red" />}
        <button disabled={disabled} type="submit" className={classes.button}>
          Signup
        </button>
      </form>
      <div className={classes.bottomWrapper}>
        <div className={classes.forgotPasswordWrapper}>
          <p className={classes.or}>or</p>
        </div>
        <div className={classes.bottomOptions}>
          <span>Already have an Account ?</span>
          <button
            className={classes.signUp}
            onClick={() => {
              navigate("/studentlogin");
            }}
          >
            Log In
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
