import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as types from "../../../../redux/types";
import { useStyles } from "./styles";
import { ReactComponent as DataScience } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Data_Science.svg";
import { ReactComponent as Consulting } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Consulting.svg";
import { ReactComponent as Software } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Software_Development.svg";
// import { ReactComponent as Infra } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Infra.svg";
import { ReactComponent as Marketing } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Marketing.svg";
import { ReactComponent as Sales } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Sales.svg";
import { ReactComponent as Product } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Product.svg";
import { ReactComponent as HR } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Human_Resource.svg";
import { ReactComponent as Supply } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Supply_Chain.svg";
import { ReactComponent as Core } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Core.svg";
import { ReactComponent as Crypto } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Crypto.svg";
import { ReactComponent as Finance } from "../../../../Assets/images/RecruiterOnboard/Select_Domain/Finance.svg";
import { useSelector } from "react-redux";
import { saveDomains } from "../../../../Services/lib/RecruiterOnboarding";
import FacebookCircularProgress from "../../../Shared/Loader/index";

const SelectDomain = (props) => {

    const styles = useStyles();
    const dispatch = useDispatch();
    const Domains = useSelector(state => state.RecruiterOnboard.domains)
    const companyDetailsId = useSelector(state => state.RecruiterOnboard.companyDetailsId);
    const persistDomains = useSelector(state => state.RecruiterOnboard.persistDomains);
    const [hover, setHover] = useState(false);

    const [formError, setFormError] = useState({
        errorState: false,
        errorNote: null
    })


    useEffect(() => {
        console.log(persistDomains);
        if (Domains.length === 0) {
            dispatch({ type: types.GET_DOMAIN_REQUESTED });
        }
        setSelected(persistDomains);
        console.log(Domains);
  
    }, [persistDomains])
    
    const [selected, setSelected] = useState([]);




    const iconSelector = (id) => {
        switch (id) {
            case "Data Science":
                return <DataScience />
            case "Product":
                return <Product />
            case "Marketing":
                return <Marketing />
            case "Software Engineering":
                return <Software />
            case "Consulting":
                return <Consulting />
            case "Sales":
                return <Sales />
            case "Customer Value":
                return <HR />
            case "Finance":
                return <Finance />
            case "Systems":
                return <Supply />
            case "Human Resources":
                return <HR />
            case "BlockChain":
                return <Crypto />
            case "Core":
                return <Core />
            default:
                return <HR />
        }
    }


    const Next = () => {
      
       
        props.setCompleted(step => [...step, props.step]);
        let domainObj = {
            companyDetailsId: companyDetailsId,
            domainsId: selected.map(a => a._id)
        }
        
        
        console.log(selected);
        if (persistDomains.length > 0) {
            props.setStep(props.step + 1);

            return;
        }
        saveDomains(domainObj).then(res => {
            console.log(res);
            if (res.res.data.success) {
                dispatch({ type: types.SET_DOMAIN_SUCCESS, selectedDomains: domainObj, persistDomains: selected })
                props.setStep(props.step + 1);
                props.setCompleted(step => [...step, props.step]);
                console.log(props.step, props.current, props.completed);
                
                
            } else {
                dispatch({ type: types.SET_DOMAIN_FAIL })
                setFormError(prev => ({
                    ...prev,
                    errorNote: res.res.data.message,
                    errorState: true
                }))
            }
        }).catch(err => {
            console.log(err);
            dispatch({ type: types.SET_DOMAIN_FAIL })
            setFormError(prev => ({
                ...prev,
                errorNote: "Network Problem",
                errorState: true
            }))

        })
        // console.log(selected);
        // dispatch({ type: types.SET_DOMAIN_REQUESTED, domains: selected });
    }

//   const Next = () => {
//     if (persistDomains) {
//       props.setStep(props.step + 1);
//       return;
//     }

//     props.setCompleted((step) => [...step, props.step]);
//     let domainObj = {
//       companyDetailsId: companyDetailsId,
//       domainsId: selected.map((a) => a._id),
//     };

//     console.log(selected);
//     saveDomains(domainObj)
//       .then((res) => {
//         console.log(res);
//         if (res.res.data.success) {
//           dispatch({
//             type: types.SET_DOMAIN_SUCCESS,
//             selectedDomains: domainObj,
//             persistDomains: selected,
//           });
//           props.setStep(props.step + 1);
//         } else {
//           dispatch({ type: types.SET_DOMAIN_FAIL });
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch({ type: types.SET_DOMAIN_FAIL });
//       });
//     // console.log(selected);
//     // dispatch({ type: types.SET_DOMAIN_REQUESTED, domains: selected });
//   };

  const submitButton = (data) => {
    if (selected.some((f) => f._id === data._id)) {
      setSelected(selected.filter((item) => item._id !== data._id));
    } else {
      setSelected((prev) => [...prev, data]);
    }
  };

  return (
    <div className={styles.container}>
      {/* <h1 className={styles.heading}>Looking to Hire in ?</h1> */}
      <h1 className={styles.subHead}>You can select multiple domains</h1>
      <div className={styles.fieldButtons}>
        {Domains.length !== 0 ? (
          Domains.map((field) => (
            <div
              key={field._id}
              className={`${styles.Field}`}
              style={{
                border: selected.some((f) => f._id === field._id)
                  ? "2px solid rgba(14, 201, 158, 1)"
                  : "2px solid #FCFCFC",
              }}
              onClick={() => submitButton(field)}
            >
              <h1>{field.domain}</h1>
              {iconSelector(field.domain)}
            </div>
          ))
        ) : (
          <FacebookCircularProgress />
        )}
      </div>
      {formError.errorState && <div className={styles.errorText}>
        {formError.errorNote}
      </div>}

      <div className={styles.ValidContainer}>
        <button
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          disabled={selected.length > 0 ? false : true}
          onClick={Next}
          className={`${styles.submit} ${
            selected.length > 0 ? styles.enabled : styles.disabled
          }`}
        >
          <span className={styles.nextBtn}>Next</span>
        </button>
        {selected.length === 0 && hover && (
          <span className={styles.validationtext}>
            Please select atleast one domain from above to continue.
          </span>
        )}
      </div>
    </div>
    );
};

export default SelectDomain;
