/* eslint-disable no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import {pxToVw,pxToMvw} from "../../../Utils/pxToRem";
import {theme} from "../../../Utils/breakpoints";


// Inspired by the former Facebook spinners.
export default function FacebookCircularProgress(props) {
  return (
    <Box sx={{
      position: "relative",
      height: pxToVw(40),
      width: pxToVw(40),
      [theme.breakpoints.down("sm")]: {
            width: pxToMvw(20),
            height: pxToMvw(20),
      }
    }}>
      <CircularProgress
              variant="determinate"
              
        sx={{
          height: pxToVw(40),
          width: pxToVw(40),
      [theme.breakpoints.down("sm")]: {
            width: pxToMvw(20),
            height: pxToMvw(20),
      },
          color: (theme) =>
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={pxToVw(40)}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
              variant="indeterminate"
              
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === "light" ? "#0EC99E" : "#308fe8"),
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          
          
        }}
        size={window.screen.width < 600 ? pxToMvw(20) : pxToVw(40)}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

// export default function CustomizedProgressBars() {
//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <FacebookCircularProgress />
//       <br />
//       <BorderLinearProgress variant="determinate" value={50} />
//     </Box>
//   );
// }