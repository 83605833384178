import { makeStyles } from "@mui/styles";
import { pxToMvw, pxToRem, pxToVh, pxToVw , pxToMvh } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints";

export const useStyles = makeStyles({
    container: {
        // height: "100vh",    
        alignSelf: "center",
        display: "flex",
        justifyContent: "center",
        position:"relative"
    },
    emailSent: {
        fontSize: pxToRem(28),
        color: "rgba(157, 157, 157, 1)",
        textAlign: "center",
        width: pxToVw(861),
        marginTop: pxToVw(65),
        [theme.breakpoints.down("sm")]: {
            width:"90%",
        
        }
        
    },
    backButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: pxToVw(211),
        top:pxToVh(99),
        backgroundColor: "white",
        borderRadius: "50%",
        width: pxToVw(80),
        height: pxToVw(80),
        cursor: "pointer",
        pointerEvents: "all",
        zIndex: 10,
        WebkitTapHighlightColor:"transparent",
        "& svg": {
            width: pxToVw(21.67),
            height: pxToVw(15.83),
            [theme.breakpoints.down("sm")]: {
            width:pxToMvw(21.67),
            height: pxToMvw(15.83),
        }
        },
        [theme.breakpoints.down("sm")]: {
            width:pxToMvw(51),
            height: pxToMvw(51),
            position: "relative",
            borderRadius: "50%",
            border: "1px solid rgba(14, 201, 158, 0.2)",
            top: pxToMvh(14),
            left:pxToMvw(24)
        }
    },
    formWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        "& h1": {
            fontSize: pxToRem(48),
            background: "-webkit-linear-gradient(#00634C, #009270)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily:"Poppins,sans-serif",
            fontWeight: 600,
            letterSpacing: "-1.5px",
            margin: 0,
            lineHeight:pxToVh(86),
            textAlign: "center",
            marginTop: pxToVh(52),
            marginBottom: pxToVh(36),
            [theme.breakpoints.down("sm")]: {
                fontSize: pxToMvw(24),
                marginBottom:0
            }
        }
    },
    Inputs: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap:pxToVh(24)
    },
    loginContainer: {
        //   width: pxToVw(140),
        height: pxToVw(80),
        background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
        borderRadius: pxToRem(40),
        border:"2px solid #0EC99E",
        margin: `${pxToVh(24)} auto ${pxToVh(32)} auto`,
         [theme.breakpoints.down("mb")]: {
            width: pxToMvw(124),
            height: pxToMvw(48),
             borderRadius: pxToMvw(40),
        }
        
        
        
    },
    btnLogin: {
        color: "white",
        textDecoration: "none",
        fontSize: pxToRem(18),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight:400,
        height: "100%",
         [theme.breakpoints.down("mb")]: {
            fontSize:pxToMvw(12)
        },
        width: "223px"
       
      
    },
    inputContainer: {
        marginTop: pxToVw(32),
        display: "flex",
        flexDirection:"column",
        alignItems: "center",
        gap:pxToVw(32),
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection:"column",
            alignItems: "center",
            gap:pxToMvw(32)
        }
    },
    note: {
        color: "rgba(157, 157, 157, 1)",
        fontSize: pxToRem(18),
        lineHeight: pxToVh(32),
        // marginBottom: pxToVh(36),
        [theme.breakpoints.down("sm")]: {
            fontSize: pxToMvw(14),
            textAlign: "center",
            marginBottom:pxToMvw(22)
        }
    },
    submit: {
        // width: pxToVw(300),
        height: pxToVw(80),
        padding:`${pxToVw(24)} ${pxToVw(46)}`,
        borderRadius: pxToRem(40),
        background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
        // background: "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), #0EC99E",
        border:"2px solid #0EC99E",
        // margin: `${pxToVw(32)} auto ${pxToVw(0)} auto`,
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap:pxToVw(10),
        marginBottom: pxToVh(118),
        fontSize: pxToRem(18),
        cursor: "pointer",
        fontFamily: "Poppins,sans-serif",
        [theme.breakpoints.down("sm")]: {
            width: pxToMvw(236),
            height: pxToMvw(48),
            borderRadius: pxToMvw(40),
            fontSize: pxToMvw(12),
            margin: "0 auto"
            
        }
    },
    disabledSubmit: {
        background: "linear-gradient(109.59deg, #CDCDCD 24.27%, #797979 162.42%), #0EC99E",
        border: "none",
        cursor: "no-drop",
        [theme.breakpoints.down("sm")]: {
            // width: pxToMvw(131),
            // height: pxToMvw(48),
            borderRadius: pxToMvw(40),
            fontSize:pxToMvw(12)
        }
    },
    enabledSubmit: {
        background: "linear-gradient(109.59deg, #0EC99E 24.27%, #106854 162.42%), #0EC99E",
        border:"2px solid #0EC99E",
        cursor:"pointer"
    },
    signUp: {
        color: "#0EC99E",
        textDecoration: "none",
        fontWeight: 400,
        fontSize: pxToRem(16),
        [theme.breakpoints.down("sm")]: {
           
            fontSize:pxToMvw(10)
        }
    }
})