import { makeStyles } from "@mui/styles";
import { pxToVw, pxToVh, pxToRem } from "../../../Utils/pxToRem";
import { theme } from "../../../Utils/breakpoints"; 
    
export const useStyles = makeStyles({
    container: {
        
        height: pxToVh(71),
        backgroundColor: "white",
        position: "sticky",
        top: 0,
        left:0,
        zIndex:21,
        display: "flex",
        alignItems: "center",
        
        paddingLeft:pxToVw(201),
        "& svg": {
            width: pxToVw(165),
            height:pxToVh(45)
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    wrapperContainer: {
        height: "100vh",
        backgroundColor: "#F6F6F6",
          [theme.breakpoints.down("sm")]: {
            backgroundColor:"white"
        }
    },
    wrapper: {
        // position: "relative",
        overflowY: "auto",
        width: "99.6%",
        maxHeight:`calc(100vh - ${pxToVh(71)})`,
        "&::-webkit-scrollbar": {
            width: pxToVw(12),
            
        },
        "&::-webkit-scrollbar-track": {
            width: pxToVw(12),
            
        },
        "&::-webkit-scrollbar-thumb": {
            width: pxToVw(12),
            backgroundColor: "#DEE0DF",
            borderRadius:pxToRem(8)
            
        },
      
    },
    leftIllus: {
        width: pxToVw(550),
        // height: pxToVh(400),
        height: pxToVh(600),
        position: "absolute",
        bottom:0,
        // top: pxToVh(329),
        zIndex: 0,
        pointerEvents:"none"
        // left:0
    },
    rightIllus: {
        position: "absolute",
        right: 0,
        top: 0,
        width: pxToVw(500),
        height: pxToVh(771),
        zIndex: 20,
        pointerEvents:"none"
    },

    
});
